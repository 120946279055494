import { FileInfoType } from 'src/tempModels/fileHandler';

import { Anything } from '../../utils/globalTypes';
import { FilterParams } from '../filters';

export const FETCH_LEGACY_CLAIMS_REQUEST = 'FETCH_LEGACY_CLAIMS_REQUEST';
export const FETCH_LEGACY_CLAIMS_FAILURE = 'FETCH_LEGACY_CLAIMS_FAILURE';
export const SET_LEGACY_CLAIMS = 'SET_LEGACY_CLAIMS';
export const UPDATE_LEGACY_CLAIMS = 'UPDATE_LEGACY_CLAIMS';
export const RESET_LEGACY_CLAIMS_PAGINATION = 'RESET_LEGACY_CLAIMS_PAGINATION';
export const FETCH_LEGACY_CLAIMS_COUNT_REQUEST = 'FETCH_LEGACY_CLAIMS_COUNT_REQUEST';
export const SET_LEGACY_CLAIMS_COUNT = 'SET_LEGACY_CLAIMS_COUNT';
export const LEGACY_CLAIMS_DOWNLOAD_ID = 'LEGACY_CLAIMS_DOWNLOAD_ID';

export interface FetchLegacyClaimsRequestAction {
  type: typeof FETCH_LEGACY_CLAIMS_REQUEST;
  payload: FilterParams;
}

export interface FetchLegacyClaimsRequestFailureAction {
  type: typeof FETCH_LEGACY_CLAIMS_FAILURE;
  payload: Anything;
}

export interface FetchLegacyClaimsCountRequestAction {
  type: typeof FETCH_LEGACY_CLAIMS_COUNT_REQUEST;
  payload: Anything;
}

export interface SetLegacyClaimsCountAction {
  type: typeof SET_LEGACY_CLAIMS_COUNT;
  payload: Anything;
}

export interface SetLegacyClaimsAction {
  type: typeof SET_LEGACY_CLAIMS;
  payload: FetchLegacyClaimsResponseType;
}

export interface UpdateLegacyClaimsAction {
  type: typeof UPDATE_LEGACY_CLAIMS;
  payload: UpdateLegacyClaimsType;
}

export interface ResetLegacyClaimsPagination {
  type: typeof RESET_LEGACY_CLAIMS_PAGINATION;
}

export type LegacyClaimsActionTypes =
  | FetchLegacyClaimsRequestAction
  | FetchLegacyClaimsRequestFailureAction
  | SetLegacyClaimsAction
  | UpdateLegacyClaimsAction
  | ResetLegacyClaimsPagination
  | SetLegacyClaimsCountAction
  | FetchLegacyClaimsCountRequestAction;

export type FetchLegacyClaimsResponseType = LegacyClaimsType[];

export type LegacyClaimsType = {
  id: number;
  submitted: string;
  user: {
    id: number;
    name: string;
  };
  actor: {
    id: number;
    name: string;
  };
  title: string;
  type: string;
  status: 'Success' | 'Failed' | 'Submitted' | 'Running';
  progress: string;
  input: FileInfoType[] | null;
  output: FileInfoType[] | null;
  output_last_updated: number;
  message: string;
  details: null | {
    total: number;
    faulty: number;
    current: number;
    ignored: number;
    claimable: number;
    already_claimed_by_orfium: number;
    already_claimed_by_others: number;
    whitelisted: number;
  };
  time_elapsed: number;
};

export type UpdateLegacyClaimsType = {
  ids: number[];
  action: string;
};

export interface LegacyClaimsReducer {
  list: LegacyClaimsType[];
  countItems: number;
  selected: number[];
}

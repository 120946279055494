import head from 'lodash/head';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { validateReviewHistoryForm } from 'src/components/Forms/validators';
import { AppState } from 'src/ducks';
import { getHistoryActions } from 'src/ducks/reviewHistory';
import { FORMS } from 'src/utils/constants';

import ReviewHistoryForm, { Props, FormArgs } from './ReviewHistoryForm';

const mapStateToProps = (state: AppState, { asset }: Props) => {
  return {
    actions: getHistoryActions(state),
    initialValues: {
      ytDuration: 0,
      otherAssets: [
        {
          option: {
            label: asset.asset
              ? `${asset.asset.title} ${asset.asset.id && `<${asset.asset.id}>`}`
              : '',
            id: asset.asset ? asset.asset.id : '',
            value: asset.asset ? asset.asset.id : '',
            reference_url: '',
          },
          action: head(getHistoryActions(state)),
          matches:
            asset.matches && asset.matches.length > 0
              ? asset.matches
              : [{ matched_from: 0, matched_to: 0 }],
        },
      ],
    },
  };
};

const ReviewHistoryFormRedux = reduxForm<FormArgs, Props>({
  form: FORMS.REVIEW_HISTORY_EDIT_ASSET,
  validate: validateReviewHistoryForm,
  shouldError: ({ nextProps }) => {
    return Boolean(nextProps && (nextProps.submitting || nextProps.dirty));
  },
})(ReviewHistoryForm);

export default connect(mapStateToProps, null)(ReviewHistoryFormRedux);

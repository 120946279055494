import { startSubmit, stopSubmit } from 'redux-form';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { SET_PAGINATION_PAGE, SetPaginationPageAction } from 'src/ducks/factories/pagination/types';
import { createError } from 'src/ducks/global/errorHandling';
import { ActionsOptions } from 'src/ducks/legacyFilters/normalization/types';
import { removeOverlay } from 'src/ducks/ui/overlay';
import { reviewHistoryAPI } from 'src/providers';
import { handleFormErrors } from 'src/utils/forms';

import {
  fetchHistoryReviewsFail,
  fetchHistoryReviewsRequest,
  setHistoryReviews,
  reviewPaginationActions,
  reviewHistoryLoadingActions,
  fetchHistoryOptionsRequest,
  fetchHistoryOptionsSuccess,
} from './actions';
import { __REDUX_STATE_KEY__, reviewHistoryPaginationSelectors } from './selectors';
import {
  FetchHistoryReviewsRequestAction,
  FetchHistoryRequestAction,
  UpdateHistoryRequestAction,
  FETCH_HISTORY_REVIEWS_REQUEST,
  FETCH_HISTORY_ACTIONS_REQUEST,
  UPDATE_HISTORY_REVIEWS,
} from './types';

const fetchHistoryReviewEpic = (
  action$: ActionsObservable<FetchHistoryReviewsRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_HISTORY_REVIEWS_REQUEST),
    switchMap(({ payload }) =>
      concat(
        of(reviewHistoryLoadingActions.setLoading(true)),
        from(reviewHistoryAPI.bulk.get(payload).request()).pipe(
          mergeMap(({ results, next }) =>
            of(
              setHistoryReviews(results),
              fetchHistoryOptionsRequest(),
              reviewPaginationActions.setPaginationTotalPages(
                !next ? reviewHistoryPaginationSelectors.getPaginationPage(state$.value) || 1 : 0
              )
            )
          ),
          catchError(({ errors }) =>
            of(fetchHistoryReviewsFail(), createError(handleFormErrors(errors)))
          )
        ),
        of(reviewHistoryLoadingActions.setLoading(false))
      )
    )
  );

const fetchHistoryActions = (action$: ActionsObservable<FetchHistoryRequestAction>) =>
  action$.pipe(
    ofType(FETCH_HISTORY_ACTIONS_REQUEST),
    switchMap(() =>
      concat(
        from(reviewHistoryAPI.bulk.getHistoryOptions().request()).pipe(
          mergeMap(({ actions }) => {
            const normalizedActions = actions.map((opt: ActionsOptions) => ({
              value: opt.value,
              type: opt.type,
              label: opt.text,
            }));

            return of(fetchHistoryOptionsSuccess(normalizedActions));
          }),
          catchError(({ errors }) =>
            of(fetchHistoryReviewsFail(), createError(handleFormErrors(errors)))
          )
        )
      )
    )
  );

const changeHistoryReviewPageEpic = (action$: ActionsObservable<SetPaginationPageAction>) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REDUX_STATE_KEY__)),
    switchMap(({ payload: page }) => of(fetchHistoryReviewsRequest({ page })))
  );

const UpdateHistoryReviewEpic = (
  action$: ActionsObservable<UpdateHistoryRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(UPDATE_HISTORY_REVIEWS),
    switchMap(({ payload: { formName = '', video_id, asset_id, action, matches } }) =>
      concat(
        of(startSubmit(formName)),
        from(
          reviewHistoryAPI.single.update({ video_id, asset_id, action, matches }).request()
        ).pipe(
          mergeMap(() => {
            return of(
              stopSubmit(formName),
              fetchHistoryReviewsRequest({ page: state$.value.reviewHistory.page }),
              removeOverlay(formName)
            );
          }),
          catchError(({ errors }) =>
            of(stopSubmit(formName, handleFormErrors(errors, state$.value.form[formName].fields)))
          )
        )
      )
    )
  );

export default {
  fetchHistoryReviewEpic,
  changeHistoryReviewPageEpic,
  fetchHistoryActions,
  UpdateHistoryReviewEpic,
};

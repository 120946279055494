import { NotificationsContainer } from '@orfium/ictinus';
import React from 'react';

import ProgressToast from './components/ProgressToast';
import Upload from './components/Upload';
import { useFileTransfer } from './FileTransferContext';
import { PROCESSING } from './types';

const FileTransfer: React.FC = () => {
  const { items } = useFileTransfer();

  return (
    <NotificationsContainer position="bottom-right">
      {items.map((item) =>
        item.type === PROCESSING ? (
          <ProgressToast key={item.id} {...item.content} />
        ) : (
          <Upload item={item} key={item.id} />
        )
      )}
    </NotificationsContainer>
  );
};

export default FileTransfer;

import {
  KeywordManagementType,
  KeywordManagementCountResponse,
  KeywordManagementsTriggerActionParams,
} from 'src/ducks/keywordManagement';
import { FilterResponseType } from 'src/providers/filtersAPI/types';

import { METHODS, requestV3 } from '../axiosInstances';

export default {
  get: (params = {}) => requestV3<KeywordManagementType[]>(METHODS.GET, `/keywords/`, { params }),
  getCount: (params = {}) =>
    requestV3<KeywordManagementCountResponse>(METHODS.GET, `/keywords/count/`, { params }),
  put: (params = {}) =>
    requestV3<KeywordManagementsTriggerActionParams>(METHODS.PUT, `/keywords/`, { params }),
  options: (params = {}) =>
    requestV3<FilterResponseType>(METHODS.GET, `/keywords/options/`, { params }),
};

import { Drawer, useBreakpoints } from '@orfium/ictinus';
import React, { useCallback, useEffect } from 'react';
import config from 'src/config';
import { Ability } from 'src/ducks/user';
import { can } from 'src/ducks/user/policy';
import { OPERATIONS } from 'src/utils/constants';

import { SidebarPolicyLinks, SidebarWrapper } from './Sidebar.style';

export type ReduxProps = {
  sidebarIsOpen: boolean;
  ability: Ability | undefined;
};

export type ReduxActions = {
  openSidebar(): void;
  closeSidebar(): void;
};

const Sidebar: React.FC<ReduxProps & ReduxActions> = ({
  sidebarIsOpen,
  ability,
  openSidebar,
  closeSidebar,
}) => {
  const breakpoints = useBreakpoints();

  useEffect(() => {
    if (!breakpoints.des1366) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }, [breakpoints.des1366, openSidebar, closeSidebar]);

  const setExpanded = useCallback(
    (isExpanded: boolean) => (isExpanded ? openSidebar() : closeSidebar()),
    [openSidebar, closeSidebar]
  );

  return (
    <SidebarWrapper>
      <Drawer
        expanded={sidebarIsOpen}
        setExpanded={setExpanded}
        menuItems={(ability && config.menuItems(ability)) || []}
      />
      {can({ I: OPERATIONS.READ, This: 'policyLinks', ability }) && (
        <SidebarPolicyLinks>
          <a href="https://www.youtube.com/t/terms" target="_blank" rel="noreferrer noopener">
            YouTube Terms of Use
          </a>
          <a
            href="http://www.google.com/policies/privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Google Privacy Policy
          </a>
        </SidebarPolicyLinks>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;

import queryString from 'query-string';
import { match } from 'react-router';

export const checkStrategyExistsAndMatch = (location: string, url: string) => {
  const parsedLocation = queryString.parse(location);
  const splittedURL = url.split('?');
  const parsedURL = queryString.parse(splittedURL ? splittedURL[splittedURL.length - 1] : '');

  return parsedURL.strategy && parsedURL?.strategy === parsedLocation?.strategy;
};

export const setIsActive = (url: string) => (match: match | null) => {
  const strategyMatch = checkStrategyExistsAndMatch(window.location.search, url as string);

  /**
   * check if the url has 'strategy' in it. If it does it means that there is a link inside a menu item
   * in that case check if strategy match otherwise run the match
   */
  return Boolean(typeof url === 'string' && url.includes('strategy') ? strategyMatch : match);
};

import { withLoadingActions } from 'src/ducks/factories/loading';
import { simpleAction } from 'src/ducks/utils';
import { PagesType } from 'src/utils/constants';

import { __REDUX_STATE_KEY__ } from './selectors';
import * as types from './types';
import { FilterOption, FiltersState, Sort } from './types';

export const fetchFilters = (
  strategy: PagesType,
  loading?: boolean | undefined
): types.FetchFiltersAction => simpleAction(types.FETCH_FILTERS, { strategy, loading });

export const fetchFiltersSuccess = (payload: FiltersState): types.FetchFiltersSuccessAction =>
  simpleAction(types.FETCH_FILTERS_SUCCESS, payload);

export const changeStrategyFilter = (
  payload: {
    newKey: FilterOption;
    changedKey: string;
  }[]
): types.ChangeFilterAction => simpleAction(types.CHANGE_FILTERS, payload);

export const addFilters = (payload: string[]): types.AddFilterAction =>
  simpleAction(types.ADD_FILTERS, payload);

export const removeAddedFilters = (payload: string[]): types.RemoveAddedFilterAction =>
  simpleAction(types.REMOVE_ADDED_FILTERS, payload);

export const changeStrategySort = (payload: { newValue: Sort }): types.ChangeSortAction =>
  simpleAction(types.CHANGE_SORT, payload);

// export const changeDynamicFilter = (payload: any): types.ChangeDynamicFilterAction =>
//   simpleAction(types.CHANGE_DYNAMIC_FILTER, payload);

export const fetchFiltersFail = (): types.FetchFiltersFailureAction =>
  simpleAction(types.FETCH_FILTERS_FAILURE);

export const resetFilter = (): types.ResetFilterAction => simpleAction(types.RESET_FILTERS);

export const filterLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

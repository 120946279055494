import isEqual from 'lodash/isEqual';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { Anything } from 'src/utils/globalTypes';

export type SimpleAction<Type, Payload = Record<string, unknown>> = {
  readonly type: Type;
  readonly payload: Payload;
};

export function simpleAction<T extends string>(type: T): { type: T; payload: Anything };

export function simpleAction<Type extends string, Payload extends unknown>(
  type: Type,
  payload: Payload
): { type: Type; payload: Payload };
export function simpleAction<Type extends string, Payload>(type: Type, payload?: Payload) {
  return {
    type,
    payload,
  };
}

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const pagePicker = (pages: number) => {
  return pages === 1 ? pages : --pages;
};

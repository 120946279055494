import { connect } from 'react-redux';
import { AppState } from 'src/ducks';
import { resetFilter } from 'src/ducks/filters';
import { getGlobalSearch, setGlobalSearch } from 'src/ducks/globalSearch';
import { getPage } from 'src/ducks/router';
import { openSidebar, closeSidebar, getSidebarOpenState } from 'src/ducks/ui/sidebar';
import { getUser, updateUserSelectedAccount, unsetUserInfo } from 'src/ducks/user';
import { getAbility } from 'src/ducks/user';

import Header, { ReduxActions, ReduxProps } from './Header';

const mapStateToProps = (state: AppState) => ({
  page: getPage(state),
  globalSearch: getGlobalSearch(state),
  sidebarIsOpen: getSidebarOpenState(state),
  user: getUser(state),
  ability: getAbility(state),
});

const mapDispatchToProps = {
  openSidebar,
  closeSidebar,
  updateUserSelectedAccount,
  unsetUserInfo,
  resetFilter,
  setGlobalSearch,
};

export default connect<ReduxProps, ReduxActions, unknown, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(Header);

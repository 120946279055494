import styled from '@emotion/styled';
import { rem } from 'polished';

export const Content = styled.div`
  width: ${rem(625)};
  padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.md} 0 ${theme.spacing.md}`};
`;

export const TimeInfo = styled.span`
  width: 100%;
  font-weight: ${({ theme }) => theme.typography.weights.regular};
  font-size: ${({ theme }) => theme.typography.fontSizes[14]};
  color: ${({ theme }) => theme.utils.getColor('lightGrey', 750, 'flat')};
`;

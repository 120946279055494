import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { __TOKEN__ } from 'src/utils/constants';
import cookies from 'src/utils/cookies';

import { CustomRouteProps } from '../types';

const PublicRoute: React.FC<CustomRouteProps> = ({
  component: Component,

  ...rest
}) => {
  const token = cookies.get(__TOKEN__);

  return (
    <Route
      {...rest}
      render={(props) => (!token ? <Component {...props} /> : <Redirect to="/dashboard" />)}
    />
  );
};

export default PublicRoute;

import { secondsToTime } from 'src/utils/timeHelpers';

import { confirmed_match, ConfirmedMatch } from '../types';

const transformResponse = (results: ConfirmedMatch[]) => {
  results.map((item: ConfirmedMatch) => {
    item.asset.confirmed_total = secondsToTime(
      item.confirmed_matches.reduce(
        (acc: number, { matched_from, matched_to }: confirmed_match) =>
          acc + Math.floor(+matched_to - +matched_from),
        0
      ),
      false
    );

    item.confirmed_matches.map((matches: confirmed_match) => {
      matches.matched_from = secondsToTime(+matches.matched_from, false);
      matches.matched_to = secondsToTime(+matches.matched_to, false);

      return matches;
    });
    return item;
  });

  return results;
};

export default transformResponse;

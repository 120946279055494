import styled from '@emotion/styled';
import { ButtonColorPicker } from 'src/utils/common';
import { ButtonStatus } from 'src/utils/constants';

type WrapperProps = {
  status?: ButtonStatus;
  fill?: boolean;
};

export const Wrapper = styled.button<WrapperProps>`
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.09;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  background-color: ${({ theme, status = ButtonStatus.Success, fill }) =>
    fill ? ButtonColorPicker(status, theme.colors.buttons) : 'transparent'};
  border-color: ${({ theme, status = ButtonStatus.Success }) =>
    ButtonColorPicker(status, theme.colors.buttons)};
  color: ${({ fill }) => (fill ? '#ffffff' : '#4a4a4a')};
  border-width: 1.2px;
  border-radius: 4px;
  border-style: solid;
  padding: 10px;
  width: 100%;
  min-width: 155px;

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${({ theme, status = ButtonStatus.Success }) =>
      ButtonColorPicker(status, theme.colors.buttons)};
    border-color: ${({ theme, status = ButtonStatus.Success }) =>
      ButtonColorPicker(status, theme.colors.buttons)};
    color: white;
    transition: 0.25s;
  }

  > svg * {
    fill: ${({ theme, status = ButtonStatus.Success, fill }) =>
      fill ? '#fff' : ButtonColorPicker(status, theme.colors.buttons)};
  }

  > svg {
    margin: 0 4px -2px;
    width: 20px;
    height: 12px;
  }
`;

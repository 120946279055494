import omit from 'lodash/omit';
import React from 'react';
import { WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import { FormError } from 'src/components/Forms';
import Dropdown from 'src/components/Select';
import { SelectOption } from 'src/components/Select';

import { Anything } from '../../../utils/globalTypes';

type Props = {
  id: string;
  options: SelectOption[];
  defaultOptions?: SelectOption[];
  loading: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  onInputChange?(): void;
  defaultValue?: Anything;
} & WrappedFieldProps &
  WrappedFieldMetaProps;

const BaseSelectField: React.FC<Props> = ({
  input,
  meta: { error },
  id,
  options,
  disabled = false,
  loading,
  placeholder,
  isSearchable,
  onInputChange,
  defaultValue,
}) => {
  const newInput = omit(input, ['onFocus']);
  return (
    <React.Fragment>
      <Dropdown
        id={id}
        {...newInput}
        defaultValue={defaultValue}
        options={options}
        loading={loading}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isDisabled={disabled}
        onInputChange={onInputChange}
        onBlur={() => newInput.onBlur(newInput.value)}
      />
      {error && <FormError>{error}</FormError>}
    </React.Fragment>
  );
};

export default BaseSelectField;

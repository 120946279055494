import isEmpty from 'lodash/isEmpty';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import {
  claimAnalyticsLoadingActions,
  fetchClaimAnalyticsRequest,
  setClaimAnalytics,
  fetchClaimAnalyticsFail,
} from 'src/ducks/claimAnalytics/actions';
import {
  FETCH_CLAIM_ANALYTICS_REQUEST,
  FetchClaimAnalyticsRequestAction,
  ClaimAnalyticsReducer,
} from 'src/ducks/claimAnalytics/types';
import {
  CHANGE_FILTERS,
  ChangeFilterAction,
  FETCH_FILTERS_SUCCESS,
  FetchFiltersSuccessAction,
  getFilterOptions,
  getSelectedStrategy,
  getSort,
} from 'src/ducks/filters';
import { handleParams } from 'src/ducks/filters/utils';
import { getGlobalSearch } from 'src/ducks/globalSearch';
import { analyticsAPI } from 'src/providers';
import { ANALYTICS_PAGES } from 'src/utils/constants';

import { initialState } from './reducer';

const triggerFetchActionsBasedOnStateFilters = (state: AppState) => {
  const selected = getSelectedStrategy(state);
  if (selected?.value && ANALYTICS_PAGES.CLAIM_ANALYTICS !== (selected?.value as string)) {
    return of();
  }
  const filters = getFilterOptions(state);
  const sort = getSort(state);
  const q = getGlobalSearch(state);
  const params = handleParams(filters, sort, selected, q, 1);
  return concat(
    of(claimAnalyticsLoadingActions.setLoading(true)),
    of(fetchClaimAnalyticsRequest(params))
  );
};

const filtersSucceededEpic = (
  action$: ActionsObservable<FetchFiltersSuccessAction | ChangeFilterAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_FILTERS_SUCCESS, CHANGE_FILTERS),
    switchMap(() => {
      const state = state$.value;
      return triggerFetchActionsBasedOnStateFilters(state);
    })
  );

const fetchClaimAnalyticsEpic = (action$: ActionsObservable<FetchClaimAnalyticsRequestAction>) =>
  action$.pipe(
    ofType(FETCH_CLAIM_ANALYTICS_REQUEST),
    switchMap(({ payload }) => {
      return concat(
        of(claimAnalyticsLoadingActions.setLoading(true)),
        from(
          analyticsAPI.bulk
            .get<ClaimAnalyticsReducer>({
              ...payload,
            })
            .request()
        ).pipe(
          mergeMap((data) => {
            return of(setClaimAnalytics(!isEmpty(data) ? data : initialState));
          }),
          catchError(() => of(fetchClaimAnalyticsFail()))
        ),
        of(claimAnalyticsLoadingActions.setLoading(false))
      );
    })
  );

export default {
  filtersSucceededEpic,
  fetchClaimAnalyticsEpic,
};

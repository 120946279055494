import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';
import { withSearchReducer } from 'src/ducks/factories/search';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_CONFIRMED_MATCHES_FAILURE,
  ReviewActionTypes,
  ConfirmedMatchesReducer,
  SET_CONFIRMED_MATCHES,
  SET_CONFIRMED_MATCHES_COUNT,
  REMOVE_CONFIRMED_MATCH,
} from './types';

const initialState: ConfirmedMatchesReducer = {
  list: [],
  countItems: 0,
};

function confirmedMatch(state = initialState, action: ReviewActionTypes): ConfirmedMatchesReducer {
  switch (action.type) {
    case SET_CONFIRMED_MATCHES:
      return {
        ...state,
        list: action.payload,
      };
    case REMOVE_CONFIRMED_MATCH:
      return {
        ...state,
        list: state.list.filter((item) => action.payload.indexOf(item.id) === -1),
        countItems: state.countItems - action.payload.length,
      };
    case SET_CONFIRMED_MATCHES_COUNT:
      return {
        ...state,
        countItems: action.payload,
      };
    case FETCH_CONFIRMED_MATCHES_FAILURE:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
}

export default compose(
  withSearchReducer<ConfirmedMatchesReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withPaginationReducer<ConfirmedMatchesReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withLoadingReducer<ConfirmedMatchesReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(confirmedMatch);

import { Menu } from '@orfium/ictinus';
import head from 'lodash/head';
import React from 'react';
import { User } from 'src/ducks/user';
import urls from 'src/urls';

const searchPlaceholderPerPage = {
  [urls.claimList()]: 'Search Claim User, Asset ID, Video ID…',
  [urls.globalReview()]: 'Search Asset ID, Video ID, Channel ID, Free text…',
};

export const pickPlaceholderPerPage = (page: string) => searchPlaceholderPerPage[page] || 'Search';

export const getAdditionalTools = (
  user: User,
  updateUserSelectedAccount: (accountId: string | number) => void
) => {
  const clients = user.accounts.map((account) => account.name);

  const selectedClient = user.accounts
    .filter((client) => client.selected)
    .map((account) => account.name);

  return (
    <Menu
      items={clients}
      buttonText={head(selectedClient)}
      rightIconName={'arrowDown'}
      color={'lightGrey-50'}
      onSelect={(option: string) =>
        updateUserSelectedAccount(user.accounts.filter((client) => client.name === option)[0].id)
      }
    />
  );
};

import React from 'react';
import { ReactComponent as LoaderSVG } from 'src/assets/input-loader.svg';
import Label from 'src/components/Label';

import {
  SelectContainer,
  SelectBox,
  Select,
  SelectLoading,
  SelectDark,
  SelectGrey,
  SelectUserMenu,
} from './Select.style';

export type SelectOption = { value: string | number; label: string };

export type Props = {
  options?: SelectOption[];
  name?: string;
  label?: string;
  loading?: boolean;
  isDisabled?: boolean;
  theme?: string;
  rounded?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  defaultValue?: SelectOption | null;
  value?: SelectOption | null;
  id?: string;
  onChange?(option: SelectOption): void;
  filterOption?(): SelectOption[];
  onBlur?(): void;
  onInputChange?(val: string, info: { action: string }): void;
};

const SelectedComponent: React.FC<Props> = ({ theme, ...rest }) => {
  switch (theme) {
    case 'grey':
      return <SelectGrey classNamePrefix="Select" {...rest} />;
    case 'dark':
      return <SelectDark classNamePrefix="Select" {...rest} />;
    case 'userMenu':
      return <SelectUserMenu classNamePrefix="Select" {...rest} />;
    default:
      return <Select classNamePrefix="Select" {...rest} />;
  }
};
const Dropdown: React.FC<Props> = ({
  theme = 'default',
  loading,
  label,
  isSearchable = false,
  ...rest
}) => (
  <SelectContainer theming={theme}>
    <Label>{label}</Label>
    {loading && (
      <SelectLoading>
        <LoaderSVG />
      </SelectLoading>
    )}
    <SelectBox isDisabled={rest.isDisabled}>
      <SelectedComponent
        theme={theme}
        isSearchable={isSearchable}
        // ignore the filtering as this is being done by the server
        // if we skip this we only filter based on the value shown
        filterOption={() => rest.options || []}
        {...rest}
      />
    </SelectBox>
  </SelectContainer>
);

const defaultProps = {
  theme: 'default',
  rounded: false,
  label: '',
  defaultValue: null,
};

Dropdown.defaultProps = defaultProps;

export default Dropdown;

import head from 'lodash/head';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { validateAssetForm } from 'src/components/Forms/validators';
import { AppState } from 'src/ducks';
import { getFilterActions } from 'src/ducks/legacyFilters';
import { defaultRejectOption, FORMS } from 'src/utils/constants';

import AssetForm, { Props, FormArgs } from './AssetForm';

const mapStateToProps = (state: AppState, { asset, getVideoDuration }: Props) => {
  const {
    asset: { title, asset_id, reference_url, artist },
  } = asset;

  return {
    actions: getFilterActions(state),
    initialValues: {
      getVideoDuration,
      originalAssets: [
        {
          option: {
            value: `${title} ${artist && `(${artist})`} ${asset_id && `<${asset_id}>`}`,
            reference_url,
          },
          action: defaultRejectOption,
        },
      ],
      otherAssets: [
        {
          option: {
            value: '',
            reference_url: '',
          },
          action: head(getFilterActions(state)) || defaultRejectOption,
          matches: [
            {
              matched_from: 0,
              matched_to: 0,
            },
          ],
        },
      ],
    },
  };
};

const AssetFormRedux = reduxForm<FormArgs, Props>({
  form: FORMS.REVIEW_QUEUE_EDIT_ASSET,
  validate: validateAssetForm,
  shouldError: ({ nextProps }) => {
    return Boolean(nextProps && (nextProps.submitting || nextProps.dirty));
  },
})(AssetForm);

export default connect(mapStateToProps, null)(AssetFormRedux);

import React from 'react';
import { ReactComponent as SavingSVG } from 'src/assets/loader.svg';
import { ButtonStatus } from 'src/utils/constants';

import { Wrapper } from './Button.style';

type Props = {
  status?: ButtonStatus;
  fill?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?(e: React.MouseEvent): void;
};

// Usage Example: <Button status={ButtonStatus.Success}>Confirm</Button>

const Button: React.FC<Props> = ({ children, fill = false, loading, ...rest }) =>
  children ? (
    <Wrapper fill={fill ? fill : undefined} {...rest}>
      {loading ? <SavingSVG /> : children}
    </Wrapper>
  ) : null;

Button.defaultProps = {
  status: ButtonStatus.Success,
  fill: false,
  loading: false,
};

export default Button;

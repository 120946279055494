import styled from '@emotion/styled';
import { SelectContainer } from 'src/components/Select/Select.style';
import { flex, flexCenter, flexCenterVertical, grid } from 'src/theme/functions';

export const Wrapper = styled.div`
  margin: auto;
  width: 1060px;
`;

export const Heading = styled.title`
  color: #4a4a4a;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 25px;
  line-height: 1.07;
  display: block;
`;

export const VideoContainer = styled.div`
  ${flex};
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  background-color: #f5f5f6;
  display: flex;
  justify-content: center;
`;

export const YoutubeWrapper = styled.div`
  margin-right: 15px;
`;

export const DetailItem = styled.div`
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
  span {
    color: #4a4a4a;
  }
  span:first-of-type {
    margin-right: 20px;
    font-size: 11px;
    width: 70px;
    display: inline-block;
  }
  span:last-of-type {
    font-weight: bold;
    font-size: 14px;
  }
`;

export const ButtonWrapper = styled.div`
  ${grid};
  grid-template-columns: repeat(2, 1fr);
  width: 50%;
  margin: 32px auto 0;
  grid-column-gap: 16px;
`;

export const BottomPanel = styled.div`
  ${flex};
  align-items: flex-start;
  margin-bottom: 15px;
  font-size: 11px;

  > * {
    margin: 0 8px;
  }

  > span:first-of-type {
    text-align: right;
  }

  ${SelectContainer} {
    max-width: 175px;
  }
`;

type BottomPanelAssetWrapperProps = { highlighted?: boolean };

export const BottomPanelAssetWrapper = styled.div<BottomPanelAssetWrapperProps>`
  flex: 1;
  display: flex;
  border-radius: 4px;
  background-color: ${({ highlighted }) => (highlighted ? '#83afe5' : '#f5f5f6')};
  color: ${({ highlighted }) => (highlighted ? '#fff' : '#000')};

  ${SelectContainer} {
    padding: 0;
    max-width: 100%;
  }

  > span {
    padding: 9px 14px;
    display: inline-block;
  }
`;

export const BottomPanelDetailsWrapper = styled.div`
  ${flex};
  ${flexCenterVertical};
  flex: 1;

  > * {
    margin: 0 8px;
  }
`;

export const ReferenceLink = styled.a`
  ${flexCenter};
  color: #83afe5;
  width: 62px;

  svg {
    align-self: center;
    width: 7px;
    height: 9px;
    margin-right: 5px;
  }

  span {
    flex: 1;
    font-size: 7px;
    line-height: 1.14;
  }
`;

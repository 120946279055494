import styled from '@emotion/styled';
import TimeField from 'react-simple-timefield';

type Props = {
  showSeconds?: boolean;
};

export const Separator = styled.span<Props>`
  align-self: center;
`;

export const ButtonWrapper = styled.button<Props>`
  padding: 4px 15px;
  height: 32px;
  border-radius: 4px;
  background-color: #eb5e27;
  color: #fff;
  text-transform: capitalize;
  font-size: 10px;
`;

export const InputWrapper = styled(TimeField)`
  margin: 0 8px 0 8px;
  padding: 0 7px;
  height: 32px;
  border-radius: 2px;
  background-color: #f5f5f6;
  width: 92px !important;
  text-align: left;

  font-size: 13px;
`;

export const TimestampSpan = styled.span<Props>``;

export const TimestampContainer = styled.span<Props>`
  display: flex;
`;

import isEmpty from 'lodash/isEmpty';
import unionBy from 'lodash/unionBy';
import queryString from 'query-string';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { normalizationData } from 'src/ducks/filters/normalization/utils';
import {
  getFilterOptions,
  getSelectedStrategy,
  getSort,
  getFilters,
} from 'src/ducks/filters/selectors';
import { handleParams } from 'src/ducks/filters/utils';
import {
  getGlobalSearch,
  resetGlobalSearch,
  SET_GLOBAL_SEARCH,
  setGlobalSearch,
  SetGlobalSearchAction,
} from 'src/ducks/globalSearch';
import { getPage } from 'src/ducks/router';
import {
  getSelectedAccount,
  UPDATE_USER_SELECTED_ACCOUNT,
  UpdateUserSelectedAccount,
} from 'src/ducks/user';
import { filtersAPI } from 'src/providers';
import urls from 'src/urls';
import { apiPagesMap, START_DATE_PREFIX } from 'src/utils/constants';

import { Anything } from '../../utils/globalTypes';
import { fetchFiltersFail, fetchFiltersSuccess, filterLoadingActions } from './actions';
import {
  CHANGE_FILTERS,
  CHANGE_SORT,
  ChangeFilterAction,
  ChangeSortAction,
  FETCH_FILTERS,
  FETCH_FILTERS_SUCCESS,
  FetchFiltersAction,
  FetchFiltersSuccessAction,
} from './types';

const fetchFiltersEpic = (
  action$: ActionsObservable<FetchFiltersAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_FILTERS),
    switchMap(({ payload: { strategy, loading = true } }) => {
      return concat(
        of(filterLoadingActions.setLoading(loading), resetGlobalSearch()),
        from(filtersAPI.single.options({ type: apiPagesMap(strategy) }).request()).pipe(
          mergeMap((data: Anything) => {
            const stateFilters = getFilters(state$.value);
            const persistedState = queryString.parse(window.location.search) as {
              [key: string]: string;
            };

            const normData = normalizationData(data, persistedState.strategy || strategy);
            const newfilters = {
              ...normData,
              filters: unionBy(stateFilters.filters, normData.filters, 'value'),
              actions: unionBy(stateFilters.actions, normData.actions, 'value'),
              sort: unionBy(
                normData.sort,
                stateFilters.sort.map((sort) => ({ ...sort, selected: false })),
                'value'
              ),
              strategies: unionBy(stateFilters.strategies, normData.strategies, 'value'),
              added: stateFilters.added,
            };

            const mergedData = {
              ...newfilters,
              filters: newfilters.filters.map((filter) => {
                const stateOption = persistedState[filter.value];

                if (filter.value === START_DATE_PREFIX) {
                  return {
                    ...filter,
                    options: stateOption
                      ? [
                          {
                            value: stateOption,
                            label: stateOption,
                            selected: true,
                            default: true,
                          },
                        ]
                      : filter.options,
                  };
                }

                if (
                  !stateOption ||
                  !filter.options.find((option) => option.value.toString() === stateOption)
                ) {
                  return {
                    ...filter,
                    options: filter.options,
                  };
                }

                return {
                  ...filter,
                  options: filter.options.map((option) => {
                    return {
                      ...option,
                      selected: stateOption === option.value.toString(),
                    };
                  }),
                };
              }),
              sort: newfilters.sort.map((sort) => {
                const stateOption = persistedState?.sort_by;
                return {
                  ...sort,
                  selected: stateOption ? stateOption === sort.value.toString() : sort.selected,
                };
              }),
            };

            return persistedState?.q && persistedState.q !== ''
              ? [
                  fetchFiltersSuccess(isEmpty(persistedState) ? newfilters : mergedData),
                  setGlobalSearch(persistedState.q),
                ]
              : [fetchFiltersSuccess(isEmpty(persistedState) ? newfilters : mergedData)];
          }),
          catchError(() => of(fetchFiltersFail()))
        ),
        of(filterLoadingActions.setLoading(false))
      );
    })
  );

const changeUrlFiltersEpic = (
  action$: ActionsObservable<
    | FetchFiltersSuccessAction
    | ChangeFilterAction
    | ChangeSortAction
    | SetGlobalSearchAction
    | UpdateUserSelectedAccount
  >,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(
      FETCH_FILTERS_SUCCESS,
      CHANGE_FILTERS,
      CHANGE_SORT,
      SET_GLOBAL_SEARCH,
      UPDATE_USER_SELECTED_ACCOUNT
    ),
    switchMap(() => {
      const selected = getSelectedStrategy(state$.value);
      //TODO: remove split when all pages use new filters
      const page = getPage(state$.value).split('?')[0];
      // allow only globalReview, leadsGeneration pages and legacy claims
      if (
        !selected ||
        ![
          urls.globalReview(),
          urls.leadsGeneration(),
          urls.keywordManagement(),
          urls.claimAnalytics(),
          urls.legacyClaims(),
        ].includes(page)
      ) {
        return of();
      }
      const filters = getFilterOptions(state$.value);
      const sort = getSort(state$.value);
      const q = getGlobalSearch(state$.value);
      const selectedAccount = getSelectedAccount(state$.value);
      const params = handleParams(filters, sort, selected, q, undefined, selectedAccount);

      //TODO: Add page again to url params and pass value to pagination and models.
      const { page: __ignored, ...rest } = params;
      // side effect that changes the url when we fetch new filters or change them
      window.history.pushState('', '', `?${queryString.stringify(rest)}`);

      return of();
    })
  );

export default {
  fetchFiltersEpic,
  changeUrlFiltersEpic,
};

import styled from '@emotion/styled';
import { rem } from 'polished';

import { StatusTypes } from '../../../../types';

type Props = {
  isValidating: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing.xl} 0 ${({ theme }) => theme.spacing.md};
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${rem(470)};
  font-weight: ${({ theme }) => theme.typography.weights.bold};
  font-size: ${({ theme }) => theme.typography.fontSizes[14]};
  color: ${({ theme }) => theme.utils.getColor('darkGrey', 850, 'flat')};
`;

export const TitleGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Size = styled.span`
  color: ${({ theme }) => theme.utils.getColor('lightGrey', 650, 'flat')};
  margin-left: ${({ theme }) => theme.spacing.xsm};
  font-weight: ${({ theme }) => theme.typography.weights.regular};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const LoaderContainer = styled.div`
  width: ${rem(470)};
`;

export const ActionContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 0 0 0 ${rem(12)};
  font-weight: ${({ theme }) => theme.typography.weights.regular};
  font-size: ${({ theme }) => theme.typography.fontSizes[14]};
  color: ${({ theme }) => theme.utils.getColor('darkBlue', 400)};
  visibility: ${({ isValidating }) => (isValidating ? 'hidden' : 'visible')};
`;

export const Message = styled.div<{ type: StatusTypes }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: ${({ theme }) => theme.typography.weights.regular};
  font-size: ${({ theme }) => theme.typography.fontSizes[12]};
  color: ${({ theme, type }) =>
    type === 'error'
      ? theme.utils.getColor('red', 500, 'flat')
      : theme.utils.getColor('lightGrey', 650, 'flat')};
`;

import flattenDeep from 'lodash/flattenDeep';
import React, { Suspense, useEffect, LazyExoticComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'src/components/utils/Routing/PrivateRoute';
import PublicRoute from 'src/components/utils/Routing/PublicRoute';
import config from 'src/config';
import { MenuItem } from 'src/config/types';
import { AppState } from 'src/ducks';
import { changePage } from 'src/ducks/router';
import { Ability, getAbility } from 'src/ducks/user';
import { lazyWithRetry } from 'src/utils/lazyWithRefresh';

import urls from './urls';
import { Anything } from './utils/globalTypes';

const LoginPage = lazyWithRetry(() => import('src/pages/LoginPage'));

type ReduxActions = { changePage(page: string): void };
type ReduxProps = {
  ability: Ability | undefined;
};

// eslint-disable-next-line react/display-name
const LazyComponent = (Component: Anything) => (props: Anything) =>
  (
    <Suspense fallback={<div>Loading Page...</div>}>
      <Component {...props} />
    </Suspense>
  );

type MenuItemReduced = {
  component: LazyExoticComponent<Anything>;
  path: string;
  exact: boolean;
};

const menuItems = config.menuItems;

const Routes: React.FC<ReduxActions & RouteComponentProps & ReduxProps> = ({
  changePage,
  location,
  ability,
}) => {
  useEffect(() => {
    changePage(location.pathname);
  }, [changePage, location]);

  const routes = React.useMemo(
    () =>
      menuItems(ability as Ability).reduce<MenuItemReduced[]>((acc, item) => {
        const getItemsWithComponents = (item: MenuItem) => {
          let items: MenuItemReduced[] = [];

          if (item.options.length) {
            const itemsWithComponentsFromOptions = item.options.map(getItemsWithComponents);

            items = [...flattenDeep<MenuItemReduced>(itemsWithComponentsFromOptions)];
          }

          if (item.component && item.visible) {
            items.push({
              component: item.component,
              path: item.url || '',
              exact: Boolean(item.exact),
            });
          }

          return items;
        };

        const allComponentsFromRouteItem = getItemsWithComponents(item);

        return [...acc, ...allComponentsFromRouteItem];
      }, []),
    [ability]
  );

  return (
    <Switch>
      <PublicRoute exact path={urls.login()} component={LazyComponent(LoginPage)} />
      {routes.map((menuItem) => (
        <PrivateRoute
          key={menuItem.path}
          exact={menuItem.exact}
          path={menuItem.path}
          component={LazyComponent(menuItem.component)}
        />
      ))}
      <PrivateRoute disabled path={'/'} />
      {/*<PrivateRoute exact path={urls.dashboard()} component={LazyComponent(Dashboard)} />*/}
    </Switch>
  );
};

const mapDispatchToProps = {
  changePage,
};

const mapStateToProps = (state: AppState) => ({
  ability: getAbility(state),
});

export default withRouter(
  connect<ReduxProps, ReduxActions, unknown, AppState>(mapStateToProps, mapDispatchToProps)(Routes)
);

import { FiltersState } from 'src/ducks/legacyFilters';
import { Anything } from 'src/utils/globalTypes';

import { PagesType } from '../../utils/constants';

export const CHANNELS_UPLOAD_ID = 'CHANNELS_UPLOAD_ID';
export const ASSETS_UPLOAD_ID = 'ASSETS_UPLOAD_ID';
export const LEGACY_CLAIMS_UPLOAD_ID = 'LEGACY_CLAIMS_UPLOAD_ID';
export const KEYWORD_MANAGEMENT_UPLOAD_ID = 'KEYWORD_MANAGEMENT_UPLOAD_ID';

export const ASSETS_DOWNLOAD_ID = 'ASSETS_DOWNLOAD_ID';
export const CHANNELS_DOWNLOAD_ID = 'CHANNELS_DOWNLOAD_ID';
export const LEGACY_CLAIMS_DOWNLOAD_ID = 'LEGACY_CLAIMS_DOWNLOAD_ID';
export const CONFIRMED_DOWNLOAD_ID = 'CONFIRMED_DOWNLOAD_ID';
export const KEYWORD_MANAGEMENT_DOWNLOAD_ID = 'KEYWORD_MANAGEMENT_DOWNLOAD_ID';

export type FileInfoType = { key: string; bucket: string };

type JobType = {
  id: number;
  submitted: string;
  user: {
    id: number;
    name: string;
  };
  actor: {
    id: number;
    name: string;
  };
  title: string;
  type: string;
  status: 'Success' | 'Failed' | 'Submitted' | 'Running';
  progress: string;
  input: FileInfoType[] | null;
  output: FileInfoType[] | null;
  message: string;
  time_elapsed: number;
};

type ChannelsJobDetails = null | {
  total: number;
  current: number;
  new_cnt: number;
  updated_cnt: number;
  faulty: number;
  ignored: number;
};

export type ChannelsJobType = JobType & {
  details: ChannelsJobDetails;
};

type AssetsJobDetails = null | {
  total: number;
  current: number;
  new_cnt: number;
  updated_cnt: number;
  reset_cnt: number;
  failed_cnt: number;
  ignored: number;
};

export type AssetsJobType = JobType & {
  details: AssetsJobDetails;
};

export type LegacyClaimsDetails = null | {
  total: number;
  faulty: number;
  current: number;
  new_cnt: number;
  ignored: number;
  claimable: number;
  already_claimed_by_orfium: number;
  already_claimed_by_others: number;
  whitelisted: number;
};

export type LegacyClaimsJobType = JobType & {
  details: LegacyClaimsDetails;
};

export type UploadCSVPayload = {
  page: PagesType;
  file: { file: File; name: string };
  fileName: string;
  fileSize?: number;
  createVideos?: boolean;
};

export type pagesFunc = {
  upload: {
    id: string;
    getPayload: ({ fileName }: UploadCSVPayload) => {
      destination: string;
      title: string;
    } | null;
  };
  download: {
    id: string;
  };
};

export type pagesFuncsMap = {
  [index: string]: pagesFunc;
};

export type pagesContent = {
  title: string;
  tooltipContent: string;
  customContent: (
    data: Anything,
    downloadS3: (payload: DownloadS3CSVPayload) => void
  ) => {
    title?: string;
    onError?: () => void;
    results: { [index: string]: number };
    onDownload?: () => void;
  };
};

export type pagesContentMap = {
  [index: string]: pagesContent;
};

export type DownloadStaticPayload = {
  url: string;
  fileName: string;
  fileId?: string;
  page: PagesType;
};

export type DownloadS3CSVPayload = {
  files: FileInfoType[];
  page: PagesType;
};

export type DownloadURLPayload = {
  filters: FiltersState;
  page: PagesType;
  paginationPage: number;
  q: string;
};

export type GetJobProgressPayload = {
  page: PagesType;
  id: number;
};

export type FileItem = {
  id: string;
  state: string;
};

export type FileHandlerContextProps = {
  downloading: FileItem[];
  uploading: FileItem[];
  hasItemUploading: (id: string) => boolean;
  uploadS3: (payload: UploadCSVPayload) => void;
  downloadS3: (payload: DownloadS3CSVPayload) => void;
  download: (payload: DownloadStaticPayload) => void;
  downloadUrl: (payload: DownloadURLPayload) => void;
  jobProgress: (payload: GetJobProgressPayload) => void;
  cancelUpload: (page: PagesType) => void;
  cancelDownload: (page: PagesType, fileId: string) => void;
};

import styled from '@emotion/styled';
import { flexCenter } from 'src/theme/functions';

export const Wrapper = styled.div`
  ${flexCenter};
  border-radius: 6px;
  border: 1px solid rgba(${({ theme }) => theme.colors.formElementError}, 0.6);
  background-color: rgba(${({ theme }) => theme.colors.formElementError}, 0.2);
  padding: 10px;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  text-align: center;
`;

import { useCallback, useEffect, useState } from 'react';
import urls from 'src/urls';

type GlobalSearchReturnProps = [
  string,
  (e: React.FormEvent<HTMLInputElement>) => void,
  (e: React.KeyboardEvent<HTMLInputElement>) => void,
  boolean
];

export const useGlobalSearch = (
  globalSearch: string,
  page: string,
  setGlobalSearch: (s: string) => void
): GlobalSearchReturnProps => {
  const [inputValue, setInputValue] = useState(globalSearch);

  useEffect(() => {
    setInputValue(globalSearch);
  }, [globalSearch]);

  const handleSearch = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const element = e.currentTarget as HTMLInputElement;
      setInputValue(element.value);
    },
    [setInputValue]
  );

  const handleKeyPress = useCallback(
    (target: React.KeyboardEvent<HTMLInputElement>) => {
      if (target.charCode === 13) {
        setGlobalSearch(inputValue);
      }
    },
    [setGlobalSearch, inputValue]
  );

  const disabled = Boolean(
    page.split('?')[0] !== urls.globalReview() &&
      page !== urls.claimList() &&
      page !== urls.legacyClaims()
  );

  return [inputValue, handleSearch, handleKeyPress, disabled];
};

import React from 'react';
import { ReactComponent as LoaderSVG } from 'src/assets/loader.svg';

import { Wrapper } from './Loader.style';

type Props = {
  size?: 'small' | 'big';
};

const Loader: React.FC<Props> = ({ size }) => {
  return (
    <Wrapper size={size}>
      <LoaderSVG />
    </Wrapper>
  );
};

Loader.defaultProps = {
  size: 'big',
};

export default Loader;

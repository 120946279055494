import debounce from 'lodash/debounce';
import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { SelectOption } from 'src/components/Select';
import { reviewAssetsSearchAPI } from 'src/providers';

type AssetsShapeType = {
  title: string;
  artist: string;
  asset_id: string;
  reference_url: string;
};

type fetchParams = { q?: string };

type ServerAssetsReturnProps = [
  (params: { q: string }) => void,
  SelectOption[],
  boolean,
  fetchParams
];

const normalizeAssets = (data: AssetsShapeType[]): SelectOption[] =>
  data.map(({ title, artist, asset_id, ...rest }) => ({
    label: `${title} (${artist}) <${asset_id}>`,
    value: asset_id,
    ...rest,
  }));

const useServerAssets = (
  initialExternalFetch = false,
  initialExternalParams: Record<string, unknown>
): ServerAssetsReturnProps => {
  const initialFetch = useRef(initialExternalFetch);
  const initialParams = useMemo(() => initialExternalParams, [initialExternalParams]);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [fetchParams, setFetchParams] = useState({});

  const fetchingAssets = useCallback(
    (params = initialParams || {}) => {
      const getReviewAssetSearch = async () => {
        initialFetch.current = false;
        setLoading(true);
        setFetchParams(params);

        const data: AssetsShapeType[] = await reviewAssetsSearchAPI.single.search(params).request();
        setOptions(normalizeAssets(data));
        setLoading(false);
      };

      if (!loading) {
        getReviewAssetSearch();
      }
    },
    [loading, setFetchParams, initialParams, setOptions]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAutompleteAssets = useCallback(debounce(fetchingAssets, 500), []);

  useEffect(() => {
    if (initialFetch.current) {
      fetchingAssets();
    }
  }, [fetchingAssets, initialFetch]);

  return [fetchAutompleteAssets, options, loading, fetchParams];
};

export default useServerAssets;

import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { withSearchActions } from 'src/ducks/factories/search';

import { Anything } from '../../utils/globalTypes';
import { simpleAction } from '../utils';
import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_LEGACY_CLAIMS_COUNT_REQUEST,
  FETCH_LEGACY_CLAIMS_FAILURE,
  FETCH_LEGACY_CLAIMS_REQUEST,
  FetchLegacyClaimsRequestAction,
  FetchLegacyClaimsRequestFailureAction,
  FetchLegacyClaimsResponseType,
  SET_LEGACY_CLAIMS,
  SET_LEGACY_CLAIMS_COUNT,
  SetLegacyClaimsAction,
} from './types';

export const setLegacyClaims = (payload: FetchLegacyClaimsResponseType): SetLegacyClaimsAction =>
  simpleAction(SET_LEGACY_CLAIMS, payload);

export const fetchLegacyClaimsRequest = (params: Anything): FetchLegacyClaimsRequestAction =>
  simpleAction(FETCH_LEGACY_CLAIMS_REQUEST, params);

export const fetchLegacyClaimsFailure = (): FetchLegacyClaimsRequestFailureAction =>
  simpleAction(FETCH_LEGACY_CLAIMS_FAILURE);

export const fetchLegacyClaimsCountRequest = (params: Record<string, unknown>) =>
  simpleAction(FETCH_LEGACY_CLAIMS_COUNT_REQUEST, params);

export const setLegacyClaimsCount = (data: number): { type: string; payload: number } =>
  simpleAction(SET_LEGACY_CLAIMS_COUNT, data);

export const legacyClaimsLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const legacyClaimsPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

export const legacyClaimsSearchActions = withSearchActions(__REDUX_STATE_KEY__);

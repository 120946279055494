import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';
import { withSearchReducer } from 'src/ducks/factories/search';

import { LEADS_PAGES } from '../../utils/constants';
import { __REDUX_STATE_KEY__ } from './selectors';
import {
  LeadsGenerationActionTypes,
  LeadsGenerationReducer,
  SET_LEADS_GENERATION,
  SET_LEADS_GENERATION_COUNT,
} from './types';

const initialState: LeadsGenerationReducer = {
  list: {
    [LEADS_PAGES.TOP_VIDEOS]: [],
    [LEADS_PAGES.TOP_KEYWORDS]: [],
    [LEADS_PAGES.TOP_ASSETS]: [],
    [LEADS_PAGES.TOP_CHANNELS]: [],
  },
  countItems: 0,
  estimate: false,
};

function leadsGenerationReducer(
  state = initialState,
  action: LeadsGenerationActionTypes
): LeadsGenerationReducer {
  switch (action.type) {
    case SET_LEADS_GENERATION:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.page]: action.payload.list,
        },
      };
    case SET_LEADS_GENERATION_COUNT:
      return {
        ...state,
        countItems: action.payload.count,
        estimate: action.payload.estimate,
      };
    default:
      return state;
  }
}

export default compose(
  withSearchReducer<LeadsGenerationReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withPaginationReducer<LeadsGenerationReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withLoadingReducer<LeadsGenerationReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(leadsGenerationReducer);

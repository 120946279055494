import isEqual from 'lodash/isEqual';
import React from 'react';
import { Field, FieldArray, GenericFieldArray, WrappedFieldArrayProps } from 'redux-form';
import { ReactComponent as PlaySVG } from 'src/assets/play-icon.svg';
import {
  BottomPanel,
  BottomPanelAssetWrapper,
  ReferenceLink,
  BottomPanelDetailsWrapper,
} from 'src/components/Forms/AssetForm/AssetForm.style';
import BaseArrayTimestamp from 'src/components/Forms/BaseArrayTimestamp';
import BaseSelectField from 'src/components/Forms/BaseSelectField';
import { NormalizedActionsOptions } from 'src/ducks/legacyFilters';
import { MatchesEntityResult } from 'src/ducks/review';
import { useServerAssets } from 'src/hooks';
import { defaultRejectOption, Timestamp } from 'src/utils/constants';

import { Anything } from '../../../../../utils/globalTypes';

export type Props = {
  title: string;
  videoRef?: Anything; // no need to have a type for this react ref
  highlighted?: boolean;
  autocomplete?: boolean;
  disabled?: boolean;
  actions?: NormalizedActionsOptions[];
  actionsWithRejectOption?: boolean;
  disableInitialFetch?: boolean;
  audioMatchSuggestions?: MatchesEntityResult[];
};

export type FieldProps = {
  action: NormalizedActionsOptions;
  option: {
    id?: string;
    value: string;
    reference_url: string;
  };
  matches: Timestamp[];
};

// type ArrayComponentProps = WrappedFieldArrayProps<FieldProps>;

const AssetEditedRow: React.FC<WrappedFieldArrayProps<FieldProps> & Props> = ({
  fields,
  title,
  highlighted,
  autocomplete = false,
  disabled = false,
  actions,
  videoRef,
  actionsWithRejectOption,
  disableInitialFetch,
  audioMatchSuggestions,
}) => {
  return (
    <React.Fragment>
      {fields.map((field, index: number) => (
        <RowAssetItem
          key={index}
          asset={fields.get(index)}
          field={field}
          disableInitialFetch={disableInitialFetch}
          videoRef={videoRef}
          actionsWithRejectOption={actionsWithRejectOption}
          actions={actions}
          disabled={disabled}
          autocomplete={autocomplete}
          highlighted={highlighted}
          title={title}
          audioMatchSuggestions={audioMatchSuggestions}
        />
      ))}
    </React.Fragment>
  );
};

export const FieldArrayRowAssetItem = FieldArray as new () => GenericFieldArray<FieldProps, Props>;

const RowAssetItem = ({
  asset,
  disableInitialFetch,
  field,
  videoRef,
  disabled,
  autocomplete,
  audioMatchSuggestions,
  title,
  highlighted,
  actions,
  actionsWithRejectOption,
}: Props & { asset: FieldProps; field: string }) => {
  const actionsWithNoReject = actionsWithRejectOption
    ? actions
    : actions?.filter((action) => !isEqual(defaultRejectOption, action));
  const [fetchAutompleteAssets, options, loading, serverAssetsParams] = useServerAssets(
    !disableInitialFetch,
    {
      q: asset.option.id,
    }
  );

  const formatGroupLabel = (data: Anything) => (
    <div style={{}}>
      <span>{data.label}</span>
      <span style={{}}>{data.options.length}</span>
    </div>
  );

  return (
    <BottomPanel>
      <BottomPanelDetailsWrapper>
        <span>{title}</span>
        <BottomPanelAssetWrapper highlighted={highlighted}>
          {!autocomplete ? (
            <span>{asset.option.value}</span>
          ) : (
            <Field
              component={BaseSelectField}
              id={`${field}.option`}
              name={`${field}.option`}
              placeholder="type something"
              isSearchable
              onInputChange={(q: Anything, { action }: Anything) => {
                if (action === 'input-change') {
                  fetchAutompleteAssets({ q });
                }
              }}
              initial={options.length > 0 ? options[0] : { label: '', value: '' }}
              options={
                !audioMatchSuggestions || serverAssetsParams.q
                  ? options
                  : [
                      {
                        label: 'Suggested',
                        value: 'Suggested',
                        options: audioMatchSuggestions.map(
                          ({ asset: { title, artist, asset_id } }) => ({
                            label: `${title} (${artist}) <${asset_id}>`,
                            value: asset_id,
                          })
                        ),
                      },
                      {
                        label: 'All Options',
                        value: 'All Options',
                        options,
                      },
                    ]
              }
              formatGroupLabel={formatGroupLabel}
              loading={loading}
            />
          )}
        </BottomPanelAssetWrapper>
        {asset.option.reference_url && (
          <ReferenceLink href={asset.option.reference_url} target="_blank">
            <PlaySVG />
            <span>Watch Reference Asset</span>
          </ReferenceLink>
        )}
        <Field
          component={BaseSelectField}
          id={`${field}.action`}
          name={`${field}.action`}
          disabled={disabled}
          options={autocomplete ? actionsWithNoReject : actions}
        />
      </BottomPanelDetailsWrapper>
      {asset.action.type !== defaultRejectOption.type &&
        asset.matches &&
        asset.matches.length > 0 &&
        videoRef && (
          <div style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <FieldArray
              name={`${field}.matches` as string}
              component={BaseArrayTimestamp}
              videoRef={videoRef}
            />
          </div>
        )}
    </BottomPanel>
  );
};

export default AssetEditedRow;

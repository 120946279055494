import { FilterParams } from 'src/ducks/filters';
import { Video, Channel } from 'src/ducks/globalReview';
import { SummaryResponse } from 'src/providers/claimAPI/types';

export const FETCH_CLAIMS_REQUEST = 'FETCH_CLAIMS_REQUEST';
export const FETCH_CLAIMS_FAILURE = 'FETCH_CLAIMS_FAILURE';
export const SET_CLAIMS = 'SET_CLAIMS';
export const FETCH_CLAIMS_COUNT_REQUEST = 'FETCH_CLAIMS_COUNT_REQUEST';
export const FETCH_CLAIMS_COUNT_FAILURE = 'FETCH_CLAIMS_COUNT_FAILURE';
export const SET_CLAIMS_COUNT = 'SET_CLAIMS_COUNT';
export const GET_CLAIMS_EXPORTS = 'GET_CLAIMS_EXPORTS';
export const FETCH_CLAIMS_SUMMARY = 'FETCH_CLAIMS_SUMMARY';
export const SET_CLAIMS_SUMMARY = 'SET_CLAIMS_SUMMARY';

export type FetchClaimsRequestAction = {
  type: typeof FETCH_CLAIMS_REQUEST;
  payload: ClaimParams;
};

export type FetchClaimsFailureAction = {
  type: typeof FETCH_CLAIMS_FAILURE;
};

export type SetClaimsAction = {
  type: typeof SET_CLAIMS;
  payload: Claim[];
};

export type FetchClaimsCountRequestAction = {
  type: typeof FETCH_CLAIMS_COUNT_REQUEST;
  payload: ClaimParams;
};

export type FetchClaimsCountFailureAction = {
  type: typeof FETCH_CLAIMS_COUNT_FAILURE;
};

export type SetClaimsCountAction = {
  type: typeof SET_CLAIMS_COUNT;
  payload: ClaimCountResponse;
};

export type GetClaimsExportsAction = {
  type: typeof GET_CLAIMS_EXPORTS;
};

export type FetchClaimsSummaryAction = {
  type: typeof FETCH_CLAIMS_SUMMARY;
};

export type SetClaimsSummaryAction = {
  type: typeof SET_CLAIMS_SUMMARY;
  payload: SummaryResponse;
};

export type ClaimParams = FilterParams;

export type ClaimCountResponse = {
  count: number;
  estimate: boolean;
};

export interface Asset {
  title?: string;
  artist?: string;
  asset_id: string;
  reference_url?: string;
}

export interface Claim {
  video: Video;
  asset: Asset;
  channel: Channel;
  retrieved: string;
  status?: string;
  claimed_date: string;
  claim_id: string;
  source: string;
  claim_owner: string;
  strategy: string;
  email: string;
  id: number;
  matched_timestamps: string[][];
}

export type ClaimActionTypes =
  | SetClaimsAction
  | SetClaimsCountAction
  | GetClaimsExportsAction
  | FetchClaimsSummaryAction
  | SetClaimsSummaryAction;

export type Summary = {
  [key: string]: number;
  channels: number;
  pending: number;
  suggested: number;
  assets: number;
  potential: number;
  inactive: number;
  manual: number;
  related: number;
};

export type ClaimReducer = {
  list: Claim[];
  countItems: number;
  estimate: boolean;
  summary: Summary | Record<string, unknown>;
  exportedDate: string;
};

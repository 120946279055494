import { RehydrateAction } from 'src/typings/redux-persist-custom';

import { Anything } from '../../utils/globalTypes';

export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const UPDATE_USER_SELECTED_ACCOUNT = 'UPDATE_USER_SELECTED_ACCOUNT';
export const SET_ABILITY = '  SET_ABILITY';

export interface UpdateUserSelectedAccount {
  type: typeof UPDATE_USER_SELECTED_ACCOUNT;
  payload: string;
}

export interface SetUserTokenAction {
  type: typeof SET_USER_TOKEN;
  payload: string;
}

export interface SetUserInfoAction {
  type: typeof SET_USER;
  payload: User;
}

export interface UnsetUserInfoAction {
  type: typeof UNSET_USER;
  payload: Record<string, unknown>;
}

export interface FetchUserInfoAction {
  type: typeof FETCH_USER_REQUEST;
  payload: Record<string, unknown>;
}

export interface LoginUserAction {
  type: typeof LOGIN_USER_REQUEST;
  payload: {
    formName: string;
    username: string;
    password: string;
  };
}

export interface SetUserAbilityAction {
  type: typeof SET_ABILITY;
  payload: Ability;
}

export type UserActionTypes =
  | LoginUserAction
  | SetUserInfoAction
  | UnsetUserInfoAction
  | SetUserTokenAction
  | FetchUserInfoAction
  | UpdateUserSelectedAccount
  | SetUserAbilityAction
  | RehydrateAction;

type Group = {
  id: number;
  name: string;
};

export type Account = {
  id: number;
  name: string;
  account_id: string;
  selected?: boolean;
  is_record_label: boolean;
};

export type LoginUser = {
  username: string;
  password: string;
};

export interface User {
  id: number | null;
  name: string;
  email: string;
  account: string;
  accounts: Account[];
  groups: Group[];
  token?: string;
  ability?: Ability;
}

export interface Ability {
  [key: string]: Anything;
  READ: {
    '/review/': boolean;
    '/review/review/': boolean;
    '/review/assets/': boolean;
    '/review/pending-claims/': boolean;
    '/review/related-videos/': boolean;
    '/review/potential-claims/': boolean;
    '/review/inactive-claims/': boolean;
    '/review/analytics-videos/': boolean;
    '/review/subscribed-channels/': boolean;
    '/review/review-history/': boolean;
    '/analytics/': boolean;
    '/analytics/claimer-analytics/': boolean;
    '/saved-for-later/': boolean;
    '/asset-strategy/': boolean;
    '/keyword-strategy/': boolean;
    '/channel-strategy/': boolean;
    '/confirmed-matches/': boolean;
    adminLink: boolean;
    oldVideoHunterLink: boolean;
    policyLinks: boolean;
  };
}

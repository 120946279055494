import React from 'react';
import 'tippy.js/dist/tippy.css';
import { Placement } from 'tippy.js/index';

import { Anything } from '../../utils/globalTypes';
import { TooltipStyle } from './Tooltip.style';

type Props = {
  children: Anything;
  content: React.ReactNode;
  placement?: Placement;
};

const Tooltip: React.FC<Props> = ({ children, content, placement }) => (
  <TooltipStyle content={content} placement={placement}>
    {children}
  </TooltipStyle>
);
export default Tooltip;

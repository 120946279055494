import { Button, Card, Icon, IconButton, useTheme } from '@orfium/ictinus';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { UploadItem, UPLOAD } from '../../types';
import {
  ActiveContainer,
  CloseContainer,
  DragDropContainer,
  Guidelines,
  ModalContainer,
  OrText,
  TemplateText,
  TitleContainer,
  UploadContainer,
} from './Upload.style';

export type Props = {
  item: {
    type: typeof UPLOAD;
    id: string | number;
    content: UploadItem;
    dropzoneConfig?: DropzoneOptions;
  };
};

const Upload: React.FC<Props> = ({ item }) => {
  const theme = useTheme();
  const { getRootProps, open, isDragActive, getInputProps } = useDropzone({
    onDrop: (r) => item.content.onSelected(r),
    ...item.dropzoneConfig,
  });

  return (
    <UploadContainer key={item.id} data-testid={'upload-container'}>
      <Card elevated={'02'} radius={'sm'}>
        <CloseContainer>
          <IconButton
            name={'close'}
            filled={false}
            transparent
            size={'sm'}
            dataTestId={'upload-close'}
            onClick={item.content.onClose}
            color={'lightGrey-650'}
          />
        </CloseContainer>
        <ModalContainer>
          <TitleContainer>{item.content.title}</TitleContainer>
          <DragDropContainer {...getRootProps()} data-testid={'upload-input'}>
            {isDragActive ? (
              <ActiveContainer>
                <p>Drop the files here ...</p>
              </ActiveContainer>
            ) : (
              <div style={{ textAlign: 'center', boxSizing: 'content-box' }}>
                <input {...getInputProps()} />
                <Icon name={'upload'} color={theme.utils.getColor('darkGrey', 850)} size={24} />
                <p>Drag & Drop your files here</p>
                <OrText>OR</OrText>
                <Button size={'sm'} type={'secondary'} onClick={open}>
                  Choose Files
                </Button>
                {item.content.guidelines && (
                  <Guidelines data-testid={'upload-guidelines'}>
                    {item.content.guidelines}
                  </Guidelines>
                )}
              </div>
            )}
          </DragDropContainer>
          {item.content.onDownloadTemplate && (
            <TemplateText data-testid={'upload-template'}>
              download template examples <span onClick={item.content.onDownloadTemplate}>here</span>
            </TemplateText>
          )}
        </ModalContainer>
      </Card>
    </UploadContainer>
  );
};

export default Upload;

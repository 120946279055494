import { Anything } from '../../../utils/globalTypes';

export const CREATE_ERROR = '@@CREATE_ERROR';

export interface ActionCreateError {
  type: typeof CREATE_ERROR;
  payload: Anything;
}

export type ErrorHandlingActionTypes = ActionCreateError;

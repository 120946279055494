import Cookies from 'universal-cookie';

const cookieOptions = {
  path: '/',
};

const cookies = new Cookies();

// Monkeypatch some instance methods
cookies.set = (name, value, overrides = {}) => {
  cookies.constructor.prototype.set.call(cookies, name, value, { ...cookieOptions, ...overrides });
};

cookies.remove = (name, overrides = {}) => {
  cookies.constructor.prototype.remove.call(cookies, name, { ...cookieOptions, ...overrides });
};

export default cookies;

import { Anything } from '../../utils/globalTypes';

export const FETCH_ASSETS_REQUEST = 'FETCH_ASSETS_REQUEST';
export const FETCH_ASSETS_FAILURE = 'FETCH_ASSETS_FAILURE';
export const SET_ASSETS = 'SET_ASSETS';
export const UPDATE_ASSETS = 'UPDATE_ASSETS';
export const RESET_ASSETS_PAGINATION = 'RESET_ASSETS_PAGINATION';
export const SET_SELECTED_ASSETS = 'SET_SELECTED_ASSETS';
export const UPDATE_SELECTED_ASSETS = 'UPDATE_SELECTED_ASSETS';
export const FETCH_ASSETS_COUNT_REQUEST = 'FETCH_ASSETS_COUNT_REQUEST';
export const SET_ASSETS_COUNT = 'SET_ASSETS_COUNT';
export const ASSETS_DOWNLOAD_ID = 'ASSETS_DOWNLOAD_ID';

export interface FetchAssetsRequestAction {
  type: typeof FETCH_ASSETS_REQUEST;
  payload: AssetsManagementParams;
}

export interface FetchAssetsRequestFailureAction {
  type: typeof FETCH_ASSETS_FAILURE;
}

export interface FetchAssetsCountRequestAction {
  type: typeof FETCH_ASSETS_COUNT_REQUEST;
  payload: AssetsManagementParams;
}

export interface SetAssetsCountAction {
  type: typeof SET_ASSETS_COUNT;
  payload: number;
}

export interface SetAssetsAction {
  type: typeof SET_ASSETS;
  payload: FetchAssetsResponseType;
}

export interface UpdateAssetsAction {
  type: typeof UPDATE_ASSETS;
  payload: UpdateAssetsType;
}

export interface ResetAssetsPagination {
  type: typeof RESET_ASSETS_PAGINATION;
}

export interface SetSelectedAssetsAction {
  type: typeof SET_SELECTED_ASSETS;
  payload: number[];
}

export interface UpdateSelectedAssetsAction {
  type: typeof UPDATE_SELECTED_ASSETS;
  payload: number[];
}

export type AssetsActionTypes =
  | FetchAssetsRequestAction
  | FetchAssetsRequestFailureAction
  | SetAssetsAction
  | UpdateAssetsAction
  | ResetAssetsPagination
  | SetAssetsCountAction
  | FetchAssetsCountRequestAction
  | UpdateSelectedAssetsAction;

export type FetchAssetsResponseType = {
  [key: string]: Anything;
  count: number;
  previous: string;
  next: string;
  results: AssetsType[];
};

export type AssetsType = {
  id: number;
  title: string;
  artist: string;
  daily_views: number;
  status: string;
  asset_id: string;
  reference_url: string;
  custom_id: string;
  linked_asset: Anything;
  release_date: string;
  date_updated: string;
  audiomatch_only: boolean;
  type: {
    value: string;
    text: string;
  };
};

export type UpdateAssetsType = {
  ids: number[];
  action: string;
};

export interface AssetsManagementReducer {
  list: AssetsType[];
  countItems: number;
  selected: number[];
}

export type AssetsManagementParams = {
  [key: string]: Anything;
  page: number;
  queue: string | undefined;
};

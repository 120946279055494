import {
  SetPaginationPageAction,
  SetPaginationTotalPagesAction,
  ResetPaginationAction,
  SET_PAGINATION_PAGE,
  SET_PAGINATION_TOTAL_PAGES,
  RESET_PAGINATION,
} from 'src/ducks/factories/pagination/types';
import { simpleAction } from 'src/ducks/utils';

const paginationActions = (prefix: string) => {
  const setPaginationPage = (payload: number): SetPaginationPageAction =>
    simpleAction(SET_PAGINATION_PAGE(prefix), payload);

  const setPaginationTotalPages = (payload: number): SetPaginationTotalPagesAction =>
    simpleAction(SET_PAGINATION_TOTAL_PAGES(prefix), payload);

  const resetPagination = (): ResetPaginationAction => simpleAction(RESET_PAGINATION(prefix));

  return {
    setPaginationTotalPages,
    setPaginationPage,
    resetPagination,
  };
};
export default paginationActions;

import * as Sentry from '@sentry/browser';
import { Middleware } from 'redux';
import { SET_USER } from 'src/ducks/user';

export const sentryMiddleware: Middleware = () => (next) => (action) => {
  switch (action.type) {
    case SET_USER: {
      const {
        payload: { id, email, username },
      } = action;

      Sentry.configureScope(function (scope) {
        scope.setUser({ email, username, id });
      });

      break;
    }
    default:
      break;
  }
  return next(action);
};

export default sentryMiddleware;

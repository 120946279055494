import styled from '@emotion/styled';
import { flex, flexCenter } from 'src/theme/functions';

export const TimestampButtonHandlerWrapper = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  marginBottom: 10,
});

export const TimestampButton = styled.button({
  marginLeft: 15,
  width: 20,
  justifyContent: 'center',
  display: 'flex',
});

export const TimestampAddButtonHandlerWrapper = styled.button`
  ${flex};
  ${flexCenter};
  color: #68b2c8;
  border-radius: 4px;
  border: dashed 2px #68b2c8;
  width: 100%;
  margin: 0 0 10px;
  padding: 5px;
  font-weight: bold;

  > div {
    margin: 2.5px 5px 0 0;
  }
`;

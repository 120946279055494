import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';
import { withPaginationSelectors } from 'src/ducks/factories/pagination';
import { withSearchSelectors } from 'src/ducks/factories/search';

export const __REDUX_STATE_KEY__ = 'leads';

export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getLeadsGenerations = (state: AppState) => getReduxStateSlice(state).list;
export const getLeadsGenerationsCount = (state: AppState) => getReduxStateSlice(state).countItems;
export const getLeadsGenerationsCountEstimate = (state: AppState) =>
  getReduxStateSlice(state).estimate;

export const leadsGenerationsLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

export const leadsGenerationsPaginationSelectors = withPaginationSelectors(__REDUX_STATE_KEY__);

export const leadsGenerationsSearchSelectors = withSearchSelectors(__REDUX_STATE_KEY__);

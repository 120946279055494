import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

import * as types from './types';

const initialState: types.Alerts = {
  byKeyId: {},
  keys: [],
};

function alerts(state = initialState, action: types.AlertAction): types.Alerts {
  switch (action.type) {
    case types.ADD_ALERT: {
      const { type, props } = action.payload;
      const uniqueId = type;

      return {
        byKeyId: {
          ...state.byKeyId,
          [uniqueId]: {
            type,
            props,
          },
        },
        keys: uniq([...state.keys, uniqueId]),
      };
    }
    case types.DELETE_ALERT:
      return {
        byKeyId: omit(state.byKeyId, action.payload.alertId || ''),
        keys: without(state.keys, action.payload.alertId || ''),
      };
    default:
      return state;
  }
}

export default alerts;

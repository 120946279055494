import React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';
import { ReactComponent as AddSVG } from 'src/assets/add.svg';
import { ReactComponent as TrashSVG } from 'src/assets/trash.svg';
import BaseTimestampsHandler from 'src/components/Forms/BaseTimestampsHandler';
import { Timestamp } from 'src/utils/constants';

import { Anything } from '../../../utils/globalTypes';
import {
  TimestampButton,
  TimestampButtonHandlerWrapper,
  TimestampAddButtonHandlerWrapper,
} from './BaseArrayTimestamp.style';

export type Props = {
  videoRef: Anything; // no need to have a type for this react ref
};

type ArrayComponentProps = WrappedFieldArrayProps<Timestamp>;

const BaseArrayTimestamp: React.FC<ArrayComponentProps & Props> = ({ videoRef, fields }) => {
  return (
    <>
      {fields.map((field, index) => {
        const isFirstItem = index === 0;

        return (
          <div key={index}>
            {isFirstItem && (
              <TimestampAddButtonHandlerWrapper
                type="button"
                onClick={() =>
                  fields.unshift({
                    matched_from: 0,
                    matched_to: 0,
                  })
                }
              >
                <div>
                  <AddSVG />
                </div>
                Add Timestamp
              </TimestampAddButtonHandlerWrapper>
            )}
            <TimestampButtonHandlerWrapper>
              <Field
                component={BaseTimestampsHandler}
                id={`${field}`}
                name={`${field}`}
                videoRef={videoRef}
              />
              <TimestampButton
                type="button"
                onClick={() => fields.length > 1 && fields.remove(index)}
              >
                <TrashSVG />
              </TimestampButton>
            </TimestampButtonHandlerWrapper>
          </div>
        );
      })}
    </>
  );
};

export default BaseArrayTimestamp;

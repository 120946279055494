import head from 'lodash/head';
import { startSubmit, stopSubmit } from 'redux-form';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { from, of, concat } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { resetFilter } from 'src/ducks/legacyFilters';
import { getUser, User } from 'src/ducks/user';
import { abilityBuilder, pickLandingPage } from 'src/ducks/user/policy';
import { userAPI } from 'src/providers';
import { setAxiosAccount, setAxiosToken } from 'src/providers/axiosInstances';
import urls from 'src/urls';
import { __ACCOUNT__, __TOKEN__ } from 'src/utils/constants';
import cookies from 'src/utils/cookies';
import { handleFormErrors } from 'src/utils/forms';
import history from 'src/utils/history';

import {
  setUserInfo,
  unsetUserInfo,
  setUserToken,
  setUserAbility,
  fetchUserRequest,
} from './actions';
import {
  FETCH_USER_REQUEST,
  LOGIN_USER_REQUEST,
  UPDATE_USER_SELECTED_ACCOUNT,
  UserActionTypes,
  LoginUserAction,
  UpdateUserSelectedAccount,
} from './types';

const loginUserEpic = (action$: ActionsObservable<LoginUserAction>) =>
  action$.pipe(
    ofType(LOGIN_USER_REQUEST),
    switchMap(({ payload }) =>
      concat(
        // Unset user info before login call to fix bug when user hasn't logged out before shutting down.
        of(unsetUserInfo()),
        of(startSubmit(payload.formName)),
        from(userAPI.single.login(payload).request()).pipe(
          mergeMap(({ apiToken }) => {
            cookies.set(__TOKEN__, apiToken);
            setAxiosToken(apiToken);
            history.replace(`${urls.globalReview()}?strategy=all`);
            return [setUserToken(apiToken), fetchUserRequest()];
          }),
          catchError(({ errors }) => {
            unsetUserInfo();
            return of(stopSubmit(payload.formName, handleFormErrors(errors)));
          })
        )
      )
    )
  );

const fetchUserEpic = (action$: ActionsObservable<UserActionTypes>) =>
  action$.pipe(
    ofType(FETCH_USER_REQUEST),
    switchMap(() =>
      from(userAPI.single.info().request()).pipe(
        mergeMap((data: User) => {
          const account = head(data.accounts);
          if (account) {
            cookies.set(__ACCOUNT__, account.id);
            setAxiosAccount(account.id);
          }

          const ability = abilityBuilder(data);
          history.replace(pickLandingPage(ability));

          return of(setUserInfo(data), setUserAbility(ability));
        }),
        catchError(() => {
          return of(unsetUserInfo());
        })
      )
    )
  );
const updateUserSelectedAccountEpic = (
  action$: ActionsObservable<UpdateUserSelectedAccount>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(UPDATE_USER_SELECTED_ACCOUNT),
    switchMap(() => {
      const state = state$.value;
      const user = getUser(state);
      const ability = abilityBuilder(user);

      return of(setUserAbility(ability), resetFilter());
    })
  );

export default {
  loginUserEpic,
  fetchUserEpic,
  updateUserSelectedAccountEpic,
};

import get from 'lodash/get';
import last from 'lodash/last';
import { startSubmit, stopSubmit } from 'redux-form';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { SET_PAGINATION_PAGE, SetPaginationPageAction } from 'src/ducks/factories/pagination/types';
import { __REDUX_STATE_KEY__ as __FILTER_REDUX_STATE_KEY__ } from 'src/ducks/legacyFilters';
import { handleReviewParams } from 'src/ducks/legacyFilters/utils';
import { __REDUX_STATE_KEY__ as __REVIEW_REDUX_STATE_KEY__ } from 'src/ducks/review';
import { ERROR_TYPE, pushAlert, SUCCESS_TYPE } from 'src/ducks/ui/alert';
import { removeOverlay } from 'src/ducks/ui/overlay';
import { reviewAPI } from 'src/providers';
import { ALERTS, PaginationLimit } from 'src/utils/constants';
import { handleFormErrors } from 'src/utils/forms';

import {
  fetchReviewsFail,
  setReviews,
  setReviewsCount,
  removeReview,
  fetchReviewsRequest,
  enableUndoLastButton,
  reviewLoadingActions,
  reviewPaginationActions,
} from './actions';
import {
  UpdateReviewsRequestAction,
  FetchReviewsRequestAction,
  FetchReviewsCountRequestAction,
  UPDATE_REVIEW_REQUEST,
  FETCH_REVIEWS_REQUEST,
  FETCH_REVIEWSCOUNT_REQUEST,
  UNDO_ACTION,
  UndoAction,
  REMOVE_REVIEW,
  RemoveReviewAction,
} from './types';

const updateReviewsEpic = (
  action$: ActionsObservable<UpdateReviewsRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(UPDATE_REVIEW_REQUEST),
    switchMap(({ payload: { formName = '', id, video_id, actions } }) =>
      concat(
        of(startSubmit(formName)),
        from(reviewAPI.single.update(id, { video_id, actions }).request()).pipe(
          mergeMap(() => {
            const item = state$.value.review.list.find((item) => item.id === id);
            return of(
              stopSubmit(formName),
              removeReview(id),
              fetchReviewsRequest({
                ...handleReviewParams(
                  state$.value[__FILTER_REDUX_STATE_KEY__],
                  state$.value[__REVIEW_REDUX_STATE_KEY__].page,
                  state$.value[__REVIEW_REDUX_STATE_KEY__].q
                ),
                withLoading: false,
              }),
              removeOverlay(formName),
              enableUndoLastButton(),
              pushAlert({
                type: ALERTS.REVIEW,
                props: {
                  type: SUCCESS_TYPE,
                  messages: [
                    `${item && item.asset.title} - successfully submitted as - ${get(
                      last(actions),
                      ['action']
                    )}`,
                  ],
                },
              })
            );
          }),
          catchError(({ errors }) =>
            of(
              formName
                ? stopSubmit(formName, handleFormErrors(errors, state$.value.form[formName].fields))
                : pushAlert({
                    type: ALERTS.REVIEW,
                    props: {
                      type: ERROR_TYPE,
                      messages: get(handleFormErrors(errors, {}), ['_error']) as string[],
                    },
                  })
            )
          )
        )
      )
    )
  );

const fetchReviewEpic = (
  action$: ActionsObservable<FetchReviewsRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_REVIEWS_REQUEST),
    switchMap(({ payload }) => {
      const { withLoading = true, ...rest } = payload;
      return concat(
        of(reviewLoadingActions.setLoading(withLoading)),
        from(
          reviewAPI.single.get({ ...rest, q: state$.value[__REVIEW_REDUX_STATE_KEY__].q }).request()
        ).pipe(
          mergeMap((data) => of(setReviews(data))),
          catchError(() => of(fetchReviewsFail()))
        ),
        of(reviewLoadingActions.setLoading(false))
      );
    })
  );

const fetchReviewsCountRequestEpic = (
  action$: ActionsObservable<FetchReviewsCountRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_REVIEWSCOUNT_REQUEST),
    switchMap(({ payload }) =>
      from(
        reviewAPI.single
          .getCount({ ...payload, q: state$.value[__REVIEW_REDUX_STATE_KEY__].q })
          .request()
      ).pipe(
        mergeMap((data) =>
          of(
            setReviewsCount(data.count),
            reviewPaginationActions.setPaginationTotalPages(Math.ceil(data.count / PaginationLimit))
          )
        ),
        //TODO: REVERT TO catchError(() => of(fetchReviewsFail())) WHEN API IS FIXED
        catchError(() => of(setReviewsCount(-1)))
      )
    )
  );

const changePageEpic = (
  action$: ActionsObservable<SetPaginationPageAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REVIEW_REDUX_STATE_KEY__)),
    switchMap((data) =>
      concat(
        of(
          fetchReviewsRequest(
            handleReviewParams(
              state$.value[__FILTER_REDUX_STATE_KEY__],
              data.payload,
              state$.value[__REVIEW_REDUX_STATE_KEY__].q
            )
          )
        )
      )
    )
  );

// SEND_UNDO_REVIEW
const undoReviewEpic = (
  action$: ActionsObservable<UndoAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(UNDO_ACTION),
    switchMap(({ payload }) =>
      from(reviewAPI.single.undoReviewAction(payload).request()).pipe(
        mergeMap(() =>
          of(
            pushAlert({
              type: ALERTS.REVIEW,
              props: {
                type: SUCCESS_TYPE,
                messages: [
                  `You successfully undo your last action. The video is now in the "No Queue"`,
                ],
              },
            }),
            fetchReviewsRequest({
              ...handleReviewParams(
                state$.value[__FILTER_REDUX_STATE_KEY__],
                state$.value[__REVIEW_REDUX_STATE_KEY__].page,
                state$.value[__REVIEW_REDUX_STATE_KEY__].q
              ),
              withLoading: false,
            })
          )
        ),
        catchError(() => {
          return of(fetchReviewsFail());
        })
      )
    )
  );

const removeReviewEpic = (action$: ActionsObservable<RemoveReviewAction>) =>
  action$.pipe(
    ofType(REMOVE_REVIEW),
    mergeMap(() => {
      return of();
    })
  );

export default {
  fetchReviewEpic,
  fetchReviewsCountRequestEpic,
  updateReviewsEpic,
  changePageEpic,
  undoReviewEpic,
  removeReviewEpic,
};

import { GlobalReview, GlobalReviewCountResponse } from 'src/ducks/globalReview';

import { METHODS, requestV3 } from '../axiosInstances';

export default {
  get: (params = {}) => requestV3<GlobalReview[]>(METHODS.GET, `/review/`, { params }),
  getCount: (params = {}) =>
    requestV3<GlobalReviewCountResponse>(METHODS.GET, `/review/count/`, { params }),
  action: <T>(params = {}) => requestV3<T>(METHODS.PUT, `/review/action/`, { params }),
};

import mapValues from 'lodash/mapValues';
import values from 'lodash/values';

import { FilterOption, FiltersState } from '../types';
import { ResponseType } from './types';

const isSelected = <T extends { default: boolean }>(item: T) => ({
  ...item,
  selected: Boolean(item.default),
});

const buildOptions = <T extends { default: boolean }>(options: T[]) => options.map(isSelected);

const buildValues = <T>(options: { [key: string]: T }) =>
  values(mapValues(options, (value, key) => ({ ...value, value: key })));

export const normalizationData = (response: ResponseType, strategy?: string): FiltersState => {
  const filters = buildValues(response.filters).map((filter) => {
    return {
      ...filter,
      options: buildOptions<FilterOption>(filter.options),
    };
  });
  const sort = buildValues(response.sort || {}).map(isSelected);
  const actions = buildValues(response.actions || {});
  const strategies = response.strategies
    ? response.strategies.map((responseStrategy) => ({
        ...responseStrategy,
        selected: Boolean(
          strategy ? strategy === responseStrategy.value : responseStrategy.default
        ),
      }))
    : [
        {
          label: strategy || '',
          value: strategy || '',
          selected: true,
          default: true,
          sort: sort.map(({ value }) => value),
          filters: {
            main: filters.map(({ value }) => value),
            more: [],
          },
          actions: [],
        },
      ];

  return { filters, strategy, strategies, sort, actions, added: [] };
};

export type Timestamp = {
  matched_from: number;
  matched_to: number;
};

/** this is a prefix name for dates that are coming from the server in order to change it to datepicker */
export const START_DATE_PREFIX = 'start_date';
export const SPAN_DATE_PREFIX = 'span';

export enum VideoStatus {
  Confirmed = 'confirmed',
  Failure = 'failure',
}

export enum REVIEW_PAGES {
  GLOBAL_REVIEW = 'all',
  ASSET = 'asset',
  ANALYTICS_VIDEOS = 'suggested',
  // although seems as unused needed for checks in the code
  SUBSCRIBED_CHANNEL = 'channel',
  INACTIVE_CLAIMS = 'inactive',
  KEYWORDS = 'keywords',
  POTENTIAL_CLAIMS = 'potential',
  PENDING_CLAIMS = 'pending',
  RELATED_VIDEOS = 'related',
}

export enum LEADS_PAGES {
  TOP_CHANNELS = 'top-channels',
  TOP_VIDEOS = 'top-videos',
  TOP_KEYWORDS = 'top-keywords',
  TOP_ASSETS = 'top-assets',
}

export enum ANALYTICS_PAGES {
  CLAIM_ANALYTICS = 'claim-analytics',
}

export enum OTHER_PAGES {
  CONFIRMED_MATCHES = 'confirmed',
  ASSETS_MANAGEMENT = 'assets',
  LEGACY_CLAIMS = 'legacy-claims',
  CHANNELS_MANAGEMENT = 'channels',
  KEYWORD_MANAGEMENT = 'keyword-management',
  CLAIM_LIST = 'claims',
}

export const PAGES = {
  ...REVIEW_PAGES,
  ...OTHER_PAGES,
  ...LEADS_PAGES,
  ...ANALYTICS_PAGES,
};

export type PagesType = REVIEW_PAGES | OTHER_PAGES | LEADS_PAGES | ANALYTICS_PAGES;

export enum TagStatus {
  QUERY = 'query',
  PROBABILITY = 'probability',
  CHANNEL = 'channel',
}

export enum ButtonStatus {
  Success = 'success',
  Info = 'info',
  Neutral = 'neutral',
  Danger = 'danger',
  Alert = 'alert',
  Default = 'default',
  Dark = 'dark',
}

export enum SnackbarStatus {
  Info = 'info',
  Warning = 'warning',
  Default = 'default',
}

export enum MODALS {
  PREVENT_UNMOUNT = 'pu',
}

export enum TABLE_VIEWS {
  ASSET_LIST_TABLE_VIEW = 'ASSET_LIST_TABLE_VIEW',
  REVIEW_HISTORY_LIST_TABLE_VIEW = 'REVIEW_HISTORY_LIST_TABLE_VIEW',
  CONFIRMED_MATCHES_LIST_TABLE_VIEW = 'CONFIRMED_MATCHES_LIST_TABLE_VIEW',
  CHANNELS_MANAGEMENT_LIST_TABLE_VIEW = 'CHANNELS_MANAGEMENT_LIST_TABLE_VIEW',
  LEGACY_CLAIMS_LIST_TABLE_VIEW = 'LEGACY_CLAIMS_LIST_TABLE_VIEW',
}

// date constants (for display purposes only)
export const DATE_FORMAT = 'DD MMM YYYY';

export enum ALERTS {
  GLOBAL = 'global',
  REVIEW = 'ra',
  SUBSCRIBED_CHANNELS = 'subch',
  RELATED_VIDEOS = 'relv',
  PENDING_CLAIMS = 'penc',
  POTENTIAL_CLAIMS = 'pc',
  INACTIVE_CLAIMS = 'ic',
  REVIEW_HISTORY = 'rha',
  CONFIRMED_MATCHES = 'cm',
  ANALYTICS_VIDEOS = 'av',
  LEGACY_CLAIMS = 'lc',
}

export enum FORMS {
  REVIEW_QUEUE_EDIT_ASSET = 'rqea',
  REVIEW_HISTORY_EDIT_ASSET = 'rhea',
  LOGIN = 'login',
}

// name of the cookies where the token is stored in
export const __TOKEN__ = 'token-vh2';

export const globalDateFormatting = 'YYYY/MM/DD';

// name of the cookies where the account is stored in
export const __ACCOUNT__ = 'account';

// this is the default reject action from the server that we are using it as default action on selections
export const defaultRejectOption = { type: 'failure', value: 'reject', label: 'Reject' };

// Pagination Limit for review
export const PaginationLimit = 5;
// Pagination Limit for channels
export const ChannelsPaginationLimit = 25;
//Pagination Limit for GlobalReview
export const GlobalReviewPaginationLimit = 25;
//Pagination Limit for Leads Generation
export const LeadsGenerationPaginationLimit = 10;

// these are the seconds that the timestamps has to have as a span between start and end
// we use this as a validation and to keep them separate when changing timestamps or saving a review item
export const secondEndHasToSkip = 3;

// this is the url for the youtube embed videos to load
export const youtubeEmbedURL = 'https://www.youtube.com/embed/';

// Predefined size options for Youtube video in our listing // modified for Jim's design.
export const videoSizes = new Map([
  [
    'small',
    {
      width: 338,
      height: 249,
    },
  ],
  [
    'smallXl',
    {
      width: 345,
      height: 249,
    },
  ],
  [
    'medium',
    {
      width: 640,
      height: 360,
    },
  ],
  [
    'large',
    {
      width: 853,
      height: 480,
    },
  ],
  [
    'largest',
    {
      width: 1280,
      height: 720,
    },
  ],
]);

export const OPERATIONS = { READ: 'READ', CREATE: 'CREATE', DELETE: 'DELETE', UPDATE: 'UPDATE' };

export const apiPagesMap = (page: PagesType) => {
  if (Object.values<string>(REVIEW_PAGES).includes(page)) {
    return 'review';
  }
  if (Object.values<string>(LEADS_PAGES).includes(page)) {
    return 'leads';
  }
  if (Object.values<string>(ANALYTICS_PAGES).includes(page)) {
    return 'analytics';
  }
  if (OTHER_PAGES.KEYWORD_MANAGEMENT === page) {
    return 'keywords';
  }
  if (OTHER_PAGES.LEGACY_CLAIMS === page) {
    return 'management/legacy';
  }

  return page;
};

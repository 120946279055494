import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { withSearchActions } from 'src/ducks/factories/search';
import { simpleAction } from 'src/ducks/utils';
import { SummaryResponse } from 'src/providers/claimAPI/types';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_CLAIMS_COUNT_FAILURE,
  FETCH_CLAIMS_COUNT_REQUEST,
  FETCH_CLAIMS_FAILURE,
  FETCH_CLAIMS_REQUEST,
  FetchClaimsCountFailureAction,
  FetchClaimsCountRequestAction,
  FetchClaimsFailureAction,
  FetchClaimsRequestAction,
  GetClaimsExportsAction,
  FetchClaimsSummaryAction,
  SetClaimsSummaryAction,
  SET_CLAIMS_SUMMARY,
  GET_CLAIMS_EXPORTS,
  FETCH_CLAIMS_SUMMARY,
  Claim,
  ClaimCountResponse,
  ClaimParams,
  SET_CLAIMS,
  SET_CLAIMS_COUNT,
  SetClaimsAction,
  SetClaimsCountAction,
} from './types';

export const fetchClaimsRequest = (params: ClaimParams): FetchClaimsRequestAction =>
  simpleAction(FETCH_CLAIMS_REQUEST, params);

export const fetchClaimsFail = (): FetchClaimsFailureAction => simpleAction(FETCH_CLAIMS_FAILURE);

export const setClaims = (reviews: Claim[]): SetClaimsAction => simpleAction(SET_CLAIMS, reviews);

export const fetchClaimsCountRequest = (params: ClaimParams): FetchClaimsCountRequestAction =>
  simpleAction(FETCH_CLAIMS_COUNT_REQUEST, params);

export const fetchClaimsCountFail = (): FetchClaimsCountFailureAction =>
  simpleAction(FETCH_CLAIMS_COUNT_FAILURE);

export const getClaimsExports = (): GetClaimsExportsAction => simpleAction(GET_CLAIMS_EXPORTS);

export const fetchClaimsSummary = (): FetchClaimsSummaryAction =>
  simpleAction(FETCH_CLAIMS_SUMMARY);

export const setClaimsSummary = (summary: SummaryResponse): SetClaimsSummaryAction =>
  simpleAction(SET_CLAIMS_SUMMARY, summary);

export const setClaimsCount = (payload: ClaimCountResponse): SetClaimsCountAction =>
  simpleAction(SET_CLAIMS_COUNT, payload);

export const claimsLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const claimsPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

export const claimsSearchActions = withSearchActions(__REDUX_STATE_KEY__);

import { Toast } from '@orfium/ictinus';
import React, { useEffect } from 'react';

import { useFileTransfer } from '../../FileTransferContext';
import { ProcessingItem } from '../../types';
import ProgressItem from './components/ProgressItem';
import { Content, TimeInfo } from './ProgressToast.style';

export type Props = ProcessingItem;

const ProgressToast: React.FC<Props> = ({ title, info, files, customContent, onClose, id }) => {
  const { removeItems } = useFileTransfer();

  useEffect(() => {
    if (files.length === 0) {
      removeItems([id]);
    }
  }, [files.length, id, removeItems]);

  return (
    <Toast type="secondary" message={title} closeCTA={onClose} expanded>
      <Content>
        <TimeInfo>{info}</TimeInfo>
        {files.map((file, index) => (
          <ProgressItem key={index + file.name} id={id} file={file} />
        ))}
        {customContent}
      </Content>
    </Toast>
  );
};

export default ProgressToast;

import { Middleware } from 'redux';
import { ADD_ALERT, ERROR_TYPE, removeAlert } from 'src/ducks/ui/alert';
import { ALERTS } from 'src/utils/constants';

export const alertsMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case ADD_ALERT: {
        const { type, props } = action.payload;

        if (type === ALERTS.GLOBAL && props.type === ERROR_TYPE) break;

        setTimeout(() => dispatch(removeAlert(type)), 5000);
        break;
      }
      default:
        break;
    }
    return next(action);
  };

export default alertsMiddleware;

import React from 'react';

import { Anything } from '../../utils/globalTypes';
import { Wrapper } from './Container.style';

type Props = {
  centered?: boolean;
  children?: Anything;
};

const Container = ({ centered = false, children }: Props) => (
  <Wrapper centered={centered}>{children}</Wrapper>
);

export default Container;

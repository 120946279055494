import { TextField } from '@orfium/ictinus';
import React from 'react';
import { WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import { FormError } from 'src/components/Forms';

type Props = { type: string; label: string; id: string } & WrappedFieldProps &
  WrappedFieldMetaProps;

const BaseTextField: React.FC<Props> = ({ input, meta: { error }, type, label, id }) => (
  <React.Fragment>
    <TextField {...input} id={id} type={type} label={label} error={Boolean(error)} />
    {error && <FormError>{error}</FormError>}
  </React.Fragment>
);

export default BaseTextField;

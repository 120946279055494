import React from 'react';
import { FilterParams } from 'src/ducks/filters';

export const FETCH_GLOBAL_REVIEWS_REQUEST = 'FETCH_GLOBAL_REVIEWS_REQUEST';
export const FETCH_GLOBAL_REVIEWS_FAILURE = 'FETCH_GLOBAL_REVIEWS_FAILURE';
export const SET_GLOBAL_REVIEWS = 'SET_GLOBAL_REVIEWS';
export const FETCH_GLOBAL_REVIEWS_COUNT_REQUEST = 'FETCH_GLOBAL_REVIEWS_COUNT_REQUEST';
export const FETCH_GLOBAL_REVIEWS_COUNT_FAILURE = 'FETCH_GLOBAL_REVIEWS_COUNT_FAILURE';
export const SET_GLOBAL_REVIEWS_COUNT = 'SET_GLOBAL_REVIEWS_COUNT';
export const TRIGGER_GLOBAL_REVIEWS_ACTION = 'TRIGGER_GLOBAL_REVIEWS_ACTION';
export const REMOVE_GLOBAL_REVIEWS = 'REMOVE_GLOBAL_REVIEWS';
export const REFETCH_GLOBAL_REVIEWS = 'REFETCH_GLOBAL_REVIEWS';

export type FetchGlobalReviewsRequestAction = {
  type: typeof FETCH_GLOBAL_REVIEWS_REQUEST;
  payload: GlobalReviewParams;
};

export type FetchGlobalReviewsFailureAction = {
  type: typeof FETCH_GLOBAL_REVIEWS_FAILURE;
};

export type SetGlobalReviewsAction = {
  type: typeof SET_GLOBAL_REVIEWS;
  payload: GlobalReview[];
};

export type RemoveGlobalReviewsAction = {
  type: typeof REMOVE_GLOBAL_REVIEWS;
  payload: (string | number)[];
};

export type FetchGlobalReviewsCountRequestAction = {
  type: typeof FETCH_GLOBAL_REVIEWS_COUNT_REQUEST;
  payload: GlobalReviewParams;
};

export type FetchGlobalReviewsCountFailureAction = {
  type: typeof FETCH_GLOBAL_REVIEWS_COUNT_FAILURE;
};

export type SetGlobalReviewsCountAction = {
  type: typeof SET_GLOBAL_REVIEWS_COUNT;
  payload: SetGlobalReviewCount;
};

export type TriggerGlobalReviewsAction = {
  type: typeof TRIGGER_GLOBAL_REVIEWS_ACTION;
  payload: GlobalReviewsTriggerActionParams;
};

export type RefetchGlobalReviewsAction = {
  type: typeof REFETCH_GLOBAL_REVIEWS;
};

export type GlobalReviewParams = FilterParams;
export type GlobalReviewsActions = 'reject' | 'hide';
export type GlobalReviewsTriggerActionParams = {
  action: GlobalReviewsActions;
  video_ids: React.ReactText[];
};

export type GlobalReviewCountResponse = {
  count: number;
  estimate: boolean;
};

export type SetGlobalReviewCount = GlobalReviewCountResponse & {
  isLoading: boolean;
  isError?: boolean;
};

export type GlobalReview = {
  id: number;
  video_id: string;
  content_type: string | null;
  channel_id: string;
  keyword: string | null;
  strategy: string;
  user_id: number;
  queue_id: number | null;
  video: Video;
  channel: Channel;
  matches: Match[];
};

export type Video = {
  service: string;
  external_id: string;
  thumbnail: string | null;
  title: string | null;
  encoded_title: string | null;
  description: string | null;
  tags: string[];
  total_views: number | null;
  views_per_hour: number | null;
  daily_views: number | null;
  uploaded: string | null;
  duration: number | null;
  category: string | null;
  topics: string[];
  text_language: string | null;
  audio_language: string | null;
  live_broadcast: boolean | null;
  likes: number | null;
  dislikes: number | null;
  comments: number | null;
  traffic: VideoTraffic;
};

export type VideoTraffic = {
  views: number | null;
  watch_time: number | null;
  source_type: string | null;
  destination_video_id: string | null;
  accumulative_watch_time: number | null;
  metrics_updated: string | null;
};

export type Channel = {
  service: string;
  external_id: string;
  thumbnail: string | null;
  title: string | null;
  description: string | null;
  published: string | null;
  country: string | null;
  language: string | null;
  views: number | null;
  videos: number | null;
  views_per_video: number | null;
  comments: number | null;
  subscribers: number | null;
  subscribers_hidden: boolean | null;
};

export type Match = {
  type: MatchTypes;
  reason: string | null;
  score: string | null;
  asset?: MatchAsset;
  segments?: Segments[];
};

export type MatchTypes =
  | 'MetadataMatch'
  | 'AudioMatch'
  | 'YouTubeInactiveMatch'
  | 'YouTubeAudioMatch'
  | 'MusicNoMusic';

export type MatchAsset = {
  title: string | null;
  artist: string | null;
  asset_id: string;
  reference_url: string | null;
  segments: Segments[];
  account_id: number;
};

export type Segments = {
  from: number;
  to: number;
  score: string;
};
export type GlobalReviewActionTypes =
  | SetGlobalReviewsAction
  | SetGlobalReviewsCountAction
  | RemoveGlobalReviewsAction
  | FetchGlobalReviewsFailureAction
  | FetchGlobalReviewsCountFailureAction;

export type GlobalReviewReducer = {
  list: GlobalReview[];
  countItems: number;
  estimate: boolean;
  countIsLoading: boolean;
  countIsError: boolean;
  isError?: boolean;
};

import React from 'react';
import { FilterParams } from 'src/ducks/filters';

import { LEADS_PAGES } from '../../utils/constants';

export const FETCH_LEADS_GENERATION_REQUEST = 'FETCH_LEADS_GENERATION_REQUEST';
export const FETCH_LEADS_GENERATION_FAILURE = 'FETCH_LEADS_GENERATION_FAILURE';
export const SET_LEADS_GENERATION = 'SET_LEADS_GENERATION';
export const FETCH_LEADS_GENERATION_COUNT_REQUEST = 'FETCH_LEADS_GENERATION_COUNT_REQUEST';
export const FETCH_LEADS_GENERATION_COUNT_FAILURE = 'FETCH_LEADS_GENERATION_COUNT_FAILURE';
export const SET_LEADS_GENERATION_COUNT = 'SET_LEADS_GENERATION_COUNT';
export const TRIGGER_LEADS_GENERATION_ACTION = 'TRIGGER_LEADS_GENERATION_ACTION';
export const REMOVE_LEADS_GENERATION = 'REMOVE_LEADS_GENERATION';

export type FetchLeadsGenerationsRequestAction = {
  type: typeof FETCH_LEADS_GENERATION_REQUEST;
  payload: LeadsGenerationParams;
};

export type FetchLeadsGenerationsFailureAction = {
  type: typeof FETCH_LEADS_GENERATION_FAILURE;
};

export type SetLeadsGenerationsAction = {
  type: typeof SET_LEADS_GENERATION;
  payload: {
    list: LeadsGeneration[] | LeadsKeyword[] | LeadsChannel[];
    page:
      | LEADS_PAGES.TOP_VIDEOS
      | LEADS_PAGES.TOP_KEYWORDS
      | LEADS_PAGES.TOP_ASSETS
      | LEADS_PAGES.TOP_CHANNELS;
  };
};

export type RemoveLeadsGenerationsAction = {
  type: typeof REMOVE_LEADS_GENERATION;
  payload: (string | number)[];
};

export type FetchLeadsGenerationsCountRequestAction = {
  type: typeof FETCH_LEADS_GENERATION_COUNT_REQUEST;
  payload: LeadsGenerationParams;
};

export type FetchLeadsGenerationsCountFailureAction = {
  type: typeof FETCH_LEADS_GENERATION_COUNT_FAILURE;
};

export type SetLeadsGenerationsCountAction = {
  type: typeof SET_LEADS_GENERATION_COUNT;
  payload: LeadsGenerationCountResponse;
};

export type TriggerLeadsGenerationsAction = {
  type: typeof TRIGGER_LEADS_GENERATION_ACTION;
  payload: LeadsGenerationsTriggerActionParams;
};

export type LeadsGenerationParams = FilterParams;
export type LeadsGenerationsActions = 'reject' | 'hide';
export type LeadsGenerationsTriggerActionParams = {
  action: LeadsGenerationsActions;
  video_ids: React.ReactText[];
};

export type LeadsGenerationCountResponse = {
  count: number;
  estimate: boolean;
};

export type Asset = {
  asset_id: string;
  share_ids_raw: string;
  asset_type: string;
  title: string;
  metadata: {
    isrc: string;
    artist: string[];
  };
  vh_asset_active: boolean;
};

export type Owner = {
  content_owner_id: string;
  content_owner_name: string;
  content_owner_email: string;
};

export type Video = {
  service: string;
  external_id: string;
  uploaded: string;
  title: string;
  encoded_title: string;
  description: string;
  total_views: number;
  daily_views: number;
  channel: {
    id: string;
    title: string;
  };
};

export type LeadsGeneration = {
  asset: Asset;
  channel: {
    vh_channel: boolean;
  } | null;
  owner: Owner;
  video: Video;
  status: string;
  claim_id: string;
  claimed_at: Date;
  uploaded: Date;
  by_third_partner: boolean;
  is_partner_uploaded: boolean;
  origin: string;
  email: string;
  by_orfium: boolean;
};

export type LeadsKeyword = {
  offset: number;
  keyword: string;
  frequency: number;
  total_views: string;
  orfium_views: string;
  percentage: string;
  title: string;
  encoded_title: string;
};

export type LeadsAsset = {
  offset: number;
  asset_title: string;
  total_claims: string;
  total_views: string;
  total_orfium_views: string;
  total_orfium_views_percentage: string;
  vh_asset_id: (null | string)[];
  vh_asset_is_active: boolean | null;
  asset_id: string;
};

export type LeadsChannel = {
  offset: number;
  channel_title: string;
  total_orfium_claims: number;
  total_orfium_claims_percentage: string;
  total_claims: string;
  total_views: string;
  total_orfium_views: string;
  total_orfium_views_percentage: string;
  channel_id: string;
  channel_whitelisted: boolean;
  vh_channel: boolean;
  favorite: boolean;
  enabled: boolean;
  thumbnail: string;
  subscribers: number | null;
  views: number;
  videos: number;
  views_per_video: number;
  videos_in_review: number;
};

export type LeadsGenerationActionTypes =
  | SetLeadsGenerationsAction
  | SetLeadsGenerationsCountAction
  | RemoveLeadsGenerationsAction;

export type LeadsGenerationListType = {
  [LEADS_PAGES.TOP_VIDEOS]: LeadsGeneration[];
  [LEADS_PAGES.TOP_KEYWORDS]: LeadsKeyword[];
  [LEADS_PAGES.TOP_ASSETS]: LeadsAsset[];
  [LEADS_PAGES.TOP_CHANNELS]: LeadsChannel[];
};

export type LeadsGenerationReducer = {
  list: LeadsGenerationListType;
  countItems: number;
  estimate: boolean;
};

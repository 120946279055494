import get from 'lodash/get';
import { DynamicStrategies, FiltersState, NormalizedSort } from 'src/ducks/legacyFilters/types';
import { Anything } from 'src/utils/globalTypes';

const getSortBySelected = (strategy: string | undefined, filters: DynamicStrategies[]) => {
  return get(
    filters
      .find((o: { strategy: string }) => o.strategy === strategy)
      ?.sort[0]?.options.find((item) => item.selected),
    'value'
  ) as Anything;
};

// This function is used when the API needs the filters in a format like [strategy].[filterName]
const getStrategyMappedFilters = (strategy: string | undefined, filters: DynamicStrategies[]) => {
  const foundStrategy = filters.find((o: { strategy: string }) => o.strategy === strategy);
  if (!strategy || !foundStrategy) {
    return [];
  }

  return foundStrategy.filters
    .map((item) => item)
    .reduce((accumulator, opt: NormalizedSort) => {
      const key = strategy + '.' + opt.value;
      return Object.assign(accumulator, {
        [key]: opt.options.find((o) => o.selected)?.value,
      });
    }, {});
};

// This function is used when the API needs the filters in a format like [filterName]
const getPropertyMappedFilters = (strategy: string | undefined, filters: DynamicStrategies[]) => {
  const foundStrategy = filters.find((o: { strategy: string }) => o.strategy === strategy);
  if (!strategy || !foundStrategy) {
    return [];
  }

  return foundStrategy.filters
    .map((item) => item)
    .reduce((accumulator, opt: NormalizedSort) => {
      const key = opt.value;
      return Object.assign(accumulator, {
        [key]: opt.options.find((o) => o.selected)?.value,
      });
    }, {});
};

export const handleReviewParams = ({ filters, queues = [] }: FiltersState, page = 1, q = '') => {
  const getSelectedStrategy: string | undefined = get(
    filters.find((item) => item.selected),
    'strategy'
  );

  const selectedFilters = getStrategyMappedFilters(getSelectedStrategy, filters);

  return {
    q: q,
    page: page,
    strategy: getSelectedStrategy,
    queue: get(
      queues.find((item: { selected: boolean }) => item.selected),
      'value'
    ),
    sort_by: getSortBySelected(getSelectedStrategy, filters),
    ...(selectedFilters as unknown as Record<string | number, unknown> as Anything),
  };
};

export const combineNormData = (
  persistedState: FiltersState,
  normData: FiltersState,
  strategy: string
) => {
  //When going from confirmed to review pages or vice versa we don't have all the pages that use filters.
  //so we have to keep track of what is the selected page at this point. If not we will end up with the last
  //review page as selected and the confirmed matches page as selected.
  persistedState.filters = persistedState.filters.map((item) => ({
    ...item,
    selected: item.strategy === strategy,
  }));

  //compare queues and if both have the same values keep the persisted else keep the new one from the response.
  const persistedQueue = persistedState.queues
    .map((q) => q.value)
    .sort()
    .toString();
  const normDataQueue = normData.queues
    .map((q) => q.value)
    .sort()
    .toString();

  if (persistedQueue !== normDataQueue) {
    persistedState.queues = [];
  }

  const combined: FiltersState = {
    ...normData,
    ...persistedState,
    actions: normData.actions,
  };
  // The Api always returns a subset from the total filters and others are kept locally.
  if (!combined.filters.find((item) => item.strategy === strategy)) {
    combined.filters.push(...normData.filters);
  }
  return combined;
};

export const handleStrategiesParams = (
  { filters, queues = [] }: FiltersState,
  page = 1,
  q = ''
) => {
  const getSelectedStrategy: string | undefined = get(
    filters.find((item) => item.selected),
    'strategy'
  );

  const selectedFilters = getPropertyMappedFilters(getSelectedStrategy, filters);
  const selectedSort = getSortBySelected(getSelectedStrategy, filters);

  return {
    q: q,
    page: page,
    queue: get(
      queues.find((item: { selected: boolean }) => item.selected),
      'value'
    ),
    sort_by: selectedSort,
    ...selectedFilters,
  };
};

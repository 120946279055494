import { createSelector } from 'reselect';
import { AppState } from 'src/ducks';

import { Anything } from '../../../utils/globalTypes';
import { Alerts, Alert } from './types';

export const __REDUX_STATE_KEY__ = 'alerts';
export const getReduxStateSlice = (state: AppState) => state.ui[__REDUX_STATE_KEY__];

// retrieves an ordered list of all the overlays
export const getAlerts = createSelector(getReduxStateSlice, (overlayState: Alerts) =>
  overlayState.keys.map((id: string): Alert => {
    const overlay: Alert = overlayState.byKeyId[id];
    return {
      id,
      type: overlay.type,
      props: overlay.props,
    };
  })
);

const alertById = (state: AppState, alertId: string): Anything =>
  getReduxStateSlice(state).byKeyId[alertId];

// retrieves a specific alert by id
export const getAlertById = createSelector(alertById, (alert: Alert) => alert);

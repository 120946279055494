import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';
import { withSearchReducer } from 'src/ducks/factories/search';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  AssetsActionTypes,
  AssetsManagementReducer,
  FETCH_ASSETS_FAILURE,
  SET_ASSETS,
  SET_ASSETS_COUNT,
  UPDATE_SELECTED_ASSETS,
} from './types';

const initialState: AssetsManagementReducer = {
  list: [],
  countItems: 0,
  selected: [],
};

function assetStrategy(state = initialState, action: AssetsActionTypes): AssetsManagementReducer {
  switch (action.type) {
    case SET_ASSETS:
      return {
        ...state,
        list: action.payload.results,
      };
    case SET_ASSETS_COUNT:
      return {
        ...state,
        countItems: action.payload,
      };
    case FETCH_ASSETS_FAILURE:
      return {
        ...state,
        list: [],
      };
    case UPDATE_SELECTED_ASSETS:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
}

export default compose(
  withSearchReducer<AssetsManagementReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withPaginationReducer<AssetsManagementReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withLoadingReducer<AssetsManagementReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(assetStrategy);

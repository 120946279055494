import { REHYDRATE, PersistorAction } from 'redux-persist';

import { Anything } from '../../../utils/globalTypes';
import { Search, SearchActionTypes, SET_SEARCH, RESET_SEARCH } from './types';

const initialState: Search = {
  q: '',
};

const pagination = <T>({ prefix, baseReducerState }: { prefix: string; baseReducerState: T }) => {
  return (baseReducer: Anything) =>
    (state = initialState, action: SearchActionTypes & PersistorAction): Anything => {
      // we are creating a new state based on the state passed our initial state and the reducer state
      // this is because at first the state is empty and if we combine multiple reducers the state must combined
      // from all the states passed
      const newState = { ...initialState, ...baseReducerState, ...state };

      switch (action.type) {
        case SET_SEARCH(prefix): {
          return {
            ...newState,
            q: action.payload,
          };
        }
        case RESET_SEARCH(prefix): {
          const newStateWithInitialStateAtTop = { ...baseReducerState, ...state, ...initialState };
          return baseReducer(newStateWithInitialStateAtTop, action);
        }
        case REHYDRATE: {
          // we use this case of rehydration for persistance because the new state that we are building will not hold the data
          // that redux-persist pass on action.payload. That's why on this type we check if there is a payload and if there is then we pass
          // to the state the piece of the state from the rehydrated. This will only be needed for factories
          return Object.assign({}, newState, action.payload ? action.payload[prefix] : {});
        }
        default:
          return baseReducer(state, action);
      }
    };
};

export default pagination;

import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

import * as types from './types';

const initialState: types.Overlays = {
  byKeyId: {},
  keys: [],
};

function overlays(state = initialState, action: types.OverlayAction): types.Overlays {
  switch (action.type) {
    case types.ADD_OVERLAY: {
      const { type, props } = action.payload;
      const uniqueId = type;

      return {
        byKeyId: {
          ...state.byKeyId,
          [uniqueId]: {
            type,
            props,
          },
        },
        keys: uniq([...state.keys, uniqueId]),
      };
    }
    case types.DELETE_OVERLAY:
      return {
        byKeyId: omit(state.byKeyId, action.payload.overlayId || ''),
        keys: without(state.keys, action.payload.overlayId || ''),
      };
    default:
      return state;
  }
}

export default overlays;

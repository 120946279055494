import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { withSearchActions } from 'src/ducks/factories/search';

import { simpleAction } from '../utils';
import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_CHANNELS_FAILURE,
  FETCH_CHANNELS_REQUEST,
  SET_CHANNELS,
  UPDATE_CHANNELS,
  SET_SELECTED_CHANNELS,
  UPDATE_SELECTED_CHANNELS,
  FETCH_CHANNELS_COUNT_REQUEST,
  SET_CHANNELS_COUNT,
  FetchChannelsRequestAction,
  FetchChannelsRequestFailureAction,
  FetchChannelsResponseType,
  SetChannelsAction,
  SetSelectedChannelsAction,
  UpdateSelectedChannelsAction,
  UpdateChannelsAction,
  UpdateChannelsType,
  FetchChannelsCountRequestAction,
  ChannelsManagementParams,
  SetChannelsCountAction,
} from './types';

export const fetchChannelsRequest = (
  params: ChannelsManagementParams
): FetchChannelsRequestAction => simpleAction(FETCH_CHANNELS_REQUEST, params);

export const setChannels = (payload: FetchChannelsResponseType): SetChannelsAction =>
  simpleAction(SET_CHANNELS, payload);

export const fetchChannelsFailure = (): FetchChannelsRequestFailureAction =>
  simpleAction(FETCH_CHANNELS_FAILURE);

export const fetchChannelsCountRequest = (
  params: ChannelsManagementParams
): FetchChannelsCountRequestAction => simpleAction(FETCH_CHANNELS_COUNT_REQUEST, params);

export const setChannelsCount = (data: number): SetChannelsCountAction =>
  simpleAction(SET_CHANNELS_COUNT, data);

export const updateChannels = (data: UpdateChannelsType): UpdateChannelsAction =>
  simpleAction(UPDATE_CHANNELS, data);

export const setSelectedChannels = (payload: number[]): SetSelectedChannelsAction =>
  simpleAction(SET_SELECTED_CHANNELS, payload);

export const updateSelectedChannels = (payload: number[]): UpdateSelectedChannelsAction =>
  simpleAction(UPDATE_SELECTED_CHANNELS, payload);

export const channelsManagementLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const channelsManagementPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

export const channelsManagementSearchActions = withSearchActions(__REDUX_STATE_KEY__);

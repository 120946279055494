import isFunction from 'lodash/isFunction';
import React, { Component } from 'react';
import FocusLock from 'react-focus-lock';
import { ReactComponent as CloseSVG } from 'src/assets/remove-icon.svg';
import EscHandler from 'src/components/utils/EscHandler';
import PreventUnmount from 'src/components/utils/PreventUnmount';
import history from 'src/utils/history';

import { Anything } from '../../utils/globalTypes';
import { Wrapper, CloseBtn } from './Overlay.style';

type WarnBeforeCloseCallback = () => void;
type Props = {
  onClose(): void;
  warnBeforeUnmount: boolean;
};

class Overlay extends Component<Props> {
  static defaultProps = {
    warnBeforeUnmount: false,
  };

  unlisten: Anything;
  domElement: Anything;

  componentDidMount() {
    // because we are using transitions, we can't use componentDidMount & componentWillUnmount,
    // but instead we need to rely on the updates of the controlled prop `open`
    window.requestIdleCallback(() => document.body.classList.add('no-scroll'));

    // listen for route changes and trigger an "onClose" everytime we change a page
    // (front-end routing only)
    this.unlisten = history.listen(this.props.onClose);
  }

  componentWillUnmount() {
    // allow scrolling of the body when overlay closes
    window.requestIdleCallback(() => document.body.classList.remove('no-scroll'));

    // don't listen to route changes anymore if you unmount
    this.unlisten();
  }

  handleRef = (domElement: HTMLElement | null): void => {
    // reference to the Overlay parent <div>
    this.domElement = domElement;
  };

  render() {
    const { onClose, warnBeforeUnmount, children } = this.props;

    return (
      <FocusLock>
        <Wrapper ref={this.handleRef} role="dialog">
          {warnBeforeUnmount ? (
            <PreventUnmount unmountCallback={onClose}>
              {(warnBeforeClose: WarnBeforeCloseCallback) => (
                <EscHandler callback={warnBeforeClose}>
                  <CloseBtn type="button" onClick={warnBeforeClose}>
                    <CloseSVG />
                  </CloseBtn>
                  {isFunction(children)
                    ? children({ overlayContainer: this.domElement })
                    : children}
                </EscHandler>
              )}
            </PreventUnmount>
          ) : (
            <EscHandler callback={onClose}>
              <CloseBtn type="button" onClick={onClose}>
                <CloseSVG />
              </CloseBtn>
              {this.props.children}
            </EscHandler>
          )}
        </Wrapper>
      </FocusLock>
    );
  }
}

export default Overlay;

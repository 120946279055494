import { AppState } from 'src/ducks';
import { __REDUX_STATE_KEY__ as __ASSETS_MANAGEMENT_REDUX_STATE_KEY__ } from 'src/ducks/assetsManagement';
import { __REDUX_STATE_KEY__ as __CHANNELS_MANAGEMENT_REDUX_STATE_KEY__ } from 'src/ducks/channelsManagement';
import { __REDUX_STATE_KEY__ as __CLAIM_ANALYTICS_REDUX_STATE_KEY__ } from 'src/ducks/claimAnalytics';
import { __REDUX_STATE_KEY__ as __CLAIM_REDUX_STATE_KEY__ } from 'src/ducks/claims';
import { __REDUX_STATE_KEY__ as __CONFIRMED_MATCHES_REDUX_STATE_KEY__ } from 'src/ducks/confirmedMatches';
import { __REDUX_STATE_KEY__ as __FILTER_REDUX_STATE_KEY__ } from 'src/ducks/filters';
import { __REDUX_STATE_KEY__ as __GLOBAL_REVIEWS_REDUX_STATE_KEY__ } from 'src/ducks/globalReview';
import { __REDUX_STATE_KEY__ as __QUERY_MANAGEMENT_REDUX_STATE_KEY__ } from 'src/ducks/keywordManagement';
import { __REDUX_STATE_KEY__ as __LEADS_GENERATION_REDUX_STATE_KEY__ } from 'src/ducks/leadsGeneration';
import { __REDUX_STATE_KEY__ as __LEGACY_CLAIMS_REDUX_STATE_KEY__ } from 'src/ducks/legacyClaims';
import { __REDUX_STATE_KEY__ as __LEGACY_FILTER_REDUX_STATE_KEY__ } from 'src/ducks/legacyFilters';
import { __REDUX_STATE_KEY__ as __REVIEW_REDUX_STATE_KEY__ } from 'src/ducks/review';
import { __REDUX_STATE_KEY__ as __REVIEW_HISTORY_REDUX_STATE_KEY__ } from 'src/ducks/reviewHistory';

// although this is a factory for the shake of good typing this module needs to know what keys are going to serve
// otherwise it can be easily misplaced somewhere with an error that will slip
const loadingSelectors = (
  stateKey:
    | typeof __LEGACY_FILTER_REDUX_STATE_KEY__
    | typeof __FILTER_REDUX_STATE_KEY__
    | typeof __REVIEW_REDUX_STATE_KEY__
    | typeof __REVIEW_HISTORY_REDUX_STATE_KEY__
    | typeof __CONFIRMED_MATCHES_REDUX_STATE_KEY__
    | typeof __ASSETS_MANAGEMENT_REDUX_STATE_KEY__
    | typeof __LEGACY_CLAIMS_REDUX_STATE_KEY__
    | typeof __CHANNELS_MANAGEMENT_REDUX_STATE_KEY__
    | typeof __GLOBAL_REVIEWS_REDUX_STATE_KEY__
    | typeof __GLOBAL_REVIEWS_REDUX_STATE_KEY__
    | typeof __CLAIM_REDUX_STATE_KEY__
    | typeof __LEADS_GENERATION_REDUX_STATE_KEY__
    | typeof __QUERY_MANAGEMENT_REDUX_STATE_KEY__
    | typeof __CLAIM_ANALYTICS_REDUX_STATE_KEY__
) => {
  const getReduxStateSlice = (state: AppState) => state[stateKey];

  const getLoadingStatus = (state: AppState) => getReduxStateSlice(state).loading;

  return {
    getLoadingStatus,
  };
};
export default loadingSelectors;

import { Filter, FilterOption, Sort, Strategy, FilterParams } from 'src/ducks/filters/types';
import { Account } from 'src/ducks/user';

import { Anything } from '../../utils/globalTypes';

export const handleParams = (
  filters: Filter[],
  sorts: Sort[],
  selected?: Strategy,
  q?: string,
  page?: number,
  selectedAccount?: Account
): FilterParams => {
  const params = selected?.filters.main
    .concat(selected?.filters.more)
    .reduce((acc: Anything, key) => {
      acc[key] = filters
        .find((item: Filter) => item.value === key)
        ?.options.find((option: FilterOption) => option?.selected)?.value;

      return acc;
    }, {});

  params.sort_by = sorts
    .filter((sort) => selected?.sort.includes(sort.value))
    .find((sort) => sort.selected)?.value;

  if (!params.strategy) {
    params.strategy = selected?.value;
  }
  if (q && q !== '') {
    params.q = q;
  }
  params.page = page || 1;

  params.userAccount = selectedAccount?.id;

  return params;
};

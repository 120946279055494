import { Button } from '@orfium/ictinus';
import React from 'react';

import ResultRow from './components/ResultRow';
import { Container, Title, Error, Result, ActionContainer } from './CustomContent.style';

type Props = {
  title?: string;
  onError?: () => void;
  results: { [index: string]: number };
  onDownload?: () => void;
};

const CustomContent = ({ title, onError, results, onDownload }: Props) => {
  return (
    <Container>
      {title && <Title data-testid={'custom-content-title'}>{title}</Title>}
      {onError && (
        <Error data-testid={'custom-content-error'}>
          Download error CSV&nbsp;
          <button onClick={onError}>here</button>, fix it and re-upload it.
        </Error>
      )}
      <Result>
        {Object.entries(results).map(([key, value]) => (
          <ResultRow key={key} label={`${key}`} value={value} />
        ))}
      </Result>
      {onDownload && (
        <ActionContainer data-testid={'custom-content-action'}>
          <Button type={'secondary'} filled={false} size={'sm'} transparent onClick={onDownload}>
            Download output
          </Button>
        </ActionContainer>
      )}
    </Container>
  );
};

export default CustomContent;

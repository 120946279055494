import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';
import { withSearchReducer } from 'src/ducks/factories/search';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  LegacyClaimsActionTypes,
  LegacyClaimsReducer,
  FETCH_LEGACY_CLAIMS_FAILURE,
  SET_LEGACY_CLAIMS,
  SET_LEGACY_CLAIMS_COUNT,
} from './types';

const initialState: LegacyClaimsReducer = {
  list: [],
  countItems: 0,
  selected: [],
};

function legacyClaims(state = initialState, action: LegacyClaimsActionTypes): LegacyClaimsReducer {
  switch (action.type) {
    case SET_LEGACY_CLAIMS:
      return {
        ...state,
        list: action.payload,
      };
    case SET_LEGACY_CLAIMS_COUNT:
      return {
        ...state,
        countItems: action.payload,
      };
    case FETCH_LEGACY_CLAIMS_FAILURE:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
}

export default compose(
  withSearchReducer<LegacyClaimsReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withPaginationReducer<LegacyClaimsReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withLoadingReducer<LegacyClaimsReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(legacyClaims);

import { CHANGE_PAGE, RouterActionTypes, RouterReducer } from './types';

const initialState: RouterReducer = {
  page: '',
};

function router(state = initialState, action: RouterActionTypes): RouterReducer {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}

export default router;

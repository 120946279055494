export const ROUTE_CHANGE = 'ROUTE_CHANGE';
export const APP_INIT = 'APP_INIT';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export interface Route {
  location: {
    pathname: string;
    search: string;
    hash: string;
    key: string;
  };
  action: string;
}

export type ChangePageAction = {
  type: typeof CHANGE_PAGE;
  payload: string;
};

export type RouterActionTypes = ChangePageAction;

export type RouterReducer = {
  page: string;
};

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';
import { withPaginationSelectors } from 'src/ducks/factories/pagination';
import { withSearchSelectors } from 'src/ducks/factories/search';

export const __REDUX_STATE_KEY__ = 'global';

export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getGlobalReviews = (state: AppState) => getReduxStateSlice(state).list;
export const getGlobalReviewsCount = (state: AppState) => getReduxStateSlice(state).countItems;
export const getGlobalReviewsIsCountLoading = (state: AppState) =>
  getReduxStateSlice(state).countIsLoading;
export const getGlobalReviewsIsCountError = (state: AppState) =>
  getReduxStateSlice(state).countIsError;
export const getGlobalReviewsCountEstimate = (state: AppState) =>
  getReduxStateSlice(state).estimate;
export const getGlobalReviewsIsError = (state: AppState) => getReduxStateSlice(state).isError;

export const globalReviewsLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

export const globalReviewsPaginationSelectors = withPaginationSelectors(__REDUX_STATE_KEY__);

export const globalReviewsSearchSelectors = withSearchSelectors(__REDUX_STATE_KEY__);

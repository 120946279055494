import React, { ComponentType, memo } from 'react';
import { useTransition } from 'react-spring';
import { Overlay } from 'src/ducks/ui/overlay';
import ReviewHistoryEditAsset from 'src/pages/ReviewHistoryEditAsset';
import ReviewQueueEditAsset from 'src/pages/ReviewQueueEditAsset';
import { FORMS as types } from 'src/utils/constants';

import { OverlayTransition } from './OverlayController.style';

type MapOverlayProps = {
  type: string;
};

// gets the proper component based on type
const MapOverlayTypeToComponent: React.FC<MapOverlayProps> = ({ type, ...rest }) => {
  switch (type) {
    case types.REVIEW_QUEUE_EDIT_ASSET:
      return <ReviewQueueEditAsset {...rest} />;
    case types.REVIEW_HISTORY_EDIT_ASSET:
      return <ReviewHistoryEditAsset {...rest} />;
    default:
      return <h1>There was an error and the content could not be loaded</h1>;
  }
};

type ReduxProps = {
  overlays: Overlay[];
  removeOverlay(): void;
};

const OverlayController: React.FC<ReduxProps> = ({ overlays }) => {
  const transitions = useTransition(overlays, (overlay) => overlay.id || '', {
    from: { transform: 'translate3d(0, 100vh, 0)' },
    enter: { transform: 'translate3d(0, 0, 0)' },
    leave: { transform: 'translate3d(0, 100vh, 0)' },
  });

  return (
    <React.Fragment>
      {transitions.map(({ item: overlay, props: styles, key }) => (
        <OverlayTransition key={key} style={styles}>
          <MapOverlayTypeToComponent {...overlay.props} type={overlay.type} />
        </OverlayTransition>
      ))}
    </React.Fragment>
  );
};

// this is a known issue on typescript + redux + memo. When there is a memoized component that is connected
export default memo(OverlayController) as unknown as ComponentType;

import axios, { Method } from 'axios';
import { axiosPromiseResult } from 'src/utils/common';

import { Anything } from '../utils/globalTypes';

const GET = 'get';
const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';

export const METHODS = { GET, POST, PUT, DELETE };

const baseURL = process.env.REACT_APP_BASE_URL;

export const orfiumAxios = axios.create({
  baseURL: baseURL + '/v2',
});

export const orfiumAxiosV3 = axios.create({
  baseURL: baseURL + '/v3',
});

export const request = <T = Anything>(
  method: string,
  url: string,
  { params }: Anything,
  withoutBase = false,
  headers = {},
  onDownloadProgress?: (e: Anything) => void,
  onUploadProgress?: (e: Anything) => void,
  responseType?: 'arraybuffer' | 'document' | 'json' | 'text' | 'stream',
  isV3 = false
) => {
  const cancelTokenSource = axios.CancelToken.source();
  const config = {
    method: method as Method,
    url,
    cancelToken: cancelTokenSource.token,
    data: params,
    params: method === METHODS.GET ? params : undefined,
    headers,
    onDownloadProgress,
    onUploadProgress,
    responseType,
  };

  const orfiumAxiosInstance = isV3 ? orfiumAxiosV3 : orfiumAxios;

  const request = () =>
    withoutBase
      ? axiosPromiseResult<T>(axios(config))
      : axiosPromiseResult<T>(orfiumAxiosInstance(config));
  return { request, cancelTokenSource };
};

export const requestV3 = <T = Anything>(
  method: string,
  url: string,
  { params }: Anything,
  withoutBase = false,
  headers = {},
  onDownloadProgress?: (e: Anything) => void,
  onUploadProgress?: (e: Anything) => void,
  responseType?: 'arraybuffer' | 'document' | 'json' | 'text' | 'stream'
) =>
  request<T>(
    method,
    url,
    { params },
    withoutBase,
    headers,
    onDownloadProgress,
    onUploadProgress,
    responseType,
    true
  );

export const setAxiosToken = (token: string) => {
  orfiumAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
  orfiumAxiosV3.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setAxiosAccount = (account: null | number = null) => {
  orfiumAxios.defaults.headers.common.Account = account;
  orfiumAxiosV3.defaults.headers.common.Account = account;
};

export const deleteAxiosHeaders = () => {
  delete orfiumAxios.defaults.headers.common.Authorization;
  delete orfiumAxiosV3.defaults.headers.common.Authorization;
};

// This is for development only. It mocks the successful usage of a provider.
export const mockProvider = (data: Anything) => {
  const request = () => new Promise((resolve) => resolve(data));

  return { request };
};

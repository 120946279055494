import styled from '@emotion/styled';
import { flex } from 'src/theme/functions';
import { device, size } from 'src/theme/globals';

export const AppMainWrapper = styled.div`
  ${flex};
  position: relative;
`;

export const AppContentWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 48px);

  @media ${device.tablet} and (max-width: ${size.almostLaptopL}) {
    margin-left: 50px;
  }
`;

import React from 'react';
import { MODALS } from 'src/utils/constants';

import { Anything } from '../../../utils/globalTypes';

type ReduxProps = {
  pushModal(o: Record<string, unknown>): void;
};

type CallbackFun = () => void;
type Props = {
  children(v: CallbackFun): React.ReactNode | React.ReactElement<Anything>;
  unmountCallback(): void;
  warningMessage?: boolean;
};

class PreventUnmount extends React.Component<Props & ReduxProps> {
  componentDidMount() {
    window.addEventListener('beforeunload', this.listenForRefreshes);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.listenForRefreshes);
  }

  // prevent the user from refreshing while uploading (warn him that if he leaves then BOOM)
  listenForRefreshes = (event: Event) => {
    const { warningMessage } = this.props;

    event.preventDefault();

    event.returnValue = Boolean(warningMessage); // Gecko, Trident, Chrome 34+
    return warningMessage; // Gecko, WebKit, Chrome <34
  };

  showWarningModal = () => {
    const { unmountCallback } = this.props;
    this.props.pushModal({ type: MODALS.PREVENT_UNMOUNT, props: { unmountCallback } });
  };

  render() {
    return this.props.children ? this.props.children(this.showWarningModal) : null;
  }
}

export default PreventUnmount;

import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Overlay from 'src/components/Overlay';
import { removeOverlay } from 'src/ducks/ui/overlay';

import { Anything } from '../utils/globalTypes';

type Props = {
  overlayId: string;
  warnBeforeUnmount: boolean;
};

type ConnectedProps = {
  removeOverlay(id: string): void;
};

const withOverlay: React.FC<Props> =
  ({ overlayId, warnBeforeUnmount = false }): Anything =>
  (Component: Anything) => {
    const ComponentWithOverlay: React.FC<ConnectedProps> = ({ removeOverlay, ...props }) => {
      const closeOverlay = useCallback(() => removeOverlay(overlayId), [removeOverlay]);

      return (
        <Overlay onClose={closeOverlay} warnBeforeUnmount={warnBeforeUnmount}>
          <Component {...props} closeOverlay={closeOverlay} />
        </Overlay>
      );
    };

    const mapDispatchToProps = {
      removeOverlay,
    };

    return connect(null, mapDispatchToProps)(ComponentWithOverlay as Anything);
  };

export default withOverlay as Anything;

import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';
import { withSearchReducer } from 'src/ducks/factories/search';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  KeywordManagementActionTypes,
  KeywordManagementReducer,
  SET_KEYWORD_MANAGEMENT,
  SET_KEYWORD_MANAGEMENT_COUNT,
  TRIGGER_KEYWORD_MANAGEMENT_ACTION_SUCCESS,
} from './types';

const initialState: KeywordManagementReducer = {
  list: [],
  countItems: 0,
  estimate: false,
};

function keywordManagementReducer(
  state = initialState,
  action: KeywordManagementActionTypes
): KeywordManagementReducer {
  switch (action.type) {
    case TRIGGER_KEYWORD_MANAGEMENT_ACTION_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => ({
          ...item,
          enabled: action.payload.keywords.includes(item.keyword)
            ? action.payload.enabled
            : item.enabled,
        })),
      };
    case SET_KEYWORD_MANAGEMENT:
      return {
        ...state,
        list: action.payload,
      };
    case SET_KEYWORD_MANAGEMENT_COUNT:
      return {
        ...state,
        countItems: action.payload.count,
        estimate: action.payload.estimate,
      };
    default:
      return state;
  }
}

export default compose(
  withSearchReducer<KeywordManagementReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withPaginationReducer<KeywordManagementReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withLoadingReducer<KeywordManagementReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(keywordManagementReducer);

import styled from '@emotion/styled';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.div`
  text-transform: capitalize;
`;

export const Value = styled.div`
  font-weight: ${({ theme }) => theme.typography.weights.medium};
`;

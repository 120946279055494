import { SetLoadingAction, SET_LOADING } from 'src/ducks/factories/loading/types';
import { simpleAction } from 'src/ducks/utils';

const loadingActions = (prefix: string) => {
  const setLoading = (payload: boolean): SetLoadingAction =>
    simpleAction(SET_LOADING(prefix), payload);

  return {
    setLoading,
  };
};
export default loadingActions;

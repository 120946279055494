import { __REDUX_STATE_KEY__ } from 'src/ducks/claimAnalytics/selectors';
import {
  FETCH_CLAIM_ANALYTICS_REQUEST,
  FETCH_CLAIM_ANALYTICS_FAILURE,
  FetchClaimAnalyticsRequestAction,
  SetClaimAnalyticsAction,
  SET_CLAIM_ANALYTICS,
  ClaimAnalyticsParams,
  ClaimAnalyticsReducer,
  FetchClaimAnalyticsFailAction,
} from 'src/ducks/claimAnalytics/types';
import { withLoadingActions } from 'src/ducks/factories/loading';
import { simpleAction } from 'src/ducks/utils';

export const fetchClaimAnalyticsRequest = (
  params: ClaimAnalyticsParams
): FetchClaimAnalyticsRequestAction => simpleAction(FETCH_CLAIM_ANALYTICS_REQUEST, params);

export const setClaimAnalytics = (dashboard: ClaimAnalyticsReducer): SetClaimAnalyticsAction =>
  simpleAction(SET_CLAIM_ANALYTICS, dashboard);

export const fetchClaimAnalyticsFail = (): FetchClaimAnalyticsFailAction =>
  simpleAction(FETCH_CLAIM_ANALYTICS_FAILURE);

export const claimAnalyticsLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';
import { withPaginationSelectors } from 'src/ducks/factories/pagination';
import { withSearchSelectors } from 'src/ducks/factories/search';

export const __REDUX_STATE_KEY__ = 'keywordManagement';

export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getKeywordManagements = (state: AppState) => getReduxStateSlice(state).list;
export const getKeywordManagementsCount = (state: AppState) => getReduxStateSlice(state).countItems;
export const getKeywordManagementsCountEstimate = (state: AppState) =>
  getReduxStateSlice(state).estimate;

export const keywordManagementsLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

export const keywordManagementsPaginationSelectors = withPaginationSelectors(__REDUX_STATE_KEY__);

export const keywordManagementsSearchSelectors = withSearchSelectors(__REDUX_STATE_KEY__);

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';
import { withPaginationSelectors } from 'src/ducks/factories/pagination';
import { withSearchSelectors } from 'src/ducks/factories/search';

export const __REDUX_STATE_KEY__ = 'assetsManagement';
export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getAssetsManagement = (state: AppState) => getReduxStateSlice(state).list;
export const getAssetsManagementCount = (state: AppState) => getReduxStateSlice(state).countItems;
export const getSelectedAssets = (state: AppState) => getReduxStateSlice(state).selected;

export const assetsManagementLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

export const assetsManagementPaginationSelectors = withPaginationSelectors(__REDUX_STATE_KEY__);

export const assetsManagementSearchSelectors = withSearchSelectors(__REDUX_STATE_KEY__);

import { METHODS, request } from '../axiosInstances';

export default {
  get: (params = {}) => request(METHODS.GET, `/review/`, { params }),
  getCount: (params = {}) => request(METHODS.GET, `/review/count/`, { params }),
  getQuery: (itemId: number) => request(METHODS.GET, `/queries/${itemId}/`, {}),
  update: (itemId: number, params: Record<string, unknown>) =>
    request(METHODS.PUT, `/review/${itemId}/`, { params }),
  undoReviewAction: (action: string) =>
    request(METHODS.POST, `/review/actions/undo/${action}/`, {}),
};

import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import {
  claimsLoadingActions,
  claimsPaginationActions,
  fetchClaimsCountRequest,
  fetchClaimsFail,
  fetchClaimsRequest,
  fetchClaimsSummary,
  setClaims,
  setClaimsCount,
  setClaimsSummary,
} from 'src/ducks/claims/actions';
import { __REDUX_STATE_KEY__ } from 'src/ducks/claims/selectors';
import {
  FETCH_CLAIMS_COUNT_REQUEST,
  FETCH_CLAIMS_REQUEST,
  FETCH_CLAIMS_SUMMARY,
  FetchClaimsCountRequestAction,
  FetchClaimsRequestAction,
  FetchClaimsSummaryAction,
  GET_CLAIMS_EXPORTS,
  GetClaimsExportsAction,
} from 'src/ducks/claims/types';
import { SET_PAGINATION_PAGE, SetPaginationPageAction } from 'src/ducks/factories/pagination/types';
import {
  CHANGE_FILTERS,
  CHANGE_SORT,
  ChangeFilterAction,
  ChangeSortAction,
  FETCH_FILTERS_SUCCESS,
  FetchFiltersSuccessAction,
  getFilterOptions,
  getSelectedStrategy,
  getSort,
} from 'src/ducks/filters';
import { handleParams } from 'src/ducks/filters/utils';
import { getGlobalSearch, SET_GLOBAL_SEARCH, SetGlobalSearchAction } from 'src/ducks/globalSearch';
import { claimAPI } from 'src/providers';
import { GlobalReviewPaginationLimit, OTHER_PAGES } from 'src/utils/constants';

const triggerFetchActionsBasedOnStateFilters = (state: AppState) => {
  const selected = getSelectedStrategy(state);
  if (selected?.value && OTHER_PAGES.CLAIM_LIST !== selected?.value) {
    return of();
  }
  const filters = getFilterOptions(state);
  const sort = getSort(state);
  const q = getGlobalSearch(state);
  const params = handleParams(filters, sort, selected, q, 1);
  return concat(
    of(claimsLoadingActions.setLoading(true)),
    of(claimsPaginationActions.setPaginationPage(1)),
    of(fetchClaimsCountRequest(params)),
    of(fetchClaimsSummary())
  );
};

//TODO: On FetchFiltersSuccessAction maybe check to keep the page from the url.
const filtersSucceededEpic = (
  action$: ActionsObservable<
    FetchFiltersSuccessAction | ChangeFilterAction | ChangeSortAction | SetGlobalSearchAction
  >,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_FILTERS_SUCCESS, CHANGE_FILTERS, CHANGE_SORT, SET_GLOBAL_SEARCH),
    switchMap(() => {
      return triggerFetchActionsBasedOnStateFilters(state$.value);
    })
  );

const fetchClaimEpic = (action$: ActionsObservable<FetchClaimsRequestAction>) =>
  action$.pipe(
    ofType(FETCH_CLAIMS_REQUEST),
    switchMap(({ payload }) => {
      return concat(
        of(claimsLoadingActions.setLoading(true)),
        from(claimAPI.single.get({ ...payload }).request()).pipe(
          mergeMap((data) => of(setClaims(data))),
          catchError(() => of(fetchClaimsFail()))
        ),
        of(claimsLoadingActions.setLoading(false))
      );
    })
  );

const fetchClaimSummaryEpic = (action$: ActionsObservable<FetchClaimsSummaryAction>) =>
  action$.pipe(
    ofType(FETCH_CLAIMS_SUMMARY),
    switchMap(() => {
      return concat(
        from(claimAPI.single.summary().request()).pipe(
          mergeMap((data) => of(setClaimsSummary(data)))
        )
      );
    })
  );

const fetchClaimCountEpic = (action$: ActionsObservable<FetchClaimsCountRequestAction>) =>
  action$.pipe(
    ofType(FETCH_CLAIMS_COUNT_REQUEST),
    switchMap(({ payload }) =>
      from(
        claimAPI.single
          .getCount({
            ...payload,
          })
          .request()
      ).pipe(
        mergeMap((data) =>
          of(
            setClaimsCount(data),
            claimsPaginationActions.setPaginationTotalPages(
              Math.ceil(data.count / GlobalReviewPaginationLimit)
            )
          )
        )
      )
    )
  );

const changePageEpic = (
  action$: ActionsObservable<SetPaginationPageAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REDUX_STATE_KEY__)),
    switchMap(({ payload }) => {
      const selected = getSelectedStrategy(state$.value);

      if ((selected?.value && OTHER_PAGES.CLAIM_LIST !== selected?.value) || !selected) {
        return of();
      }

      const filters = getFilterOptions(state$.value);
      const sort = getSort(state$.value);
      const q = getGlobalSearch(state$.value);
      const params = handleParams(filters, sort, selected, q, payload);

      return concat(
        of(claimsLoadingActions.setLoading(true)),
        of(fetchClaimsRequest(params)),
        of(claimsLoadingActions.setLoading(false))
      );
    })
  );

const getExportsEpic = (action$: ActionsObservable<GetClaimsExportsAction>) =>
  action$.pipe(
    ofType(GET_CLAIMS_EXPORTS),
    switchMap(() => {
      //TODO revisit in the future, add fileTransfer components
      return from(claimAPI.single.exports().request()).pipe(
        mergeMap((data) => {
          data.forEach((claimExport) => {
            const link = document.createElement('a');
            link.href = claimExport.url;
            document.body.appendChild(link);
            link.click();
            link.remove();
          });

          return of();
        })
      );
    })
  );

export default {
  filtersSucceededEpic,
  fetchClaimEpic,
  fetchClaimCountEpic,
  fetchClaimSummaryEpic,
  changePageEpic,
  getExportsEpic,
};

import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  ClaimAnalyticsDonutType,
  ClaimAnalyticsActionTypes,
  ClaimAnalyticsReducer,
  SET_CLAIM_ANALYTICS,
} from './types';

const initialDonutState: ClaimAnalyticsDonutType = {
  claims: { total_claims: 0, orfium_claims_percentage: 0, orfium_claims: 0 },
  views: { total_views: 0, orfium_views_percentage: 0, orfium_views: 0 },
};

export const initialState: ClaimAnalyticsReducer = {
  scale: { min: 0, max: 0 },
  bars: [],
  donuts: initialDonutState,
  last_updated: '',
};

function claimAnalyticsReducer(
  state = initialState,
  action: ClaimAnalyticsActionTypes
): ClaimAnalyticsReducer {
  switch (action.type) {
    case SET_CLAIM_ANALYTICS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default compose(
  withLoadingReducer<ClaimAnalyticsReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(claimAnalyticsReducer);

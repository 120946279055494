import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { withSearchActions } from 'src/ducks/factories/search';
import { simpleAction } from 'src/ducks/utils';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_KEYWORD_MANAGEMENT_COUNT_FAILURE,
  FETCH_KEYWORD_MANAGEMENT_COUNT_REQUEST,
  FETCH_KEYWORD_MANAGEMENT_FAILURE,
  FETCH_KEYWORD_MANAGEMENT_REQUEST,
  FetchKeywordManagementsCountFailureAction,
  FetchKeywordManagementsCountRequestAction,
  FetchKeywordManagementsFailureAction,
  FetchKeywordManagementsRequestAction,
  KeywordManagementCountResponse,
  KeywordManagementParams,
  KeywordManagementsTriggerActionParams,
  KeywordManagementType,
  REMOVE_KEYWORD_MANAGEMENT,
  RemoveKeywordManagementsAction,
  SET_KEYWORD_MANAGEMENT,
  SET_KEYWORD_MANAGEMENT_COUNT,
  SetKeywordManagementsAction,
  SetKeywordManagementsCountAction,
  TRIGGER_KEYWORD_MANAGEMENT_ACTION,
  TRIGGER_KEYWORD_MANAGEMENT_ACTION_SUCCESS,
  TriggerKeywordManagementsAction,
  TriggerKeywordManagementsActionSuccess,
} from './types';

export const fetchKeywordManagementsRequest = (
  params: KeywordManagementParams
): FetchKeywordManagementsRequestAction => simpleAction(FETCH_KEYWORD_MANAGEMENT_REQUEST, params);

export const fetchKeywordManagementsFail = (): FetchKeywordManagementsFailureAction =>
  simpleAction(FETCH_KEYWORD_MANAGEMENT_FAILURE);

export const setKeywordManagements = (
  leads: KeywordManagementType[]
): SetKeywordManagementsAction => simpleAction(SET_KEYWORD_MANAGEMENT, leads);

export const removeKeywordManagements = (
  leadsIds: (string | number)[]
): RemoveKeywordManagementsAction => simpleAction(REMOVE_KEYWORD_MANAGEMENT, leadsIds);

export const fetchKeywordManagementsCountRequest = (
  params: KeywordManagementParams
): FetchKeywordManagementsCountRequestAction =>
  simpleAction(FETCH_KEYWORD_MANAGEMENT_COUNT_REQUEST, params);

export const fetchKeywordManagementsCountFail = (): FetchKeywordManagementsCountFailureAction =>
  simpleAction(FETCH_KEYWORD_MANAGEMENT_COUNT_FAILURE);

export const setKeywordManagementsCount = (
  payload: KeywordManagementCountResponse
): SetKeywordManagementsCountAction => simpleAction(SET_KEYWORD_MANAGEMENT_COUNT, payload);

export const triggerKeywordManagementsAction = (
  payload: KeywordManagementsTriggerActionParams
): TriggerKeywordManagementsAction => simpleAction(TRIGGER_KEYWORD_MANAGEMENT_ACTION, payload);

export const triggerKeywordManagementsActionSuccess = (
  payload: KeywordManagementsTriggerActionParams
): TriggerKeywordManagementsActionSuccess =>
  simpleAction(TRIGGER_KEYWORD_MANAGEMENT_ACTION_SUCCESS, payload);

export const keywordManagementLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const keywordManagementPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

export const keywordManagementSearchActions = withSearchActions(__REDUX_STATE_KEY__);

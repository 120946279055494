export const SET_GLOBAL_SEARCH = 'SET_GLOBAL_SEARCH';
export const RESET_GLOBAL_SEARCH = 'RESET_GLOBAL_SEARCH';

export type SetGlobalSearchAction = {
  type: typeof SET_GLOBAL_SEARCH;
  payload: string;
};

export type ResetGlobalSearchAction = {
  type: typeof RESET_GLOBAL_SEARCH;
};

export type GlobalSearchActionTypes = SetGlobalSearchAction | ResetGlobalSearchAction;

export type GlobalSearchReducer = {
  q: string;
};

import styled from '@emotion/styled';

type LabelClassProps = { dark?: boolean };

export const LabelClass = styled.label<LabelClassProps>`
  font-size: 13px;
  margin-right: 5px;
  text-align: right;
  display: inline-block;
  white-space: nowrap;
  color: ${({ dark }) => (dark ? '#4a4a4a' : '#231f20')};
`;

import { SimpleAction } from 'src/ducks/utils';

export const ADD_OVERLAY = 'ADD_OVERLAY';
export const DELETE_OVERLAY = 'DELETE_OVERLAY';

export type ActionTypeAdd = SimpleAction<
  typeof ADD_OVERLAY,
  { type: string; props: Record<string, unknown> }
>;
export type ActionTypeDelete = SimpleAction<typeof DELETE_OVERLAY, { overlayId: string | null }>;

export type OverlayAction = ActionTypeAdd | ActionTypeDelete;

export type Overlay = {
  id?: string;
  type: string;
  props?: Record<string, unknown>;
};

export type Overlays = {
  byKeyId: {
    [key: string]: Overlay;
  };
  keys: string[];
};

import { TopNavBar } from '@orfium/ictinus';
import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'src/assets/VIDEOHUNTER.svg';
import { User } from 'src/ducks/user';
import { Ability } from 'src/ducks/user';
import { can, pickLandingPage } from 'src/ducks/user/policy';
import { OPERATIONS } from 'src/utils/constants';

import { HeaderWrapper } from './Header.style';
import { useGlobalSearch } from './hooks';
import { getAdditionalTools, pickPlaceholderPerPage } from './utils';

export type ReduxProps = {
  page: string;
  globalSearch: string;
  sidebarIsOpen: boolean;
  user: User;
  ability: Ability | undefined;
};

export type ReduxActions = {
  openSidebar(): void;
  closeSidebar(): void;
  updateUserSelectedAccount(accountId: string | number): void;
  unsetUserInfo(): void;
  resetFilter(): void;
  setGlobalSearch(s: string): void;
};

const Header: React.FC<ReduxProps & ReduxActions> = ({
  page,
  globalSearch,
  sidebarIsOpen,
  user,
  openSidebar,
  closeSidebar,
  updateUserSelectedAccount,
  unsetUserInfo,
  ability,
  resetFilter,
  setGlobalSearch,
}) => {
  const [inputValue, handleSearch, handleKeyPress, disabled] = useGlobalSearch(
    globalSearch,
    page,
    setGlobalSearch
  );

  const getUserMenu = useCallback(() => {
    const userName = user.name && user.name !== ' ' ? user.name : user.email ? user.email : '';

    const items = can({ I: OPERATIONS.READ, This: 'adminLink', ability: ability as Ability })
      ? ['Django Admin', 'Logout']
      : ['Logout'];

    const onSelect = (selectedItem: string) => {
      if (selectedItem === 'Django Admin') {
        window.open(`${process.env.REACT_APP_ORIGINAL_URL}/admin`);
      }
      if (selectedItem === 'Logout') {
        setGlobalSearch('');
        resetFilter();
        unsetUserInfo();
      }
    };

    const userAvatar = { src: '', letter: `${userName.charAt(0)}`, color: 'darkBlue-500' };

    return { items, userName, onSelect, userAvatar };
  }, [user, ability, setGlobalSearch, resetFilter, unsetUserInfo]);

  const additionalTools = useMemo(
    () => getAdditionalTools(user, updateUserSelectedAccount),
    [user, updateUserSelectedAccount]
  );

  return (
    <HeaderWrapper>
      <TopNavBar
        searchPlaceholder={pickPlaceholderPerPage(page.split('?')[0])}
        searchDefaultValue={inputValue}
        isSearchDisabled={disabled}
        onSearchHandler={handleSearch}
        onKeyPressHandler={handleKeyPress}
        logoIcon={
          <NavLink to={{ pathname: pickLandingPage(ability) }} style={{ paddingLeft: '8px' }}>
            <LogoIcon />
          </NavLink>
        }
        onMenuIconClick={() => (sidebarIsOpen ? closeSidebar() : openSidebar())}
        additionalTools={additionalTools}
        userMenu={getUserMenu()}
      />
    </HeaderWrapper>
  );
};

export default Header;

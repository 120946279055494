import React from 'react';

import { Row, Label, Value } from './ResultRow.style';

type Props = {
  label: string;
  value: number;
};

const ResultRow = ({ label, value }: Props) => {
  return (
    <Row>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Row>
  );
};

export default ResultRow;

import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap, map } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { SET_PAGINATION_PAGE } from 'src/ducks/factories/pagination/types';
import { getFilters } from 'src/ducks/legacyFilters';
import { handleStrategiesParams } from 'src/ducks/legacyFilters/utils';
import { pagePicker } from 'src/ducks/utils';
import { assetStrategiesAPI } from 'src/providers';
import { ChannelsPaginationLimit } from 'src/utils/constants';

import { Anything } from '../../utils/globalTypes';
import {
  fetchAssetsFailure,
  setAssets,
  fetchAssetsRequest,
  setAssetsCount,
  assetsManagementLoadingActions,
  assetsManagementPaginationActions,
  updateSelectedAssets,
  setSelectedAssets,
  fetchAssetsCountRequest,
} from './actions';
import {
  __REDUX_STATE_KEY__,
  assetsManagementSearchSelectors,
  getSelectedAssets,
} from './selectors';
import {
  FetchAssetsRequestAction,
  FETCH_ASSETS_REQUEST,
  SET_SELECTED_ASSETS,
  FETCH_ASSETS_COUNT_REQUEST,
  FetchAssetsCountRequestAction,
  SetSelectedAssetsAction,
  UPDATE_ASSETS,
  UpdateAssetsAction,
} from './types';

const fetchAssetsEpic = (
  action$: ActionsObservable<FetchAssetsRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_ASSETS_REQUEST),
    switchMap(({ payload }) => {
      const { withLoading = true, ...rest } = payload;
      return concat(
        of(assetsManagementLoadingActions.setLoading(withLoading)),
        from(
          assetStrategiesAPI.single
            .get({
              ...rest,
              q: assetsManagementSearchSelectors.getSearchValue(state$.value),
            })
            .request()
        ).pipe(
          mergeMap((data) => [setAssets(data), setSelectedAssets([])]),
          catchError(() => of(fetchAssetsFailure()))
        ),
        of(assetsManagementLoadingActions.setLoading(false))
      );
    })
  );

const fetchAssetsCountRequestEpic = (
  action$: ActionsObservable<FetchAssetsCountRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_ASSETS_COUNT_REQUEST),
    switchMap(({ payload }) =>
      concat(
        of(assetsManagementLoadingActions.setLoading(true)),
        from(
          assetStrategiesAPI.single
            .getCount({
              ...payload,
              q: assetsManagementSearchSelectors.getSearchValue(state$.value),
            })
            .request()
        ).pipe(
          mergeMap((data) =>
            of(
              setAssetsCount(data.count),
              // assetsManagementPaginationActions.setPaginationPage(payload.page),
              assetsManagementPaginationActions.setPaginationTotalPages(
                Math.ceil(data.count / ChannelsPaginationLimit)
              )
            )
          )
        ),
        of(assetsManagementLoadingActions.setLoading(false))
      )
    )
  );

const changePageEpic = (action$: ActionsObservable<Anything>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REDUX_STATE_KEY__)),
    switchMap((data) =>
      concat(
        of(assetsManagementLoadingActions.setLoading(true)),
        of(
          fetchAssetsRequest(
            handleStrategiesParams(
              getFilters(state$.value),
              data.payload,
              assetsManagementSearchSelectors.getSearchValue(state$.value)
            )
          )
        ),
        of(assetsManagementLoadingActions.setLoading(false))
      )
    )
  );

const setSelectedAssetsEpic = (
  action$: ActionsObservable<SetSelectedAssetsAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(SET_SELECTED_ASSETS),
    map(({ payload }) => {
      const selected = getSelectedAssets(state$.value);
      if (payload.length === 1) {
        return selected.includes(payload[0])
          ? updateSelectedAssets([
              ...selected.splice(0, selected.indexOf(payload[0])),
              ...selected.splice(selected.indexOf(payload[0]) + 1, selected.length - 1),
            ])
          : updateSelectedAssets([...selected, payload[0]]);
      } else return updateSelectedAssets(payload);
    })
  );

const updateAssetsEpic = (
  action$: ActionsObservable<UpdateAssetsAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(UPDATE_ASSETS),
    switchMap(({ payload }) => {
      const {
        [__REDUX_STATE_KEY__]: { page, totalPages },
      } = state$.value;
      const fetchPage = page === totalPages ? pagePicker(page) : page;
      return concat(
        of(assetsManagementLoadingActions.setLoading(true)),
        from(
          assetStrategiesAPI.bulk
            .update({
              ...payload,
            })
            .request()
        ).pipe(map(() => updateSelectedAssets([]))),
        of(
          assetsManagementPaginationActions.setPaginationPage(fetchPage),
          fetchAssetsCountRequest(
            handleStrategiesParams(
              getFilters(state$.value),
              fetchPage,
              assetsManagementSearchSelectors.getSearchValue(state$.value)
            )
          )
        ),
        of(assetsManagementLoadingActions.setLoading(false))
      );
    })
  );

export default {
  fetchAssetsEpic,
  fetchAssetsCountRequestEpic,
  changePageEpic,
  setSelectedAssetsEpic,
  updateAssetsEpic,
};

import styled from '@emotion/styled';

export const GuidelinesContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.fontSizes[12]};
  color: ${({ theme }) => theme.utils.getColor('darkBlue', 500)};
  font-weight: ${({ theme }) => theme.typography.weights.medium};
  text-decoration: underline;
`;

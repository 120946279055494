import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { NormalizedActionsOptions } from 'src/ducks/legacyFilters/types';

import { simpleAction } from '../utils';
import { __REDUX_STATE_KEY__ } from './selectors';
import {
  SET_HISTORY_REVIEWS,
  FETCH_HISTORY_REVIEWS_REQUEST,
  FETCH_HISTORY_REVIEWS_FAILURE,
  FETCH_HISTORY_ACTIONS_REQUEST,
  FETCH_HISTORY_ACTIONS_SUCCESS,
  UPDATE_HISTORY_REVIEWS,
  FetchHistoryRequestAction,
  ReviewHistoryActionTypes,
  ReviewHistory,
  FetchHistorySuccessAction,
  UpdateHistoryRequestAction,
  ReviewHistoryUpdateData,
} from './types';

export const setHistoryReviews = (reviews: ReviewHistory[]): ReviewHistoryActionTypes =>
  simpleAction(SET_HISTORY_REVIEWS, reviews);

export const fetchHistoryReviewsRequest = (
  params: Record<string, unknown> = {}
): ReviewHistoryActionTypes => simpleAction(FETCH_HISTORY_REVIEWS_REQUEST, params);

export const fetchHistoryReviewsFail = (): ReviewHistoryActionTypes =>
  simpleAction(FETCH_HISTORY_REVIEWS_FAILURE);

export const fetchHistoryOptionsRequest = (): FetchHistoryRequestAction =>
  simpleAction(FETCH_HISTORY_ACTIONS_REQUEST);

export const fetchHistoryOptionsSuccess = (
  payload: NormalizedActionsOptions[]
): FetchHistorySuccessAction => simpleAction(FETCH_HISTORY_ACTIONS_SUCCESS, payload);

export const setReviewHistory = (payload: ReviewHistoryUpdateData): UpdateHistoryRequestAction =>
  simpleAction(UPDATE_HISTORY_REVIEWS, payload);

export const reviewHistoryLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const reviewPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import GenericErrorPage from 'src/pages/GenericErrorPage';

import { Anything } from '../../../utils/globalTypes';
import { Wrapper } from './ErrorBoundary.style';

type State = {
  hasError: boolean;
};

type Props = {
  errorMessage?: React.ReactElement | string;
  children: React.ReactElement<Anything> | React.ReactElement<Anything>[] | null;
  fallbackToErrorScreen?: boolean;
  fallbackToHidden?: boolean;
};

const defaultProps = {
  errorMessage: 'Something went wrong and we could not display correctly this content',
  fallbackToErrorScreen: false,
  fallbackToHidden: false,
};

class ErrorBoundary extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = { hasError: false, eventId: null };

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    // if no error occurs -> render as normal
    if (!this.state.hasError) {
      return this.props.children;
    }

    // if we have chosen to show a full-screen error page when an error occurs
    if (this.props.fallbackToErrorScreen) {
      return <GenericErrorPage />;
    }

    // if we have chosen to hide the component inside the error boundary when an error occurs
    if (this.props.fallbackToHidden) {
      return null;
    }

    // default behaviour for errors is to show a box with the message
    return (
      <Wrapper>
        <p>{this.props.errorMessage}</p>
      </Wrapper>
    );
  }
}

export default ErrorBoundary;

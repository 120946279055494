import flatMap from 'lodash/flatMap';
import React from 'react';
import Container from 'src/components/Container';
import AssetForm from 'src/components/Forms/AssetForm';
import { Review, UpdateReviewData } from 'src/ducks/review';

import { Anything } from '../../utils/globalTypes';
import { Wrapper, ContentWrapper, FormWrapper } from './ReviewQueueEditAsset.style';

type Props = {
  onSubmit(data: UpdateReviewData): void;
  closeOverlay(): void;
  asset: Review;
  getVideoDuration: () => number;
};

const ReviewQueueEditAsset: React.FC<Props> = ({
  closeOverlay,
  asset,
  onSubmit,
  getVideoDuration,
}) => {
  return (
    <Container centered>
      <Wrapper>
        <ContentWrapper>
          <FormWrapper>
            <AssetForm
              onSubmit={({ originalAssets, otherAssets }: Anything) => {
                const actions = flatMap([originalAssets, otherAssets]).map(
                  ({ option, action, matches = [] }) => ({
                    asset_id: option.value,
                    action: action.value,
                    matches,
                  })
                );
                onSubmit({ id: asset.id, video_id: asset.video.external_id, actions });
              }}
              triggerClose={closeOverlay}
              asset={asset}
              getVideoDuration={getVideoDuration}
            />
          </FormWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default ReviewQueueEditAsset;

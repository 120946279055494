import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';
import { withSearchReducer } from 'src/ducks/factories/search';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_REVIEWS_FAILURE,
  REMOVE_REVIEW,
  ReviewActionTypes,
  ReviewReducer,
  SET_REVIEWS,
  SET_REVIEWSCOUNT,
  SHOW_UNDO_BUTTON,
  UNDO_ACTION,
} from './types';

const initialState: ReviewReducer = {
  list: [],
  countItems: 0,
  showUndoButton: false,
};

function review(state = initialState, action: ReviewActionTypes): ReviewReducer {
  switch (action.type) {
    case SET_REVIEWS:
      return {
        ...state,
        list: action.payload,
      };
    case SET_REVIEWSCOUNT:
      return {
        ...state,
        //TODO: REVERT TO action.payload WHEN API IS FIXED
        countItems: action.payload >= 0 ? action.payload : null,
      };
    case FETCH_REVIEWS_FAILURE:
      return {
        ...state,
        list: [],
      };
    case REMOVE_REVIEW:
      return {
        ...state,
        //TODO: REVERT TO state.countItems - 1 WHEN API IS FIXED
        countItems: state.countItems ? state.countItems - 1 : null,
        list: state.list.filter((o) => o.id !== action.payload),
      };
    case SHOW_UNDO_BUTTON:
      return {
        ...state,
        showUndoButton: true,
      };
    case UNDO_ACTION:
      return {
        ...state,
        showUndoButton: false,
      };
    default:
      return state;
  }
}

export default compose(
  withPaginationReducer<ReviewReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withLoadingReducer<ReviewReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withSearchReducer<ReviewReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(review);

import { createSelector } from 'reselect';
import { AppState } from 'src/ducks';
import { Overlays, Overlay } from 'src/ducks/ui/overlay/types';

export const __REDUX_STATE_KEY__ = 'overlays';
export const getReduxStateSlice = (state: AppState) => state.ui[__REDUX_STATE_KEY__];

// retrieves an ordered list of all the overlays
export const getOverlays = createSelector(
  (state: AppState) => getReduxStateSlice(state),
  (overlayState: Overlays) =>
    overlayState.keys.map((id: string): Overlay => {
      const overlay: Overlay = overlayState.byKeyId[id];
      return {
        id,
        type: overlay.type,
        props: overlay.props,
      };
    })
);

import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { SET_PAGINATION_PAGE, SetPaginationPageAction } from 'src/ducks/factories/pagination/types';
import {
  CHANGE_FILTERS,
  CHANGE_SORT,
  ChangeFilterAction,
  ChangeSortAction,
  FETCH_FILTERS,
  FETCH_FILTERS_SUCCESS,
  FetchFiltersSuccessAction,
  getFilterOptions,
  getSelectedStrategy,
  getSort,
} from 'src/ducks/filters';
import { handleParams } from 'src/ducks/filters/utils';
import { getGlobalSearch, SET_GLOBAL_SEARCH, SetGlobalSearchAction } from 'src/ducks/globalSearch';
import {
  fetchKeywordManagementsCountRequest,
  fetchKeywordManagementsFail,
  fetchKeywordManagementsRequest,
  keywordManagementLoadingActions,
  keywordManagementPaginationActions,
  setKeywordManagements,
  setKeywordManagementsCount,
  triggerKeywordManagementsActionSuccess,
} from 'src/ducks/keywordManagement/actions';
import { __REDUX_STATE_KEY__ } from 'src/ducks/keywordManagement/selectors';
import {
  FETCH_KEYWORD_MANAGEMENT_COUNT_REQUEST,
  FETCH_KEYWORD_MANAGEMENT_REQUEST,
  FetchKeywordManagementsCountRequestAction,
  FetchKeywordManagementsRequestAction,
  TRIGGER_KEYWORD_MANAGEMENT_ACTION,
  TriggerKeywordManagementsAction,
} from 'src/ducks/keywordManagement/types';
import { keywordManagementAPI } from 'src/providers';
import { GlobalReviewPaginationLimit, OTHER_PAGES } from 'src/utils/constants';

const triggerReactiveActionsBasedOnState = (state: AppState) => {
  const selected = getSelectedStrategy(state);
  if (selected?.value && OTHER_PAGES.KEYWORD_MANAGEMENT !== (selected?.value as string)) {
    return of();
  }
  const filters = getFilterOptions(state);
  const sort = getSort(state);
  const q = getGlobalSearch(state);
  const params = handleParams(filters, sort, selected, q, 1);
  return concat(
    of(keywordManagementLoadingActions.setLoading(true)),
    of(keywordManagementPaginationActions.setPaginationPage(1)),
    of(fetchKeywordManagementsCountRequest(params))
  );
};

const filtersSucceededEpic = (
  action$: ActionsObservable<
    FetchFiltersSuccessAction | ChangeFilterAction | ChangeSortAction | SetGlobalSearchAction
  >,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_FILTERS_SUCCESS, CHANGE_FILTERS, CHANGE_SORT, SET_GLOBAL_SEARCH),
    switchMap(() => {
      const state = state$.value;
      return triggerReactiveActionsBasedOnState(state);
    })
  );

const fetchKeywordManagementEpic = (
  action$: ActionsObservable<FetchKeywordManagementsRequestAction>
) =>
  action$.pipe(
    ofType(FETCH_KEYWORD_MANAGEMENT_REQUEST),
    switchMap(({ payload }) => {
      return concat(
        of(keywordManagementLoadingActions.setLoading(true)),
        from(keywordManagementAPI.bulk.get(payload).request()).pipe(
          mergeMap((data) => of(setKeywordManagements(data))),
          catchError(() => of(fetchKeywordManagementsFail()))
        ),
        of(keywordManagementLoadingActions.setLoading(false))
      );
    })
  );

const fetchKeywordManagementCountEpic = (
  action$: ActionsObservable<FetchKeywordManagementsCountRequestAction>
) =>
  action$.pipe(
    ofType(FETCH_KEYWORD_MANAGEMENT_COUNT_REQUEST),
    switchMap(({ payload }) =>
      from(
        keywordManagementAPI.bulk
          .getCount({
            ...payload,
          })
          .request()
      ).pipe(
        mergeMap((data) =>
          of(
            setKeywordManagementsCount(data),
            keywordManagementPaginationActions.setPaginationTotalPages(
              Math.ceil(data.count / GlobalReviewPaginationLimit)
            )
          )
        )
      )
    )
  );

const changePageEpic = (
  action$: ActionsObservable<SetPaginationPageAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REDUX_STATE_KEY__)),
    switchMap(({ payload }) => {
      const selected = getSelectedStrategy(state$.value);

      if (
        (selected?.value && OTHER_PAGES.KEYWORD_MANAGEMENT !== (selected?.value as string)) ||
        !selected
      ) {
        return of();
      }

      const filters = getFilterOptions(state$.value);
      const sort = getSort(state$.value);
      const q = getGlobalSearch(state$.value);
      const params = handleParams(filters, sort, selected, q, payload);

      return concat(
        of(keywordManagementLoadingActions.setLoading(true)),
        of(fetchKeywordManagementsRequest(params)),
        of(keywordManagementLoadingActions.setLoading(false))
      );
    })
  );

const resetPageData = (
  action$: ActionsObservable<SetPaginationPageAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_FILTERS),
    switchMap(() => {
      const selected = getSelectedStrategy(state$.value);
      if (
        (selected?.value && OTHER_PAGES.KEYWORD_MANAGEMENT !== (selected?.value as string)) ||
        !selected
      ) {
        return of();
      }

      return concat(of(setKeywordManagements([])));
    })
  );

const triggerActions = (action$: ActionsObservable<TriggerKeywordManagementsAction>) =>
  action$.pipe(
    ofType(TRIGGER_KEYWORD_MANAGEMENT_ACTION),
    switchMap(({ payload }) =>
      from(keywordManagementAPI.bulk.put(payload).request()).pipe(
        mergeMap(() => of(triggerKeywordManagementsActionSuccess(payload)))
      )
    )
  );

export default {
  filtersSucceededEpic,
  fetchKeywordManagementEpic,
  fetchKeywordManagementCountEpic,
  changePageEpic,
  resetPageData,
  triggerActions,
};

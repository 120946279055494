import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  ReviewHistoryReducer,
  ReviewHistoryActionTypes,
  SET_HISTORY_REVIEWS,
  FETCH_HISTORY_REVIEWS_FAILURE,
  FETCH_HISTORY_ACTIONS_SUCCESS,
} from './types';

const initialState: ReviewHistoryReducer = {
  list: [],
  actions: [],
};

function reviewHistory(
  state = initialState,
  action: ReviewHistoryActionTypes
): ReviewHistoryReducer {
  switch (action.type) {
    case SET_HISTORY_REVIEWS:
      return {
        ...state,
        list: action.payload,
      };
    case FETCH_HISTORY_REVIEWS_FAILURE:
      return {
        ...state,
        list: [],
      };
    case FETCH_HISTORY_ACTIONS_SUCCESS:
      return {
        ...state,
        actions: action.payload,
      };
    default:
      return state;
  }
}

export default compose(
  withLoadingReducer<ReviewHistoryReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withPaginationReducer<ReviewHistoryReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(reviewHistory);

import { NormalizedActionsOptions } from 'src/ducks/legacyFilters/types';
import { Timestamp } from 'src/utils/constants';
export const FETCH_HISTORY_REVIEWS_REQUEST = 'FETCH_HISTORY_REVIEWS_REQUEST';
export const SET_HISTORY_REVIEWS = 'SET_HISTORY_REVIEWS';
export const FETCH_HISTORY_REVIEWS_FAILURE = 'FETCH_HISTORY_REVIEWS_FAILURE';
export const FETCH_HISTORY_ACTIONS_REQUEST = 'FETCH_HISTORY_ACTIONS_REQUEST';
export const FETCH_HISTORY_ACTIONS_SUCCESS = 'FETCH_HISTORY_ACTIONS_SUCCESS';
export const FETCH_HISTORY_ACTIONS_FAILED = 'FETCH_HISTORY_ACTIONS_FAILED';
export const UPDATE_HISTORY_REVIEWS = 'UPDATE_HISTORY_REVIEWS';

export interface FetchHistoryRequestAction {
  type: typeof FETCH_HISTORY_ACTIONS_REQUEST;
}
export interface UpdateHistoryRequestAction {
  type: typeof UPDATE_HISTORY_REVIEWS;
  payload: ReviewHistoryUpdateData;
}
export interface FetchHistorySuccessAction {
  type: typeof FETCH_HISTORY_ACTIONS_SUCCESS;
  payload: NormalizedActionsOptions[];
}

export interface SetHistoryReviewsAction {
  type: typeof SET_HISTORY_REVIEWS;
  payload: ReviewHistory[];
}

export interface FetchHistoryReviewsRequestAction {
  type: typeof FETCH_HISTORY_REVIEWS_REQUEST;
  payload: Record<string, unknown>;
}

export interface FetchHistoryReviewsFailureAction {
  type: typeof FETCH_HISTORY_REVIEWS_FAILURE;
}

export type ReviewHistoryActionTypes =
  | UpdateHistoryRequestAction
  | FetchHistorySuccessAction
  | FetchHistoryRequestAction
  | SetHistoryReviewsAction
  | FetchHistoryReviewsRequestAction
  | FetchHistoryReviewsFailureAction;

export interface ReviewHistoryReducer {
  list: ReviewHistory[];
  actions: NormalizedActionsOptions[];
}

export interface Video {
  video_id: string;
  title: string;
  daily_views: number;
}

export interface Asset {
  id: string;
  title: string;
}

export interface Status {
  action: string;
  action_type: string;
  text: string;
}

export interface ReviewHistory {
  id: number;
  video: Video;
  asset: Asset;
  status: Status;
  performed_at: Date;
  matches: Timestamp[];
  can_change_status: boolean;
}

export interface ReviewHistoryUpdateData {
  formName?: string;
  video_id: string;
  action: string;
  asset_id: string;
  matches: Timestamp[];
}

export interface ReviewHistoryAsset {
  asset: Asset | null;
  can_change_status: boolean;
  id: number;
  matches: Timestamp[];
  performed_at: string;
  status: {
    action_type: string;
    action: string;
    text: string;
  };
  video: Video;
}

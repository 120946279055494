const urls = {
  login: () => '/login/',
  dashboard: () => '/dashboard/',
  review: () => '/review/',
  reviewQueue: () => `${urls.review()}assets/`,
  globalReview: () => `${urls.review()}global/`,
  reviewHistory: () => `${urls.review()}review-history/`,

  leadsGeneration: () => '/leads-generation/',

  claimList: () => '/claim-list/',

  analytics: () => '/analytics/',
  claimerAnalytics: () => `${urls.analytics()}claimer-analytics/`,
  claimAnalytics: () => `${urls.analytics()}claim-analytics/`,
  savedForLater: () => '/saved-for-later/',
  systemManagement: () => '/system-management/',
  assetsManagement: () => `${urls.systemManagement()}assets-management/`,
  channelsManagement: () => `${urls.systemManagement()}channels-management/`,
  keywordManagement: () => `${urls.systemManagement()}keyword-management/`,
  legacyClaims: () => `${urls.systemManagement()}legacy-claims/`,
  keywordStrategy: () => '/keyword-strategy/',
  channelStrategy: () => '/channel-strategy/',
  confirmedMatches: () => '/confirmed-matches/',
};

export default urls;

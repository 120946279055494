import { simpleAction } from '../utils';
import {
  User,
  LoginUser,
  FETCH_USER_REQUEST,
  LOGIN_USER_REQUEST,
  SET_USER_TOKEN,
  SET_USER,
  UNSET_USER,
  SetUserInfoAction,
  UnsetUserInfoAction,
  FetchUserInfoAction,
  LoginUserAction,
  SetUserTokenAction,
  UpdateUserSelectedAccount,
  UPDATE_USER_SELECTED_ACCOUNT,
  SET_ABILITY,
  SetUserAbilityAction,
  Ability,
} from './types';

/* We needed a way to add the headers the moment the app boots
if a token exists. The reducer file where `authenticated` state is initialized was an option but i
ended up putting it here because it belongs to an initial async configuration.
 */
export const setUserToken = (token: string): SetUserTokenAction =>
  simpleAction(SET_USER_TOKEN, token);

export const setUserInfo = (payload: User): SetUserInfoAction => simpleAction(SET_USER, payload);

export const setUserAbility = (payload: Ability): SetUserAbilityAction =>
  simpleAction(SET_ABILITY, payload);

export const updateUserSelectedAccount = (accountId: string): UpdateUserSelectedAccount =>
  simpleAction(UPDATE_USER_SELECTED_ACCOUNT, accountId);

export const unsetUserInfo = (): UnsetUserInfoAction => simpleAction(UNSET_USER);

export const fetchUserRequest = (): FetchUserInfoAction => simpleAction(FETCH_USER_REQUEST);

export const loginUserRequest = (args: LoginUser, formName: string): LoginUserAction =>
  simpleAction(LOGIN_USER_REQUEST, { ...args, formName });

import { createSelector } from 'reselect';
import { AppState } from 'src/ducks';
import { Modals, Modal } from 'src/ducks/ui/modal/types';

export const __REDUX_STATE_KEY__ = 'modals';
export const getReduxStateSlice = (state: AppState) => state.ui[__REDUX_STATE_KEY__];

// retrieves an ordered list of all the modals
export const getModals = createSelector(
  (state: AppState) => getReduxStateSlice(state),
  (modalState: Modals) =>
    modalState.keys.map((id: string): Modal => {
      const modal: Modal = modalState.byKeyId[id];
      return {
        id,
        type: modal.type,
        props: modal.props,
      };
    })
);

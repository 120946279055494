import { METHODS, request } from 'src/providers/axiosInstances';
import { PagesType } from 'src/utils/constants';
import { Anything } from 'src/utils/globalTypes';

export default {
  download: (url = '', onDownloadProgress?: (e: number, total: number) => void) =>
    request(
      METHODS.GET,
      url,
      { responseType: 'blob' },
      true,
      {},
      (progressEvent: Anything) => {
        const total = progressEvent?.total;
        const current = progressEvent?.loaded;

        const percentCompleted = Math.floor((current / total) * 100);
        //This is because when failing this calculates the size of the response and there isn't a way to
        // know whe the call has failed or has succeeded here
        if (onDownloadProgress) {
          onDownloadProgress(percentCompleted, total);
        }
      },
      undefined,
      //TODO: Check this in different file types. Already Checked for: zip
      'arraybuffer'
    ),
  downloadS3: (url = '') => request(METHODS.GET, url, { responseType: 'blob' }, true),
  upload: (params = {}) => request(METHODS.POST, '/upload/', { params }, false),
  getPresigned: (key?: string, bucket?: string) =>
    request(
      METHODS.GET,
      `/sign-s3/${key && bucket ? '?key=' + key + '&bucket=' + bucket : ''}`,
      false
    ),
  uploadS3: (
    url = '',
    params: { file: File; name: string },
    onUploadProgress?: (e: number) => void
  ) =>
    request(
      METHODS.PUT,
      url,
      { params: params.file },
      true,
      {
        'content-type': params.file.type,
        contentDisposition: 'attachment;',
      },
      undefined,
      onUploadProgress
        ? (progressEvent: Anything) => {
            const total = progressEvent?.total;
            const current = progressEvent?.loaded;

            const percentCompleted = Math.floor((current / total) * 100);
            //This is because when failing this calculates the size of the response and there isn't a way to
            // know whe the call has failed or has succeeded here
            onUploadProgress(percentCompleted);
          }
        : undefined,
      //TODO: Check this in different file types. Already Checked for: zip
      'arraybuffer'
    ),
  getProgress: (id: number, params = {}) => request(METHODS.GET, `/jobs/${id}/`, { params }),
  getURL: (page: PagesType, params = {}) => request(METHODS.GET, `/${page}/export/`, { params }),
};

import queryString from 'query-string';
import { Middleware } from 'redux';
import { resetFilter } from 'src/ducks/legacyFilters';
import { UNSET_USER, UPDATE_USER_SELECTED_ACCOUNT } from 'src/ducks/user';
import { setAxiosAccount, deleteAxiosHeaders } from 'src/providers/axiosInstances';
import { __ACCOUNT__, __TOKEN__ } from 'src/utils/constants';
import cookies from 'src/utils/cookies';

export const customHeaderMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case UPDATE_USER_SELECTED_ACCOUNT: {
        setTimeout(() => {
          // getting and setting the account id as a cookie and reload the page to get all the new data based on that account
          // this is getting place inside a setTimeout so it can be handled in a different web thread and to have our reducers set
          const accountId = parseInt(action.payload);
          cookies.set(__ACCOUNT__, accountId);
          setAxiosAccount(accountId);
          window.location.reload();
        }, 0);
        break;
      }
      case UNSET_USER: {
        deleteAxiosHeaders();
        dispatch(resetFilter());
        cookies.remove(__ACCOUNT__);
        cookies.remove(__TOKEN__);
        break;
      }
      case 'persist/REHYDRATE': {
        const queryParams = queryString.parse(window.location.search) as {
          [key: string]: string;
        };
        const selectedAccountId = queryParams.userAccount;

        if (selectedAccountId) {
          cookies.set(__ACCOUNT__, selectedAccountId);
          setAxiosAccount(parseInt(selectedAccountId));
        }
        break;
      }
      default:
        break;
    }
    return next(action);
  };

export default customHeaderMiddleware;

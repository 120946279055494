import { withLoadingActions } from 'src/ducks/factories/loading';
import { simpleAction } from 'src/ducks/utils';
import { PagesType } from 'src/utils/constants';

import { __REDUX_STATE_KEY__ } from './selectors';
import * as types from './types';
import { FiltersState } from './types';

export const fetchFilters = (
  strategy: PagesType,
  loading?: boolean | undefined
): types.FetchFiltersAction => simpleAction(types.FETCH_LEGACY_FILTERS, { strategy, loading });

export const fetchFiltersSuccess = (payload: FiltersState): types.FetchFiltersSuccessAction =>
  simpleAction(types.FETCH_LEGACY_FILTERS_SUCCESS, payload);

export const changeStrategyFilter = (
  payload: types.NormalizedOptions,
  changedKey: string,
  strategy: string
): types.ChangeFilterAction =>
  simpleAction(types.CHANGE_LEGACY_FILTERS, { options: payload, changedKey, strategy });

export const changeDynamicFilter = (
  payload: types.DynamicFilterPayload
): types.ChangeDynamicFilterAction => simpleAction(types.CHANGE_DYNAMIC_FILTER, payload);

export const fetchFiltersFail = (): types.FetchFiltersFailureAction =>
  simpleAction(types.FETCH_LEGACY_FILTERS_FAILURE);

export const resetFilter = (): types.ResetFilterAction => simpleAction(types.RESET_LEGACY_FILTERS);

export const filterLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

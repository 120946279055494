import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';
import { withSearchReducer } from 'src/ducks/factories/search';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_GLOBAL_REVIEWS_COUNT_FAILURE,
  FETCH_GLOBAL_REVIEWS_FAILURE,
  GlobalReviewActionTypes,
  GlobalReviewReducer,
  REMOVE_GLOBAL_REVIEWS,
  SET_GLOBAL_REVIEWS,
  SET_GLOBAL_REVIEWS_COUNT,
} from './types';

const initialState: GlobalReviewReducer = {
  list: [],
  countItems: 0,
  estimate: false,
  countIsLoading: false,
  isError: false,
  countIsError: false,
};

function confirmedMatch(
  state = initialState,
  action: GlobalReviewActionTypes
): GlobalReviewReducer {
  switch (action.type) {
    case SET_GLOBAL_REVIEWS:
      return {
        ...state,
        list: action.payload,
        isError: false,
      };
    case REMOVE_GLOBAL_REVIEWS:
      return {
        ...state,
        list: state.list.filter((item) => action.payload.indexOf(item.video_id) === -1),
        countItems: state.countItems - action.payload.length,
      };
    case SET_GLOBAL_REVIEWS_COUNT:
      return {
        ...state,
        countItems: action.payload.count,
        estimate: action.payload.estimate,
        countIsLoading: action.payload.isLoading,
        countIsError: action.payload?.isError || false,
      };
    case FETCH_GLOBAL_REVIEWS_FAILURE:
      return {
        ...state,
        isError: true,
        countItems: 0,
        list: [],
      };
    case FETCH_GLOBAL_REVIEWS_COUNT_FAILURE:
      return {
        ...state,
        countIsLoading: false,
        countIsError: true,
      };
    default:
      return state;
  }
}

export default compose(
  withSearchReducer<GlobalReviewReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withPaginationReducer<GlobalReviewReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withLoadingReducer<GlobalReviewReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(confirmedMatch);

import some from 'lodash/some';
import unionBy from 'lodash/unionBy';
import queryString from 'query-string';
import { REHYDRATE } from 'src/typings/redux-persist-custom';

import { User } from './types';
import {
  SET_USER,
  SET_USER_TOKEN,
  UNSET_USER,
  UPDATE_USER_SELECTED_ACCOUNT,
  UserActionTypes,
  Account,
  SET_ABILITY,
} from './types';

const accountsHaveSelected = (arr: Account[]) => some(arr, (account: Account) => account.selected);

const initialState: User = {
  id: null,
  name: '',
  email: '',
  accounts: [],
  account: '',
  groups: [],
};

function userInfo(state = initialState, action: UserActionTypes): User {
  switch (action.type) {
    case SET_USER_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_USER: {
      const normalizedAccount = action.payload.accounts
        .filter(({ is_record_label }) => is_record_label !== null)
        .map((account, i) => ({
          ...account,
          selected: accountsHaveSelected(action.payload.accounts)
            ? account.name === action.payload.account
            : i === 0,
        }));

      return {
        ...state,
        ...action.payload,
        // here we are keeping any format of accounts that we have and update anything that comes form the server
        accounts: unionBy(state.accounts, normalizedAccount, 'id'),
      };
    }
    case UPDATE_USER_SELECTED_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.map((account) => ({
          ...account,
          selected: account.id === parseInt(action.payload),
        })),
      };
    case UNSET_USER:
      return initialState;
    case SET_ABILITY:
      return {
        ...state,
        ability: action.payload,
      };
    case REHYDRATE: {
      const queryParams = queryString.parse(window.location.search) as {
        [key: string]: string;
      };
      const selectedAccountId = queryParams.userAccount;

      return {
        ...state,
        ...(action.payload ? action.payload?.user : {}),
        accounts: selectedAccountId
          ? action.payload?.user?.accounts.map((account) => ({
              ...account,
              selected: account.id === parseInt(selectedAccountId),
            })) || []
          : action.payload?.user?.accounts || [],
      };
    }
    default:
      return state;
  }
}

export default userInfo;

import { Anything } from '../../utils/globalTypes';

export const FETCH_CONFIRMED_MATCHES_REQUEST = 'FETCH_CONFIRMED_MATCHES_REQUEST';
export const SET_CONFIRMED_MATCHES = 'SET_CONFIRMED_MATCHES';
export const FETCH_CONFIRMED_MATCHES_FAILURE = 'FETCH_CONFIRMED_MATCHES_FAILURE';
export const FETCH_CONFIRMED_MATCHES_COUNT_REQUEST = 'FETCH_CONFIRMED_MATCHES_COUNT_REQUEST';
export const SET_CONFIRMED_MATCHES_COUNT = 'SET_CONFIRMED_MATCHES_COUNT';
export const RESET_REVIEW_PAGINATION = 'RESET_REVIEW_PAGINATION';
export const HIDE_CONFIRMED_MATCHES_REQUEST = 'HIDE_CONFIRMED_MATCHES_REQUEST';
export const REMOVE_CONFIRMED_MATCH = 'REMOVE_CONFIRMED_MATCH';
export const CONFIRMED_DOWNLOAD_ID = 'CONFIRMED_DOWNLOAD_ID';

export interface RemoveConfirmedMatchAction {
  type: typeof REMOVE_CONFIRMED_MATCH;
  payload: number[];
}

export interface SetConfirmedMatchesAction {
  type: typeof SET_CONFIRMED_MATCHES;
  payload: ConfirmedMatch[];
}

export interface SetConfirmedMatchesCountAction {
  type: typeof SET_CONFIRMED_MATCHES_COUNT;
  payload: number;
}

export interface FetchConfirmedMatchesRequestAction {
  type: typeof FETCH_CONFIRMED_MATCHES_REQUEST;
  payload: ConfirmedMatchesParams;
}

export interface HideConfirmedMatchesRequestAction {
  type: typeof HIDE_CONFIRMED_MATCHES_REQUEST;
  payload: number[];
}

export type ConfirmedMatchesParams = {
  [key: string]: Anything;
  page: number;
  queue: number;
  sort_by: string;
  strategy: string;
};

export interface FetchConfirmedMatchesCountRequestAction {
  type: typeof FETCH_CONFIRMED_MATCHES_COUNT_REQUEST;
  payload: ConfirmedMatchesParams;
}

export interface FetchConfirmedMatchesFailureAction {
  type: typeof FETCH_CONFIRMED_MATCHES_FAILURE;
}

export interface ResetReviewPagination {
  type: typeof RESET_REVIEW_PAGINATION;
}

export type ReviewActionTypes =
  | ResetReviewPagination
  | FetchConfirmedMatchesFailureAction
  | SetConfirmedMatchesCountAction
  | SetConfirmedMatchesAction
  | FetchConfirmedMatchesRequestAction
  | RemoveConfirmedMatchAction
  | HideConfirmedMatchesRequestAction;

export interface ConfirmedMatchesReducer {
  list: ConfirmedMatch[];
  countItems: number;
}

export type confirmed_match = {
  matched_from: number | string;
  matched_to: number | string;
};

export type ConfirmedMatch = {
  id: number;
  video: {
    service: string;
    external_id: string;
    uploaded: string;
    title: string;
    description: string;
    total_views: number;
    daily_views: number;
    channel: {
      id: string;
      title: string;
    };
    reason: {
      value: string;
      text: string;
    };
  };
  assigned_to: {
    id: null | number;
    display_name: string;
  };
  confirmed_matches: confirmed_match[];
  type: {
    text: string;
    value: string;
  };
  reviewed_at: string;
  asset: {
    confirmed_total?: string;
    id: string;
    asset_id: string;
    title: string;
    review_date: string;
    reference_url: string;
    policy: string;
    status: string;
  };
};

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';

export const __REDUX_STATE_KEY__ = 'legacyFilters';
export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getFilters = (state: AppState) => getReduxStateSlice(state);

export const getSelectedStrategy = (state: AppState) =>
  getReduxStateSlice(state).filters.find((filter) => filter.selected)?.strategy;

export const getFilterActions = (state: AppState) => getReduxStateSlice(state).actions;

export const filterLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';
import { withPaginationSelectors } from 'src/ducks/factories/pagination';
import { withSearchSelectors } from 'src/ducks/factories/search';

export const __REDUX_STATE_KEY__ = 'review';
export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getReviews = (state: AppState) => getReduxStateSlice(state).list;
export const getReviewsCount = (state: AppState) => getReduxStateSlice(state).countItems;
export const getShowUndoButton = (state: AppState) => getReduxStateSlice(state).showUndoButton;

export const reviewLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

export const reviewPaginationSelectors = withPaginationSelectors(__REDUX_STATE_KEY__);

export const searchSelectors = withSearchSelectors(__REDUX_STATE_KEY__);

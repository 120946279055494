export const SET_PAGINATION_PAGE = (prefix = '') => `${prefix}_SET_PAGINATION_PAGE`;
export const RESET_PAGINATION = (prefix = '') => `${prefix}_RESET_PAGINATION`;
export const SET_PAGINATION_TOTAL_PAGES = (prefix = '') => `${prefix}_SET_PAGINATION_TOTAL_PAGES`;

export type ResetPaginationAction = {
  type: string;
  payload: Record<string, unknown>;
};

export type SetPaginationPageAction = {
  type: string;
  payload: number;
};

export type SetPaginationTotalPagesAction = {
  type: string;
  payload: number;
};

export type PaginationActionTypes =
  | SetPaginationTotalPagesAction
  | ResetPaginationAction
  | SetPaginationPageAction;

export type Pagination = {
  totalPages: null | number;
  page: number;
};

import React, { useRef } from 'react';
import { Form, InjectedFormProps, Field } from 'redux-form';
import Button from 'src/components/Button';
import InlineAlert from 'src/components/InlineAlert';
import YoutubeEmbed from 'src/components/YoutubeEmbed';
import { NormalizedActionsOptions } from 'src/ducks/legacyFilters';
import { ReviewHistoryAsset } from 'src/ducks/reviewHistory';
import { ButtonStatus } from 'src/utils/constants';
import { Timestamp } from 'src/utils/constants';

import {
  Wrapper,
  Heading,
  VideoContainer,
  YoutubeWrapper,
  DetailItem,
  ButtonWrapper,
} from '../AssetForm/AssetForm.style';
import { AssetEditedRow } from '../AssetForm/components';
import { FieldArrayRowAssetItem } from '../AssetForm/components/AssetEditedRow/AssetEditedRow';

export type Props = {
  actions?: NormalizedActionsOptions[];
  triggerClose?(): void;
  asset: ReviewHistoryAsset;
};

type FormAsset = {
  option: {
    value: string;
    reference_url: string;
  };
  action: { type?: string; value: string; label: string } | undefined;
  matches: Timestamp[];
};

export type FormArgs = {
  getVideoDuration: () => number;
  otherAssets: FormAsset[];
  ytDuration: number;
};

const ReviewHistoryForm: React.FC<Props & InjectedFormProps<FormArgs, Props>> = ({
  handleSubmit,
  triggerClose = () => {},
  asset,
  pristine,
  actions,
  submitting,
  valid,
  submitFailed,
  error,
  change,
}) => {
  const {
    video: { title: videoTitle, video_id, daily_views },
  } = asset;

  const videoRef = useRef({ getDuration: () => 0, getCurrentTime: () => 0 });

  // trigger an onClose if provided
  const onClose = (e: React.MouseEvent) => {
    e.preventDefault();
    triggerClose();
  };

  const ytReady = () => {
    change('ytDuration', videoRef.current.getDuration());
  };

  return (
    <Form onSubmit={handleSubmit} data-testid="history-form">
      <Wrapper>
        {error && submitFailed && <InlineAlert type="error">{error}</InlineAlert>}
        <VideoContainer>
          <YoutubeWrapper>
            <YoutubeEmbed
              ref={videoRef}
              width={360}
              height={220}
              videoId={video_id}
              onReady={ytReady}
            />
            <Field type="text" hidden component={'input'} name="ytDuration" />
          </YoutubeWrapper>
          <div>
            <Heading dangerouslySetInnerHTML={{ __html: videoTitle }} />
            <DetailItem>
              <span>Daily Views</span>
              <span>{daily_views.toLocaleString()}</span>
            </DetailItem>
          </div>
        </VideoContainer>

        <FieldArrayRowAssetItem
          name="otherAssets"
          title="Reviewed Asset(s)"
          component={AssetEditedRow}
          autocomplete
          actions={actions}
          videoRef={videoRef}
          actionsWithRejectOption
          rerenderOnEveryChange
        />

        <ButtonWrapper>
          <Button onClick={onClose} status={ButtonStatus.Danger} disabled={submitting}>
            Cancel Review
          </Button>
          <Button
            status={ButtonStatus.Success}
            disabled={!valid || pristine || submitting}
            loading={submitting}
          >
            Submit Review
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Form>
  );
};

export default ReviewHistoryForm;

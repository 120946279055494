import React from 'react';

import { Wrapper, H1, H2 } from './GenericErrorPage.style';

function GenericErrorPage() {
  return (
    <Wrapper>
      <H1>The app reached an unknown state</H1>
      <H2>{" This is a crash & it's a programming issue "}</H2>
    </Wrapper>
  );
}

export default GenericErrorPage;

import { SimpleAction } from 'src/ducks/utils';

export const ADD_ALERT = 'ADD_ALERT';
export const DELETE_ALERT = 'DELETE_ALERT';

// define the error type so we can use them when we define alert or actions etc
export const ERROR_TYPE = 'error';
export const SUCCESS_TYPE = 'success';

export type AlertProps = {
  type: typeof ERROR_TYPE | typeof SUCCESS_TYPE;
  messages: string[];
};

export type ActionTypeAdd = SimpleAction<typeof ADD_ALERT, { type: string; props: AlertProps }>;
export type ActionTypeDelete = SimpleAction<typeof DELETE_ALERT, { alertId: string | null }>;

export type AlertAction = ActionTypeAdd | ActionTypeDelete;

export type Alert = {
  id?: string;
  type: string;
  props?: AlertProps;
};

export type Alerts = {
  byKeyId: {
    [key: string]: Alert;
  };
  keys: string[];
};

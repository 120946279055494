import * as Sentry from '@sentry/react';
import { createStore as createReduxStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistStore, persistReducer } from 'redux-persist';
import createExpirationTransform from 'redux-persist-transform-expire';
import storage from 'redux-persist/lib/storage';
import { __REDUX_STATE_KEY__ as __FILTERS_REDUX_STATE_KEY__ } from 'src/ducks/filters';
import { __REDUX_STATE_KEY__ as __LEGACY_FILTERS_REDUX_STATE_KEY__ } from 'src/ducks/legacyFilters';
import { __REDUX_STATE_KEY__ as __USER_REDUX_STATE_KEY__ } from 'src/ducks/user';
import { sentryMiddleware, customHeaderMiddleware, alertsMiddleware } from 'src/middlewares';

import reducers, { rootEpic } from './ducks';

const expireTransform = createExpirationTransform({
  expireKey: '_expiresAt',
  defaultState: {},
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    __USER_REDUX_STATE_KEY__,
    __FILTERS_REDUX_STATE_KEY__,
    __LEGACY_FILTERS_REDUX_STATE_KEY__,
  ],
  transforms: [expireTransform],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// we export them in order to be used in our test utils
export const storeMiddlewares = [customHeaderMiddleware, alertsMiddleware, sentryMiddleware];

// a Generic function that returns a new store configured with the reducers & middlewares
export const createStore = (initialState = {}) => {
  // the middleware for the epics
  const epicMiddleware = createEpicMiddleware();

  // create the redux store
  const storeInstance = createReduxStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...storeMiddlewares, epicMiddleware), sentryReduxEnhancer)
  );

  // run the epics in order to start piping the actions
  epicMiddleware.run(rootEpic);

  return storeInstance;
};

// the actual front-end store
const store = createStore();

export const persistor = persistStore(store);

// if (process.env.NODE_ENV === 'development') {
//     window.store = store;
// }
export default store;

import { device } from 'src/theme/globals';

import * as types from './types';

const initialState: types.Sidebar = {
  open: window.matchMedia(device.laptop).matches,
};

function sidebar(state = initialState, action: types.SidebarAction): types.Sidebar {
  switch (action.type) {
    case types.OPEN_SIDEBAR:
      return {
        ...state,
        open: true,
      };
    case types.CLOSE_SIDEBAR:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}

export default sidebar;

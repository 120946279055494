import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme } from '@orfium/ictinus';
import { rem } from 'polished';

import { ERROR, StatusTypes, WARNING } from '../../../../types';

export const LoaderLine = styled.div`
  position: absolute;
  opacity: 0.4;
  background: ${({ theme }) => theme.utils.getColor('lightGrey', 50)};
  width: 150%;
  height: ${rem(8)};
`;

export const LoaderSubLine = (color: string) => css`
  position: absolute;
  background: ${color};
  height: ${rem(8)};
`;

export const LoaderDecLine = styled.div`
  ${({ theme }) => LoaderSubLine(theme.utils.getColor('primary', 400, 'normal'))};

  animation: decrease 2.3s 0.8s infinite;
  @keyframes decrease {
    from {
      left: -80%;
      width: 80%;
    }
    to {
      left: 110%;
      width: 10%;
    }
  }
`;

export const LoaderIncLine = styled.div`
  ${({ theme }) => LoaderSubLine(theme.utils.getColor('primary', 400, 'normal'))};

  animation: increase 2.3s infinite;
  @keyframes increase {
    from {
      left: -5%;
      width: 5%;
    }
    to {
      left: 130%;
      width: 100%;
    }
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${rem(8)};
  overflow-x: hidden;
  border-radius: ${rem(8)};
`;

const colorPicker = (theme: Theme, type: StatusTypes) => {
  switch (type) {
    case ERROR:
      return theme.utils.getColor('red', 400, 'flat');
    case WARNING:
      return theme.utils.getColor('yellow', 400, 'flat');
    default:
      return theme.utils.getColor('darkBlue', 500, 'flat');
  }
};

export const Progress = styled.progress<{ type: StatusTypes }>`
  width: 100%;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-size: ${rem(8)};
  color: ${({ theme, type }) => colorPicker(theme, type)};
  background: ${({ theme }) => theme.utils.getColor('lightGrey', 50)};

  &::-webkit-progress-bar {
    background-color: transparent;
  }
  /* Determinate */
  &::-webkit-progress-value {
    background-color: currentColor;
    border-radius: ${rem(8)};
    transition: all 0.2s;
  }
  &::-moz-progress-bar {
    background-color: currentColor;
    transition: all 0.2s;
    border-radius: ${rem(8)};
  }
  &::-ms-fill {
    border: none;
    background-color: currentColor;
    transition: all 0.2s;
    border-radius: ${rem(8)};
  }
`;

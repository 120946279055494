import { Anything } from '../../utils/globalTypes';

export const FETCH_CHANNELS_REQUEST = 'FETCH_CHANNELS_REQUEST';
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE';
export const SET_CHANNELS = 'SET_CHANNELS';
export const UPDATE_CHANNELS = 'UPDATE_CHANNELS';
export const RESET_CHANNELS_PAGINATION = 'RESET_CHANNELS_PAGINATION';
export const SET_SELECTED_CHANNELS = 'SET_SELECTED_CHANNELS';
export const UPDATE_SELECTED_CHANNELS = 'UPDATE_SELECTED_CHANNELS';
export const FETCH_CHANNELS_COUNT_REQUEST = 'FETCH_CHANNELS_COUNT_REQUEST';
export const SET_CHANNELS_COUNT = 'SET_CHANNELS_COUNT';
export const CHANNELS_DOWNLOAD_ID = 'CHANNELS_DOWNLOAD_ID';

export interface FetchChannelsRequestAction {
  type: typeof FETCH_CHANNELS_REQUEST;
  payload: ChannelsManagementParams;
}

export interface FetchChannelsRequestFailureAction {
  type: typeof FETCH_CHANNELS_FAILURE;
}

export interface FetchChannelsCountRequestAction {
  type: typeof FETCH_CHANNELS_COUNT_REQUEST;
  payload: ChannelsManagementParams;
}

export interface SetChannelsCountAction {
  type: typeof SET_CHANNELS_COUNT;
  payload: number;
}

export interface SetChannelsAction {
  type: typeof SET_CHANNELS;
  payload: FetchChannelsResponseType;
}

export interface UpdateChannelsAction {
  type: typeof UPDATE_CHANNELS;
  payload: UpdateChannelsType;
}

export interface ResetChannelsPagination {
  type: typeof RESET_CHANNELS_PAGINATION;
}

export interface SetSelectedChannelsAction {
  type: typeof SET_SELECTED_CHANNELS;
  payload: number[];
}

export interface UpdateSelectedChannelsAction {
  type: typeof UPDATE_SELECTED_CHANNELS;
  payload: number[];
}

export type ChannelsActionTypes =
  | FetchChannelsRequestAction
  | FetchChannelsRequestFailureAction
  | SetChannelsAction
  | UpdateChannelsAction
  | ResetChannelsPagination
  | SetChannelsCountAction
  | FetchChannelsCountRequestAction
  | UpdateSelectedChannelsAction;

export type FetchChannelsResponseType = {
  [key: string]: Anything;
  previous: string;
  next: string;
  results: ChannelsType[];
};

export type ChannelsType = {
  id: number;
  external_id: string;
  title: string;
  subscribers: number;
  views: number;
  videos: number;
  enabled: boolean;
  favorite: boolean;
  last_checked: string;
  fetched_videos: number;
  in_review: number;
  claimed_videos: number;
  content_type: string | null;
};

export type UpdateChannelsType = {
  ids: number[];
  action: string;
};

export interface ChannelsManagementReducer {
  list: ChannelsType[];
  countItems: number;
  selected: number[];
}

export type ChannelsManagementParams = {
  [key: string]: Anything;
  page: number;
  queue: string | undefined;
};

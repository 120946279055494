import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { withSearchActions } from 'src/ducks/factories/search';

import { simpleAction } from '../utils';
import { __REDUX_STATE_KEY__ } from './selectors';
import {
  EnableUndoButton,
  FETCH_REVIEWS_FAILURE,
  FETCH_REVIEWS_REQUEST,
  FETCH_REVIEWSCOUNT_REQUEST,
  FetchReviewsFailureAction,
  FetchReviewsRequestAction,
  REMOVE_REVIEW,
  RemoveReviewAction,
  Review,
  ReviewParams,
  SET_REVIEWS,
  SET_REVIEWSCOUNT,
  SetReviewsAction,
  SHOW_UNDO_BUTTON,
  UNDO_ACTION,
  UndoAction,
  UPDATE_REVIEW_REQUEST,
  UpdateReviewData,
  UpdateReviewsRequestAction,
  FetchReviewsCountRequestAction,
  SetReviewsCountAction,
} from './types';

export const setReviews = (reviews: Review[]): SetReviewsAction =>
  simpleAction(SET_REVIEWS, reviews);

export const fetchReviewsRequest = (params: ReviewParams): FetchReviewsRequestAction =>
  simpleAction(FETCH_REVIEWS_REQUEST, params);

export const fetchReviewsFail = (): FetchReviewsFailureAction =>
  simpleAction(FETCH_REVIEWS_FAILURE);

export const updateReviewRequest = (data: UpdateReviewData): UpdateReviewsRequestAction =>
  simpleAction(UPDATE_REVIEW_REQUEST, data);

export const fetchReviewsCountRequest = (params: ReviewParams): FetchReviewsCountRequestAction =>
  simpleAction(FETCH_REVIEWSCOUNT_REQUEST, params);
//TODO: REVERT TYPE TO number ONLY WHEN API IS FIXED.
export const setReviewsCount = (data: number): SetReviewsCountAction =>
  simpleAction(SET_REVIEWSCOUNT, data);

export const removeReview = (assetId: number): RemoveReviewAction =>
  simpleAction(REMOVE_REVIEW, assetId);

export const enableUndoLastButton = (): EnableUndoButton => simpleAction(SHOW_UNDO_BUTTON);

export const undoLastAction = (payload: string): UndoAction => simpleAction(UNDO_ACTION, payload);

export const reviewLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const reviewPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

export const reviewSearchActions = withSearchActions(__REDUX_STATE_KEY__);

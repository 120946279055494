import { FilterParams } from '../filters';

export const FETCH_CLAIM_ANALYTICS_REQUEST = 'FETCH_CLAIM_ANALYTICS_REQUEST';
export const SET_CLAIM_ANALYTICS = 'SET_CLAIM_ANALYTICS';
export const FETCH_CLAIM_ANALYTICS_FAILURE = 'FETCH_CLAIM_ANALYTICS_FAILURE';

export type FetchClaimAnalyticsRequestAction = {
  type: typeof FETCH_CLAIM_ANALYTICS_REQUEST;
  payload: ClaimAnalyticsParams;
};

export type SetClaimAnalyticsAction = {
  type: typeof SET_CLAIM_ANALYTICS;
  payload: ClaimAnalyticsReducer;
};

export type FetchClaimAnalyticsFailAction = {
  type: typeof FETCH_CLAIM_ANALYTICS_FAILURE;
};

export type ClaimAnalyticsParams = FilterParams;

export type ClaimAnalyticsActionTypes =
  | FetchClaimAnalyticsRequestAction
  | SetClaimAnalyticsAction
  | FetchClaimAnalyticsFailAction;

export type ClaimAnalyticsReducer = {
  scale: ClaimAnalyticsScaleType;
  bars: ClaimAnalyticsBarType[];
  donuts: ClaimAnalyticsDonutType;
  last_updated: string;
};

export type ClaimAnalyticsScaleType = {
  min: number;
  max: number;
};

export type ClaimAnalyticsBarType = {
  name: string;
  views_int: number;
  claims_int: number;
  views_percentage: number;
  claims_percentage: number;
  owner_id: string | number;
};

export type ClaimAnalyticsDonutType = {
  claims: ClaimsType;
  views: ViewsType;
};

export type ClaimsType = {
  total_claims: number;
  orfium_claims_percentage: number;
  orfium_claims: number;
};

export type ViewsType = {
  total_views: number;
  orfium_views_percentage: number;
  orfium_views: number;
};

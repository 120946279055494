import styled from '@emotion/styled';
import ReactSelect from 'react-select';

import { Anything } from '../../utils/globalTypes';

// TODO import Props from react-select

type SelectContainerProps = { theming?: string };

type SelectBoxProps = { isDisabled?: boolean };

export const SelectContainer = styled.span<SelectContainerProps>`
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  position: relative;
  align-items: center;

  label {
    color: ${({ theming }) => (theming === 'dark' || theming === 'userMenu' ? 'white' : 'inherit')};
  }
`;

export const SelectBox = styled.span<SelectBoxProps>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  color: #ffffff;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'inherit')};
`;

export const SelectDark = styled(ReactSelect)<Anything>`
  .Select {
    &__control {
      border-color: transparent;
      color: transparent;
      background-color: #514c4a;
      border-radius: ${({ rounded }) => (rounded ? '23px' : '4px')};
      max-height: 46px;
      min-height: 46px;
      font-size: 11px;
      z-index: 1;
      outline: none;
      line-height: 1.09;
      border-color: transparent;
    }
    &__indicators {
      color: #4a4a4a;
      width: 10px;
      margin-right: 25px;
    }
    &__indicator-separator {
      display: none;
    }
    &__indicator {
      padding: 0 8px;
    }
    &__value-container {
      padding: 2px 0 2px 10px;
      color: #4a4a4a;
    }
    &__menu {
      color: #4a4a4a;
      font-size: 11px;
    }
    &__single-value {
      color: #f5f5f6;
      line-height: 140%;
    }
  }
`;

export const SelectUserMenu = styled(SelectDark)`
  .Select {
    &__control--menu-is-open {
      box-shadow: none;
      border-radius: 0;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }
    &__option--is-focused {
      background-color: #231f20;
    }
    &__menu {
      box-shadow: none;
      margin-top: 0;
      border-radius: 0;
      background-color: #514c4a;
      color: white;
      font-size: 11px;
    }
  }
`;

export const SelectLoading = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  z-index: 10;
  width: 20px;
  height: 20px;
  margin: auto;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Select = styled(ReactSelect)<Anything>`
  .Select {
    &__control {
      border-color: ${({ dark }) => (dark ? 'transparent' : 'initial')};
      color: ${({ dark }) => (dark ? '' : 'initial')};
      background-color: ${({ dark }) => (dark ? '#514c4a' : '#f5f5f6')};
      border-radius: ${({ rounded }) => (rounded ? '23px' : '4px')};
      max-height: 32px;
      min-height: 32px;
      font-size: 11px;
      z-index: 1;
      outline: none;
      line-height: 1.09;
      border-color: transparent;
      &--is-disabled {
        opacity: 0.5;
      }
    }
    &__indicators {
      color: ${({ dark }) => (dark ? '#4a4a4a' : '#231f20')};
      width: 10px;
      margin-right: 25px;
    }
    &__indicator-separator {
      display: none;
    }
    &__indicator {
      padding: 0 8px;
    }
    &__value-container {
      color: ${({ dark }) => (dark ? '#4a4a4a' : '#231f20')};
    }
    &__menu {
      color: ${({ dark }) => (dark ? '#4a4a4a' : '#231f20')};
      font-size: 11px;
      z-index: 5000;
    }
    &__single-value {
      color: ${({ dark }) => (dark ? '#f5f5f6' : '#231f20')};
      line-height: 140%;
    }
  }
`;

export const SelectGrey = styled(Select)<Anything>`
  .Select {
    &__control {
      background-color: white;
    }
  }
`;

import { AcceptedIconNames } from '@orfium/ictinus/dist/components/Icon/types';

import { ERROR, StatusTypes, WARNING } from '../../../../types';

type actionPickerReturnValue = {
  text: string;
  icon: AcceptedIconNames;
  color: string;
  onClick?: () => void;
};

export const actionPicker = (
  status: StatusTypes,
  progress?: number,
  onCancel?: () => void,
  onClear?: () => void,
  onRetry?: () => void
): actionPickerReturnValue => {
  const isNotDone = progress !== undefined && progress !== 100;

  switch (status) {
    case WARNING:
      return {
        text: 'Retry',
        icon: 'refresh',
        color: 'lightGrey-650',
        onClick: onRetry,
      };
    case ERROR:
      return {
        text: 'Clear',
        icon: 'error',
        color: 'lightGrey-650',
        onClick: onClear,
      };
    default:
      return {
        text: isNotDone ? 'Cancel' : 'Done',
        icon: isNotDone ? 'error' : 'success',
        color: isNotDone ? 'lightGrey-650' : 'lightBlue-500',
        onClick: isNotDone ? onCancel : () => {},
      };
  }
};

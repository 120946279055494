import isEqual from 'lodash/isEqual';
import { timestampIsValid } from 'src/utils/common';
import { defaultRejectOption } from 'src/utils/constants';

import { Anything } from '../../utils/globalTypes';
import { FormArgs } from './AssetForm/AssetForm';
import { FormArgs as FormHistoryArgs } from './ReviewHistoryForm/ReviewHistoryForm';

export const validateAssetForm = ({ originalAssets, otherAssets, getVideoDuration }: FormArgs) => {
  const errors: Anything = {};

  if (originalAssets && originalAssets.length > 0) {
    const otherAssetsHasEmpty = otherAssets && otherAssets.every(({ option: { value } }) => !value);
    const otherAssetsHaveValidTimestamps = otherAssets.every(({ matches = [] }) =>
      timestampIsValid(matches, getVideoDuration)
    );

    // check if some of the other asset hold any timestamp that is invalid
    if (!otherAssetsHaveValidTimestamps) {
      errors._error = 'Invalid timestamp range.';
    }

    // check if the original asset is not a rejected one
    if (
      originalAssets.some(
        ({ action }) => !isEqual(defaultRejectOption, action) && otherAssetsHasEmpty
      )
    ) {
      errors._error = 'Original asset must be rejected in order to select from other asset';
    }

    // check if the other asset is empty
    if (otherAssetsHasEmpty) {
      errors._error = 'Other asset cannot be empty';
    }
  }
  return errors;
};

export const validateReviewHistoryForm = ({ otherAssets, ytDuration }: FormHistoryArgs) => {
  const errors: Anything = {};

  if (otherAssets && otherAssets.length > 0) {
    const otherAssetsHasEmpty = otherAssets && otherAssets.every(({ option: { value } }) => !value);

    // check if some of the other asset hold any timestamp that is invalid
    //TODO Check for multiple other assets
    if (otherAssets[0].action && otherAssets[0].action.value !== defaultRejectOption.value) {
      if (
        otherAssets.some(
          ({ matches }) =>
            typeof matches !== 'undefined' &&
            typeof ytDuration !== 'undefined' &&
            !timestampIsValid(matches, () => ytDuration)
        )
      ) {
        errors._error = 'Invalid timestamp range.';
      }
    }
    // check if the original asset is not a rejected one
    if (
      otherAssets.some(({ action }) => !isEqual(defaultRejectOption, action) && otherAssetsHasEmpty)
    ) {
      errors._error = 'Original asset must be rejected in order to select from other asset';
    }

    // check if the other asset is empty
    if (otherAssetsHasEmpty) {
      errors._error = 'Other asset cannot be empty';
    }
  }
  return errors;
};

import { Claim, ClaimCountResponse } from 'src/ducks/claims';
import { ClaimExport, SummaryResponse } from 'src/providers/claimAPI/types';

import { METHODS, requestV3 } from '../axiosInstances';

export default {
  get: (params = {}) => requestV3<Claim[]>(METHODS.GET, `/claims/`, { params }),
  getCount: (params = {}) =>
    requestV3<ClaimCountResponse>(METHODS.GET, `/claims/count/`, { params }),
  exports: (params = {}) => requestV3<ClaimExport[]>(METHODS.GET, `/claims/export/`, { params }),
  summary: (params = {}) => requestV3<SummaryResponse>(METHODS.GET, `/claims/summary/`, { params }),
};

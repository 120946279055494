import omit from 'lodash/omit';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Loader from 'src/components/Loader';
import { youtubeEmbedURL, videoSizes } from 'src/utils/constants';

import { Wrapper } from './YoutubeEmbed.style';

type PlayerProgressProps = {
  playedSeconds: number;
  played: number;
  loadedSeconds: number;
  loaded: number;
};

type Props = {
  ref?: React.RefObject<{
    getCurrentTime: () => number;
    getDuration: () => number;
    seekTo?: (amount: number, type?: 'seconds' | 'fraction') => void;
  }>;
  playing?: string;
  setPlaying?(id: string): void;
  onReady?(): void;
  videoId: string;
  width?: number | string;
  height?: number | string;
  size?: string;
  onPlayerProgress?(state: PlayerProgressProps): void;
  autoplay?: boolean;
  showInfo?: boolean;
};

const getVideoSize = (width: number | string, height: number | string, size: string) => {
  if (size && videoSizes.has(size.toLowerCase())) {
    return videoSizes.get(size.toLowerCase());
  }

  return {
    width,
    height,
  };
};

const getFullVideoUrl = (videoId: string) => [youtubeEmbedURL, videoId].join('');

// Example <YoutubeEmbed videoId="Ind9-QncpYI" />
// You can pass playing parameter and setPlaying if you want to manage the player from outside
const YoutubeEmbed: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<Props> & React.RefAttributes<unknown>
> = forwardRef(
  (
    {
      videoId = '',
      autoplay = false,
      showInfo = true,
      playing = false,
      onPlayerProgress = () => {},
      setPlaying = () => {},
      onReady = () => {},
      width = 560,
      height = 315,
      size = '',
      ...rest
    },
    ref
  ) => {
    const [loading, setLoading] = useState(true);
    const videoRef = useRef<ReactPlayer>(null);
    const url = getFullVideoUrl(videoId);
    const calculatedSize = getVideoSize(width, height, size);

    useImperativeHandle(ref, () => ({
      // useRef for typescript can potentially be null this way we ensure this never happens
      getCurrentTime: videoRef && videoRef.current ? videoRef.current.getCurrentTime : () => 0,
      getDuration: videoRef && videoRef.current ? videoRef.current.getDuration : () => 0,
      seekTo: videoRef && videoRef.current ? videoRef.current.seekTo : () => null,
    }));

    if (!videoId || !calculatedSize) {
      return null;
    }

    const onProgress = (state: PlayerProgressProps) => {
      onPlayerProgress(state);
    };

    return (
      <Wrapper size={calculatedSize}>
        {loading && (
          <div style={{ position: 'absolute' }}>
            <Loader />
          </div>
        )}
        <ReactPlayer
          ref={videoRef}
          width={calculatedSize.width}
          height={calculatedSize.height}
          url={url}
          onReady={() => {
            onReady();
            setLoading(false);
          }}
          playing={playing === videoId}
          controls
          config={{
            youtube: {
              playerVars: { autoplay, showinfo: showInfo },
            },
          }}
          onProgress={onProgress}
          onPause={() => playing === videoId && setPlaying('')}
          onPlay={() => setPlaying(videoId)}
          {...omit(rest, ['ref'])}
        />
      </Wrapper>
    );
  }
);
YoutubeEmbed.displayName = 'YoutubeEmbed';

export default YoutubeEmbed;

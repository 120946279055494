import { DropzoneOptions } from 'react-dropzone';

export const UPLOAD = 'upload';
export const PROCESSING = 'processing';

export const INFO = 'info';
export const ERROR = 'error';
export const WARNING = 'warning';

export type StatusTypes = typeof INFO | typeof ERROR | typeof WARNING | typeof PROCESSING;

export type ProcessingFile = {
  id: string;
  name: string;
  size: string;
  status: StatusTypes;
  progress?: number;
  message?: string;
  isIntermediate?: boolean;
  onCancel: () => void;
  onRetry?: () => void;
  onClear?: () => void;
};

export type ProcessingItem = {
  id: string | number;
  title: string;
  info: string;
  files: ProcessingFile[];
  customContent?: JSX.Element;
  onClose: () => void;
  testDataId?: string;
};

export type UploadItem = {
  id: string | number;
  title: string;
  guidelines?: JSX.Element;
  onDownloadTemplate?: () => void;
  onClose: () => void;
  onSelected: (file: File[]) => void;
};

export type FileTransferItem =
  | {
      type: typeof UPLOAD;
      id: string | number;
      content: UploadItem;
      dropzoneConfig?: DropzoneOptions;
    }
  | {
      type: typeof PROCESSING;
      id: string | number;
      content: ProcessingItem;
    };

export type FileTransferContextProps = {
  items: FileTransferItem[];
  addItems: (item: FileTransferItem[]) => void;
  removeItems: (itemIds: (string | number)[]) => void;
  removeUploads: () => void;
  updateItem: (
    itemId: string | number,
    updater: ((i: FileTransferItem) => FileTransferItem) | FileTransferItem
  ) => void;
};

import flatMap from 'lodash/flatMap';
import intersection from 'lodash/intersection';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { FormErrors } from 'redux-form';

import { Anything } from './globalTypes';

/**
 * handles the API errors on a failed API POST/UPDATE and makes sure that the redux-form can have
 * an error thrown the way it expects it (in order to properly have the UI updated)
 *
 * @param {Object} errorData - The error that was catched in the promise `catch` during submission
 * @param {Object} fields - The fields defined on a possible form so we can try and see which errors from the response are associated to the fields
 * @returns {Object}
 */
export function handleFormErrors(
  { non_field_errors = {}, ...rest }: FormErrors<{ non_field_errors?: string[] }, Anything>,
  fields: Record<string, unknown> = {}
) {
  // fetching all the keys of the fields
  const fieldKeys = keys(fields);
  // fetching all the keys of the rest of the fields
  const pickedErrors = keys(rest);

  // check if we have keys that intersect with each other to see if we already have those keys on our form
  const intersectedKeys = intersection(fieldKeys, pickedErrors);

  // remove any intersected keys and define them as global because we dont have them as inputs/fields
  const omitedValues = flatMap(omit(rest, intersectedKeys));
  // show the intersected ones as field errors
  const pickedValues = pick(rest, intersectedKeys);

  return {
    _error: [...flatMap(non_field_errors), ...omitedValues],
    ...pickedValues,
  };
}

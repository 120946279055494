import React, { useCallback } from 'react';

import FileTransfer from './FileTransfer';
import { FileTransferContextProps, FileTransferItem, UPLOAD } from './types';

const FileTransferContext = React.createContext<FileTransferContextProps>({
  items: [],
  addItems: () => {},
  removeItems: () => {},
  removeUploads: () => {},
  updateItem: () => {},
});

FileTransferContext.displayName = 'FileTransferContext';

const FileTransferProvider: React.FC = ({ children }) => {
  const [items, setItems] = React.useState<FileTransferItem[]>([]);

  const addItems = (newItems: FileTransferItem[]) => {
    setItems((items) => {
      const newList = [...items];
      newItems.forEach((newItem) => {
        const found = items.find((item) => item.id === newItem.id);

        if (!found) {
          newList.push(newItem);
        }
      });
      return newList;
    });
  };

  const removeItems = (ids: (string | number)[]) => {
    setItems((items) => {
      let newList = [...items];
      ids.forEach((id) => {
        const found = items.findIndex((item) => item.id === id);
        if (found !== -1) {
          newList = [...items.slice(0, found), ...items.slice(found + 1)];
        }
      });

      return newList;
    });
  };

  const removeUploads = useCallback(
    () => setItems((items) => items.filter((item) => item.type !== UPLOAD)),
    []
  );

  const updateItem = (
    id: string | number,
    updater: ((i: FileTransferItem) => FileTransferItem) | FileTransferItem
  ) => {
    setItems((items) => {
      const found = items.find((item) => item.id === id);

      if (found) {
        const index = items.indexOf(found);

        const updated = typeof updater === 'function' ? updater(found) : updater;
        return [...items.slice(0, index), updated, ...items.slice(index + 1)];
      }

      return items;
    });
  };

  return (
    <FileTransferContext.Provider
      value={{ items, addItems, removeItems, removeUploads, updateItem }}
    >
      <FileTransfer />
      {children}
    </FileTransferContext.Provider>
  );
};

const useFileTransfer = () => React.useContext(FileTransferContext);

export { FileTransferProvider, useFileTransfer };

import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as IctinusThemeProvider } from '@orfium/ictinus';
import React from 'react';
import { hotjar } from 'react-hotjar';
import ReactPiwik from 'react-piwik';
import { connect } from 'react-redux';
import { Redirect, Router } from 'react-router-dom';
import Header from 'src/components/Header';
import { SelectedRowProvider } from 'src/components/ReviewTable/context/SelectedRowContext';
import Sidebar from 'src/components/Sidebar';
import { AppState } from 'src/ducks';
import { getUser, User } from 'src/ducks/user';
import { defaultTheme, theme } from 'src/theme/globals';
import urls from 'src/urls';
import { __TOKEN__ } from 'src/utils/constants';
import cookies from 'src/utils/cookies';

import { AppContentWrapper, AppMainWrapper } from './App.style';
import Container from './components/Container';
import { FileTransferProvider } from './components/FileTransfer';
import ErrorBoundary from './components/utils/ErrorBoundary';
import OverlayController from './components/utils/OverlayController';
import Routes from './Routes';
import { FileHandlerProvider } from './tempModels/fileHandler';
import history from './utils/history';

hotjar.initialize(1854127, 6);

type Props = {
  user: User;
};

const App: React.FC<Props> = ({ user }) => {
  const token = user && !!cookies.get(__TOKEN__);

  const piwik = new ReactPiwik({
    url: process.env.NODE_ENV === 'development' ? '' : '//video-hunter-analytics.orfium.com',
    siteId: 2,
    trackErrors: true,
  });

  return (
    <IctinusThemeProvider
      theme={{
        palette: {
          primary: theme.colors.orange,
          secondary: theme.colors.blue,
        },
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Router history={piwik.connectToHistory(history)}>
          <React.Fragment>
            {!token && <Redirect to={urls.login()} />}
            <Container>
              {token && <Header />}
              <AppMainWrapper>
                {token && <Sidebar />}
                <AppContentWrapper>
                  <ErrorBoundary fallbackToErrorScreen>
                    <FileTransferProvider>
                      <FileHandlerProvider>
                        <SelectedRowProvider>
                          <Routes />
                        </SelectedRowProvider>
                      </FileHandlerProvider>
                    </FileTransferProvider>
                  </ErrorBoundary>
                </AppContentWrapper>
              </AppMainWrapper>
            </Container>
            <OverlayController />
            {/*<ModalController />*/}
          </React.Fragment>
        </Router>
      </ThemeProvider>
    </IctinusThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(App);

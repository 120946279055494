import { combineReducers } from 'redux';

import alerts, { __REDUX_STATE_KEY__ as __ALERT_REDUX_STATE_KEY__ } from './alert';
import modals, { __REDUX_STATE_KEY__ as __MODAL_REDUX_STATE_KEY__ } from './modal';
import overlays, { __REDUX_STATE_KEY__ as __OVERLAY_REDUX_STATE_KEY__ } from './overlay';
import sidebar, { __REDUX_STATE_KEY__ as __SIDEBAR_REDUX_STATE_KEY__ } from './sidebar';
export * from './types';

const uiReducer = combineReducers({
  [__SIDEBAR_REDUX_STATE_KEY__]: sidebar,
  [__OVERLAY_REDUX_STATE_KEY__]: overlays,
  [__MODAL_REDUX_STATE_KEY__]: modals,
  [__ALERT_REDUX_STATE_KEY__]: alerts,
});

export default uiReducer;

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';
import { withPaginationSelectors } from 'src/ducks/factories/pagination';
import { withSearchSelectors } from 'src/ducks/factories/search';

export const __REDUX_STATE_KEY__ = 'legacyClaims';
export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getLegacyClaims = (state: AppState) => getReduxStateSlice(state).list;
export const getLegacyClaimsCount = (state: AppState) => getReduxStateSlice(state).countItems;

export const legacyClaimsLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

export const legacyClaimsPaginationSelectors = withPaginationSelectors(__REDUX_STATE_KEY__);

export const legacyClaimsSearchSelectors = withSearchSelectors(__REDUX_STATE_KEY__);

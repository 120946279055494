import values from 'lodash/values';
import { combineEpics } from 'redux-observable';

import allEpics from './epics';

export { default } from './reducer';
export * from './selectors';
export * from './actions';
export * from './types';
export const epics = combineEpics(...values(allEpics));

import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { withSearchActions } from 'src/ducks/factories/search';

import { Anything } from '../../utils/globalTypes';
import { simpleAction } from '../utils';
import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_CONFIRMED_MATCHES_FAILURE,
  FETCH_CONFIRMED_MATCHES_REQUEST,
  FETCH_CONFIRMED_MATCHES_COUNT_REQUEST,
  ConfirmedMatch,
  ConfirmedMatchesParams,
  SET_CONFIRMED_MATCHES,
  SET_CONFIRMED_MATCHES_COUNT,
  HIDE_CONFIRMED_MATCHES_REQUEST,
  REMOVE_CONFIRMED_MATCH,
  RemoveConfirmedMatchAction,
  HideConfirmedMatchesRequestAction,
  FetchConfirmedMatchesFailureAction,
  SetConfirmedMatchesAction,
  FetchConfirmedMatchesRequestAction,
} from './types';

export const setConfirmedMatches = (reviews: ConfirmedMatch[]): SetConfirmedMatchesAction =>
  simpleAction(SET_CONFIRMED_MATCHES, reviews);

export const fetchConfirmedMatchesRequest = (
  params: ConfirmedMatchesParams
): FetchConfirmedMatchesRequestAction => simpleAction(FETCH_CONFIRMED_MATCHES_REQUEST, params);

export const fetchConfirmedMatchesFail = (): FetchConfirmedMatchesFailureAction =>
  simpleAction(FETCH_CONFIRMED_MATCHES_FAILURE);

export const hideConfirmedMatchesRequest = (ids: number[]): HideConfirmedMatchesRequestAction =>
  simpleAction(HIDE_CONFIRMED_MATCHES_REQUEST, ids);

export const fetchConfirmedMatchesCountRequest = (params: ConfirmedMatchesParams): Anything =>
  simpleAction(FETCH_CONFIRMED_MATCHES_COUNT_REQUEST, params);

export const removeConfirmedMatch = (id: number[]): RemoveConfirmedMatchAction =>
  simpleAction(REMOVE_CONFIRMED_MATCH, id);

export const setConfirmedMatchesCount = (data: number): { type: string; payload: number } =>
  simpleAction(SET_CONFIRMED_MATCHES_COUNT, data);

export const confirmedMatchesLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const confirmedMatchesPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

export const confirmedMatchesSearchActions = withSearchActions(__REDUX_STATE_KEY__);

import { Configuration } from 'src/config/types';
import { Ability } from 'src/ducks/user';
import { can } from 'src/ducks/user/policy';
import urls from 'src/urls';
import { LEADS_PAGES, OPERATIONS } from 'src/utils/constants';
import { lazyWithRetry } from 'src/utils/lazyWithRefresh';

import { setIsActive } from './utils';
const LeadsGenerationPage = lazyWithRetry(() => import('src/pages/LeadsGenerationPage'));
const ReviewPage = lazyWithRetry(() => import('src/pages/ReviewPage'));
const GlobalReviewPage = lazyWithRetry(() => import('src/pages/GlobalReviewPage'));
const ClaimListPage = lazyWithRetry(() => import('src/pages/ClaimListPage'));
const ReviewHistoryPage = lazyWithRetry(() => import('src/pages/ReviewHistoryPage'));
const ClaimersAnalyticsPage = lazyWithRetry(() => import('src/pages/ClaimersAnalyticsPage'));
const ClaimAnalyticsPage = lazyWithRetry(() => import('src/pages/ClaimAnalyticsPage'));
const ConfirmedMatchesPage = lazyWithRetry(() => import('src/pages/ConfirmedMatchesPage'));
const AssetsManagementPage = lazyWithRetry(() => import('src/pages/AssetsManagementPage'));
const LegacyClaimsPage = lazyWithRetry(() => import('src/pages/LegacyClaimsPage'));
const ChannelsManagementPage = lazyWithRetry(() => import('src/pages/ChannelsManagementPage'));
const KeywordManagementPage = lazyWithRetry(() => import('src/pages/KeywordManagementPage'));

const configuration: Configuration = {
  baseURL: process.env.REACT_APP_BASE_URL as string,
  menuItems: (ability: Ability) => [
    {
      name: 'Review',
      url: urls.globalReview(),
      component: GlobalReviewPage,
      exact: false,
      iconName: 'searchMusic',
      visible: can({ I: OPERATIONS.READ, This: urls.globalReview(), ability }),
      options: [
        {
          name: 'Global Review',
          url: urls.globalReview() + '?strategy=all',
          iconName: 'globalItem',
          isActive: setIsActive(urls.globalReview() + '?strategy=all'),
          visible: can({ I: OPERATIONS.READ, This: urls.globalReview(), ability }),
          options: [],
        },
        {
          name: 'Asset',
          url: urls.globalReview() + '?strategy=asset',
          iconName: 'musicNote',
          isActive: setIsActive(urls.globalReview() + '?strategy=asset'),
          visible: can({ I: OPERATIONS.READ, This: urls.globalReview(), ability }),
          options: [],
        },
        {
          name: 'Keywords',
          url: urls.globalReview() + '?strategy=keywords',
          iconName: 'keyword',
          isActive: setIsActive(urls.globalReview() + '?strategy=keywords'),
          visible: can({ I: OPERATIONS.READ, This: urls.globalReview(), ability }),
          options: [],
        },
        {
          name: 'Subscribed Channels',
          url: urls.globalReview() + '?strategy=channel',
          iconName: 'user',
          isActive: setIsActive(urls.globalReview() + '?strategy=channel'),
          visible: can({ I: OPERATIONS.READ, This: urls.globalReview(), ability }),
          options: [],
        },
        {
          name: 'Analytics Videos',
          url: urls.globalReview() + '?strategy=suggested',
          iconName: 'linked',
          isActive: setIsActive(urls.globalReview() + '?strategy=suggested'),
          visible: can({ I: OPERATIONS.READ, This: urls.globalReview(), ability }),
          options: [],
        },
        {
          name: 'Pending Claims',
          url: urls.globalReview() + '?strategy=pending',
          iconName: 'hourglass',
          isActive: setIsActive(urls.globalReview() + '?strategy=pending'),
          visible: can({ I: OPERATIONS.READ, This: urls.globalReview(), ability }),
          options: [],
        },
        {
          name: 'Potential Claims',
          url: urls.globalReview() + '?strategy=potential',
          iconName: 'percentage',
          isActive: setIsActive(urls.globalReview() + '?strategy=potential'),
          visible: can({ I: OPERATIONS.READ, This: urls.globalReview(), ability }),
          options: [],
        },
        {
          name: 'Inactive Claims',
          url: urls.globalReview() + '?strategy=inactive',
          iconName: 'timeOut',
          isActive: setIsActive(urls.globalReview() + '?strategy=inactive'),
          visible: can({ I: OPERATIONS.READ, This: urls.globalReview(), ability }),
          options: [],
        },
      ],
    },
    {
      name: 'Leads Generation',
      url: urls.leadsGeneration(),
      iconName: 'youtube',
      exact: false,
      component: LeadsGenerationPage,
      visible: can({ I: OPERATIONS.READ, This: urls.leadsGeneration(), ability }),
      options: [
        {
          name: 'Top Assets Claimed',
          url: urls.leadsGeneration() + `?strategy=${LEADS_PAGES.TOP_ASSETS}`,
          iconName: 'star',
          isActive: setIsActive(urls.leadsGeneration() + `?strategy=${LEADS_PAGES.TOP_ASSETS}`),
          visible: can({ I: OPERATIONS.READ, This: urls.leadsGeneration(), ability }),
          options: [],
        },
        {
          name: 'Top Channels Claimed',
          url: urls.leadsGeneration() + `?strategy=${LEADS_PAGES.TOP_CHANNELS}`,
          iconName: 'user',
          isActive: setIsActive(urls.leadsGeneration() + `?strategy=${LEADS_PAGES.TOP_CHANNELS}`),
          visible: can({ I: OPERATIONS.READ, This: urls.leadsGeneration(), ability }),
          options: [],
        },
        {
          name: 'Top Keywords Claimed',
          url: urls.leadsGeneration() + `?strategy=${LEADS_PAGES.TOP_KEYWORDS}`,
          iconName: 'keyword',
          isActive: setIsActive(urls.leadsGeneration() + `?strategy=${LEADS_PAGES.TOP_KEYWORDS}`),
          visible: can({ I: OPERATIONS.READ, This: urls.leadsGeneration(), ability }),
          options: [],
        },
        {
          name: 'Top Videos Claimed',
          url: urls.leadsGeneration() + `?strategy=${LEADS_PAGES.TOP_VIDEOS}`,
          iconName: 'financialChart',
          isActive: setIsActive(urls.leadsGeneration() + `?strategy=${LEADS_PAGES.TOP_VIDEOS}`),
          visible: can({ I: OPERATIONS.READ, This: urls.leadsGeneration(), ability }),
          options: [],
        },
      ],
    },
    {
      name: 'Review History',
      component: ReviewHistoryPage,
      exact: true,
      url: urls.reviewHistory(),
      iconName: 'restore',
      visible: can({ I: OPERATIONS.READ, This: urls.reviewHistory(), ability }),
      options: [],
    },
    {
      name: 'Claim List',
      component: ClaimListPage,
      exact: true,
      url: urls.claimList(),
      iconName: 'income',
      visible: can({ I: OPERATIONS.READ, This: urls.claimList(), ability }),
      options: [],
    },
    {
      name: 'Analytics',
      url: urls.analytics(),
      iconName: 'analytics',
      visible: can({ I: OPERATIONS.READ, This: urls.claimerAnalytics(), ability }),
      options: [
        {
          name: 'Claimer Analytics',
          component: ClaimersAnalyticsPage,
          exact: true,
          url: urls.claimerAnalytics(),
          iconName: 'financialChart',
          visible: can({ I: OPERATIONS.READ, This: urls.claimerAnalytics(), ability }),
          options: [],
        },
        {
          name: 'Claim Analytics',
          component: ClaimAnalyticsPage,
          exact: true,
          url: urls.claimAnalytics(),
          iconName: 'analytics',
          visible: can({ I: OPERATIONS.READ, This: urls.claimAnalytics(), ability }),
          options: [],
        },
      ],
    },
    {
      name: 'System Management',
      url: urls.systemManagement(),
      iconName: 'ticTacToeArrow',
      visible: can({ I: OPERATIONS.READ, This: urls.systemManagement(), ability }),
      options: [
        {
          name: 'Assets Management',
          component: AssetsManagementPage,
          exact: true,
          url: urls.assetsManagement(),
          visible: can({ I: OPERATIONS.READ, This: urls.assetsManagement(), ability }),
          iconName: 'musicNote',
          options: [],
        },
        {
          name: 'Channels Management',
          component: ChannelsManagementPage,
          exact: true,
          url: urls.channelsManagement(),
          visible: can({ I: OPERATIONS.READ, This: urls.channelsManagement(), ability }),
          iconName: 'user',
          options: [],
        },
        {
          name: 'Keyword Management',
          component: KeywordManagementPage,
          exact: true,
          url: urls.keywordManagement(),
          visible: can({ I: OPERATIONS.READ, This: urls.keywordManagement(), ability }),
          iconName: 'keyword',
          options: [],
        },
        {
          name: 'Legacy Claims',
          component: LegacyClaimsPage,
          exact: true,
          url: urls.legacyClaims(),
          visible: can({ I: OPERATIONS.READ, This: urls.legacyClaims(), ability }),
          iconName: 'ancient',
          options: [],
        },
      ],
    },
    {
      name: 'Training & Trials',
      url: urls.reviewQueue(),
      iconName: 'training',
      visible: can({ I: OPERATIONS.READ, This: urls.reviewQueue(), ability }),
      options: [
        {
          name: 'Video Confirmation',
          exact: true,
          component: ReviewPage,
          url: urls.reviewQueue(),
          iconName: 'stamp',
          visible: can({ I: OPERATIONS.READ, This: urls.reviewQueue(), ability }),
          options: [],
        },
        {
          name: 'Confirmed Matches',
          component: ConfirmedMatchesPage,
          exact: true,
          url: urls.confirmedMatches(),
          iconName: 'checkedList',
          visible: can({ I: OPERATIONS.READ, This: urls.confirmedMatches(), ability }),
          options: [],
        },
      ],
    },
  ],
};

export default configuration;

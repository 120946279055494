import { REHYDRATE, RehydrateAction } from 'redux-persist';
import { SET_LOADING, Loading, LoadingActionTypes } from 'src/ducks/factories/loading/types';

import { Anything } from '../../../utils/globalTypes';

const initialState: Loading = {
  loading: true,
};

const loading = <T>({ prefix, baseReducerState }: { prefix: string; baseReducerState: T }) => {
  return (baseReducer: Anything) =>
    (state = initialState, action: LoadingActionTypes | RehydrateAction): Anything => {
      // we are creating a new state based on the state passed our initial state and the reducer state
      // this is because at first the state is empty and if we combine multiple reducers the state must combined
      // from all the states passed
      const newState = { ...initialState, ...baseReducerState, ...state };

      switch (action.type) {
        case SET_LOADING(prefix): {
          return {
            ...newState,
            loading: action.payload,
          };
        }
        case REHYDRATE: {
          // we use this case of rehydration for persistance because the new state that we are building will not hold the data
          // that redux-persist pass on action.payload. That's why on this type we check if there is a payload and if there is then we pass
          // to the state the piece of the state from the rehydrated. This will only be needed for factories
          const state = Object.assign(
            {},
            newState,
            // we supress it with ts-ignore since the rehydrate action that we don't handle uses object as a payload type
            // @ts-ignore
            action.payload && typeof action.payload === 'object' ? action.payload[prefix] : {}
          );
          return baseReducer(state, action);
        }
        default:
          return baseReducer(newState, action);
      }
    };
};

export default loading;

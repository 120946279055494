import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { SET_PAGINATION_PAGE } from 'src/ducks/factories/pagination/types';
import { getFilters, getSelectedStrategy } from 'src/ducks/legacyFilters';
import { handleReviewParams } from 'src/ducks/legacyFilters/utils';
import { pagePicker } from 'src/ducks/utils';
import { confirmedAPI } from 'src/providers';
import { ChannelsPaginationLimit } from 'src/utils/constants';

import { Anything } from '../../utils/globalTypes';
import {
  confirmedMatchesLoadingActions,
  confirmedMatchesPaginationActions,
  fetchConfirmedMatchesCountRequest,
  fetchConfirmedMatchesFail,
  fetchConfirmedMatchesRequest,
  removeConfirmedMatch,
  setConfirmedMatches,
  setConfirmedMatchesCount,
} from './actions';
import { __REDUX_STATE_KEY__, confirmedMatchesSearchSelectors } from './selectors';
import {
  FETCH_CONFIRMED_MATCHES_COUNT_REQUEST,
  FETCH_CONFIRMED_MATCHES_REQUEST,
  FetchConfirmedMatchesCountRequestAction,
  FetchConfirmedMatchesRequestAction,
  HIDE_CONFIRMED_MATCHES_REQUEST,
  HideConfirmedMatchesRequestAction,
  REMOVE_CONFIRMED_MATCH,
  RemoveConfirmedMatchAction,
} from './types';
import { transformResponse } from './utils';

const fetchConfirmedMatchEpic = (
  action$: ActionsObservable<FetchConfirmedMatchesRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_CONFIRMED_MATCHES_REQUEST),
    switchMap(({ payload }) => {
      const { withLoading = true, ...rest } = payload;
      return concat(
        of(confirmedMatchesLoadingActions.setLoading(withLoading)),
        from(
          confirmedAPI.single
            .get({
              ...rest,
              q: confirmedMatchesSearchSelectors.getSearchValue(state$.value),
            })
            .request()
        ).pipe(
          mergeMap((data) => of(setConfirmedMatches(transformResponse(data.results)))),
          catchError(() => of(fetchConfirmedMatchesFail()))
        ),
        of(confirmedMatchesLoadingActions.setLoading(false))
      );
    })
  );

const hideConfirmedMatchEpic = (
  action$: ActionsObservable<HideConfirmedMatchesRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(HIDE_CONFIRMED_MATCHES_REQUEST),
    switchMap(({ payload: item_ids }) =>
      concat(
        // if the user wants to hide one then dont show a loading message
        of(confirmedMatchesLoadingActions.setLoading(item_ids.length > 1)),
        // if its only one item then hide it before the request and do the optimistic update
        from(Promise.resolve()).pipe(
          mergeMap(() => (item_ids.length === 1 ? of(removeConfirmedMatch(item_ids)) : of()))
        ),
        from(
          confirmedAPI.bulk
            .action('confirmed', 'hide', {
              item_ids,
              strategy: getSelectedStrategy(state$.value),
            })
            .request()
        ).pipe(
          mergeMap(() => {
            // as previous check we do the opposite in this case
            // if its more than one item then remove analytics video and trigger new fetch
            return item_ids.length > 1 ? of(removeConfirmedMatch(item_ids)) : of();
          })
        )
      )
    )
  );

const fetchConfirmedMatchesCountRequestEpic = (
  action$: ActionsObservable<FetchConfirmedMatchesCountRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_CONFIRMED_MATCHES_COUNT_REQUEST),
    switchMap(({ payload }) =>
      from(
        confirmedAPI.single
          .getCount({
            ...payload,
            q: confirmedMatchesSearchSelectors.getSearchValue(state$.value),
          })
          .request()
      ).pipe(
        mergeMap((data) =>
          of(
            setConfirmedMatchesCount(data.count),
            confirmedMatchesPaginationActions.setPaginationTotalPages(
              Math.ceil(data.count / ChannelsPaginationLimit)
            )
          )
        )
      )
    )
  );

const changePageEpic = (action$: ActionsObservable<Anything>, state$: StateObservable<Anything>) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REDUX_STATE_KEY__)),
    switchMap((data) =>
      concat(
        of(confirmedMatchesLoadingActions.setLoading(true)),
        of(
          fetchConfirmedMatchesRequest(
            handleReviewParams(
              getFilters(state$.value),
              data.payload,
              confirmedMatchesSearchSelectors.getSearchValue(state$.value)
            )
          )
        ),
        of(confirmedMatchesLoadingActions.setLoading(false))
      )
    )
  );

const removeConfirmedMatchEpic = (
  action$: ActionsObservable<RemoveConfirmedMatchAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(REMOVE_CONFIRMED_MATCH),
    mergeMap(() => {
      const {
        [__REDUX_STATE_KEY__]: { list, countItems, page, q },
      } = state$.value;
      if (list.length === 0 && countItems !== 0) {
        return of(
          confirmedMatchesPaginationActions.setPaginationPage(pagePicker(page)),
          fetchConfirmedMatchesCountRequest(
            handleReviewParams(getFilters(state$.value), pagePicker(page), q)
          )
        );
      }
      return of();
    })
  );

export default {
  hideConfirmedMatchEpic,
  fetchConfirmedMatchEpic,
  fetchConfirmedMatchesCountRequestEpic,
  changePageEpic,
  removeConfirmedMatchEpic,
};

import { AppState } from 'src/ducks';
import { Account } from 'src/ducks/user/types';

export const __REDUX_STATE_KEY__ = 'user';
export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getUser = (state: AppState) => getReduxStateSlice(state);

export const getSelectedAccount = (state: AppState) =>
  getReduxStateSlice(state)?.accounts.find(({ selected }) => selected) as Account;

export const getAbility = (state: AppState) => getReduxStateSlice(state)?.ability;

export const getAccounts = (state: AppState) => getReduxStateSlice(state)?.accounts;

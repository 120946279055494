import styled from '@emotion/styled';
import { rem } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.utils.getColor('lightGrey', 50)};
  border-radius: ${({ theme }) => theme.spacing.xsm};
  margin: ${({ theme }) => theme.spacing.md} 0px;
  padding: 0px ${({ theme }) => theme.spacing.xl};
  font-size: ${({ theme }) => theme.typography.fontSizes['16']};
  line-height: ${rem(19)};
  text-align: left;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.typography.weights.medium};
  margin-top: ${({ theme }) => theme.spacing.md}; ;
`;

export const Error = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.sm};
  > button {
    color: ${({ theme }) => theme.utils.getColor('secondary', 400, 'normal')};
    text-decoration: underline;
    font-weight: ${({ theme }) => theme.typography.weights.medium};
  }
`;

export const Result = styled.div`
  margin: ${({ theme }) => theme.spacing.lg} 0px;
  > div:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

import { REHYDRATE as REHYDRATE_ACTION } from 'redux-persist';
import { RehydrateErrorType } from 'redux-persist/es/types';
import { FiltersState } from 'src/ducks/filters';
import { User } from 'src/ducks/user';

export const REHYDRATE = REHYDRATE_ACTION;

export type RehydrateAction = {
  type: typeof REHYDRATE;
  key: string;
  payload?: {
    user?: User;
    filters?: FiltersState;
  } | null;
  err?: RehydrateErrorType | null;
};

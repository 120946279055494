export const SET_LOADING = (prefix = '') => `${prefix}_SET_LOADING`;

export interface SetLoadingAction {
  type: string;
  payload: boolean;
}

export type LoadingActionTypes = SetLoadingAction;

export type Loading = {
  loading: boolean;
};

import { FilterParams } from 'src/ducks/filters';

export const FETCH_KEYWORD_MANAGEMENT_REQUEST = 'FETCH_KEYWORD_MANAGEMENT_REQUEST';
export const FETCH_KEYWORD_MANAGEMENT_FAILURE = 'FETCH_KEYWORD_MANAGEMENT_FAILURE';
export const SET_KEYWORD_MANAGEMENT = 'SET_KEYWORD_MANAGEMENT';
export const FETCH_KEYWORD_MANAGEMENT_COUNT_REQUEST = 'FETCH_KEYWORD_MANAGEMENT_COUNT_REQUEST';
export const FETCH_KEYWORD_MANAGEMENT_COUNT_FAILURE = 'FETCH_KEYWORD_MANAGEMENT_COUNT_FAILURE';
export const SET_KEYWORD_MANAGEMENT_COUNT = 'SET_KEYWORD_MANAGEMENT_COUNT';
export const TRIGGER_KEYWORD_MANAGEMENT_ACTION = 'TRIGGER_KEYWORD_MANAGEMENT_ACTION';
export const TRIGGER_KEYWORD_MANAGEMENT_ACTION_SUCCESS =
  'TRIGGER_KEYWORD_MANAGEMENT_ACTION_SUCCESS';
export const REMOVE_KEYWORD_MANAGEMENT = 'REMOVE_KEYWORD_MANAGEMENT';
export const KEYWORD_MANAGEMENT_UPLOAD_ID = 'KEYWORD_MANAGEMENT_UPLOAD_ID';

export type FetchKeywordManagementsRequestAction = {
  type: typeof FETCH_KEYWORD_MANAGEMENT_REQUEST;
  payload: KeywordManagementParams;
};

export type FetchKeywordManagementsFailureAction = {
  type: typeof FETCH_KEYWORD_MANAGEMENT_FAILURE;
};

export type SetKeywordManagementsAction = {
  type: typeof SET_KEYWORD_MANAGEMENT;
  payload: KeywordManagementType[];
};

export type RemoveKeywordManagementsAction = {
  type: typeof REMOVE_KEYWORD_MANAGEMENT;
  payload: (string | number)[];
};

export type FetchKeywordManagementsCountRequestAction = {
  type: typeof FETCH_KEYWORD_MANAGEMENT_COUNT_REQUEST;
  payload: KeywordManagementParams;
};

export type FetchKeywordManagementsCountFailureAction = {
  type: typeof FETCH_KEYWORD_MANAGEMENT_COUNT_FAILURE;
};

export type SetKeywordManagementsCountAction = {
  type: typeof SET_KEYWORD_MANAGEMENT_COUNT;
  payload: KeywordManagementCountResponse;
};

export type TriggerKeywordManagementsAction = {
  type: typeof TRIGGER_KEYWORD_MANAGEMENT_ACTION;
  payload: KeywordManagementsTriggerActionParams;
};

export type TriggerKeywordManagementsActionSuccess = {
  type: typeof TRIGGER_KEYWORD_MANAGEMENT_ACTION_SUCCESS;
  payload: KeywordManagementsTriggerActionParams;
};

export type KeywordManagementParams = FilterParams;
export type KeywordManagementsActions = 'reject' | 'hide';
export type KeywordManagementsTriggerActionParams = {
  keywords: string[];
  enabled: boolean;
};

export type KeywordManagementCountResponse = {
  count: number;
  estimate: boolean;
};

export type KeywordManagementActionTypes =
  | SetKeywordManagementsAction
  | SetKeywordManagementsCountAction
  | RemoveKeywordManagementsAction
  | TriggerKeywordManagementsAction
  | TriggerKeywordManagementsActionSuccess;

export type KeywordManagementReducer = {
  list: KeywordManagementType[];
  countItems: number;
  estimate: boolean;
};

export type KeywordManagementType = {
  keyword: string;
  last_run_at: string | null;
  executions: number;
  review_videos: number;
  claimed_videos: number;
  enabled: boolean;
};

import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { SET_PAGINATION_PAGE } from 'src/ducks/factories/pagination/types';
import { getFilters } from 'src/ducks/legacyFilters';
import { handleStrategiesParams } from 'src/ducks/legacyFilters/utils';
import { pagePicker } from 'src/ducks/utils';
import { channelManagementAPI } from 'src/providers';
import { ChannelsPaginationLimit } from 'src/utils/constants';

import { Anything } from '../../utils/globalTypes';
import {
  channelsManagementLoadingActions,
  channelsManagementPaginationActions,
  fetchChannelsFailure,
  fetchChannelsRequest,
  setChannels,
  setChannelsCount,
  setSelectedChannels,
  updateSelectedChannels,
} from './actions';
import {
  __REDUX_STATE_KEY__,
  channelsManagementSearchSelectors,
  getSelectedChannels,
} from './selectors';
import {
  FETCH_CHANNELS_COUNT_REQUEST,
  FETCH_CHANNELS_REQUEST,
  FetchChannelsCountRequestAction,
  FetchChannelsRequestAction,
  SET_SELECTED_CHANNELS,
  SetSelectedChannelsAction,
  UPDATE_CHANNELS,
  UpdateChannelsAction,
} from './types';

const fetchChannelsEpic = (
  action$: ActionsObservable<FetchChannelsRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_CHANNELS_REQUEST),
    switchMap(({ payload }) => {
      const { withLoading = true, ...rest } = payload;
      return concat(
        of(channelsManagementLoadingActions.setLoading(withLoading)),
        from(
          channelManagementAPI.single
            .get({
              ...rest,
              q: channelsManagementSearchSelectors.getSearchValue(state$.value),
            })
            .request()
        ).pipe(
          mergeMap((data: Anything) => [setChannels(data), setSelectedChannels([])]),
          catchError(() => of(fetchChannelsFailure()))
        ),
        of(channelsManagementLoadingActions.setLoading(false))
      );
    })
  );

const fetchChannelsCountRequestEpic = (
  action$: ActionsObservable<FetchChannelsCountRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_CHANNELS_COUNT_REQUEST),
    switchMap(({ payload }) =>
      concat(
        of(channelsManagementLoadingActions.setLoading(true)),
        from(
          channelManagementAPI.single
            .getCount({
              ...payload,
              q: channelsManagementSearchSelectors.getSearchValue(state$.value),
            })
            .request()
        ).pipe(
          mergeMap((data: Anything) =>
            of(
              setChannelsCount(data.count),
              // channelsManagementPaginationActions.setPaginationPage(payload.page),
              channelsManagementPaginationActions.setPaginationTotalPages(
                Math.ceil(data.count / ChannelsPaginationLimit)
              )
            )
          )
        ),
        of(channelsManagementLoadingActions.setLoading(false))
      )
    )
  );

const changePageEpic = (action$: ActionsObservable<Anything>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REDUX_STATE_KEY__)),
    switchMap((data) =>
      concat(
        of(channelsManagementLoadingActions.setLoading(true)),
        of(
          fetchChannelsRequest(
            handleStrategiesParams(
              getFilters(state$.value),
              data.payload,
              channelsManagementSearchSelectors.getSearchValue(state$.value)
            )
          )
        ),
        of(channelsManagementLoadingActions.setLoading(false))
      )
    )
  );

const setSelectedChannelsEpic = (
  action$: ActionsObservable<SetSelectedChannelsAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(SET_SELECTED_CHANNELS),
    map(({ payload }) => {
      const selected = getSelectedChannels(state$.value);
      if (payload.length === 1) {
        return selected.includes(payload[0])
          ? updateSelectedChannels([
              ...selected.splice(0, selected.indexOf(payload[0])),
              ...selected.splice(selected.indexOf(payload[0]) + 1, selected.length - 1),
            ])
          : updateSelectedChannels([...selected, payload[0]]);
      } else return updateSelectedChannels(payload);
    })
  );

const updateChannelsEpic = (
  action$: ActionsObservable<UpdateChannelsAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(UPDATE_CHANNELS),
    switchMap(({ payload }) => {
      const {
        [__REDUX_STATE_KEY__]: { page, totalPages },
      } = state$.value;
      const fetchPage = page === totalPages ? pagePicker(page) : page;

      return concat(
        of(channelsManagementLoadingActions.setLoading(true)),
        from(
          channelManagementAPI.bulk
            .update({
              ...payload,
            })
            .request()
        ).pipe(map(() => updateSelectedChannels([]))),
        of(
          fetchChannelsRequest(
            handleStrategiesParams(
              getFilters(state$.value),
              fetchPage,
              channelsManagementSearchSelectors.getSearchValue(state$.value)
            )
          )
        ),
        of(channelsManagementLoadingActions.setLoading(false))
      );
    })
  );

export default {
  fetchChannelsEpic,
  fetchChannelsCountRequestEpic,
  changePageEpic,
  setSelectedChannelsEpic,
  updateChannelsEpic,
};

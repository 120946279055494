import dayjs from 'dayjs';
import React from 'react';

import {
  ButtonWrapper,
  InputWrapper,
  Separator,
  TimestampSpan,
  TimestampContainer,
} from './TimestampsHandler.style';

const secondsToTimeFormat = (seconds: number) =>
  new Date(seconds * 1000).toISOString().substr(11, 8);

const getSecondsFromValue = (time: string) =>
  dayjs(time, 'HH:mm:ss').diff(dayjs().startOf('day'), 'second');

type Props = {
  start: number;
  end: number;
  setStart(num?: number): void;
  setEnd(num?: number): void;
};

const TimestampsHandler: React.FC<Props> = ({ start, setStart, end, setEnd }) => {
  const setStartCallback = (__e: React.ChangeEvent<HTMLInputElement>, newTime: string) => {
    setStart(getSecondsFromValue(newTime));
  };

  const setEndCallback = (__e: React.ChangeEvent<HTMLInputElement>, newTime: string) => {
    setEnd(getSecondsFromValue(newTime));
  };

  return (
    <React.Fragment>
      <TimestampContainer>
        <TimestampSpan>
          <ButtonWrapper type="button" onClick={() => setStart()}>
            start
          </ButtonWrapper>
          <InputWrapper
            aria-label="start-point-timestamp"
            value={secondsToTimeFormat(start)}
            showSeconds
            onChange={setStartCallback}
          />
        </TimestampSpan>
        <Separator>-</Separator>
        <TimestampSpan>
          <InputWrapper
            aria-label="end-point-timestamp"
            value={secondsToTimeFormat(end)}
            showSeconds
            onChange={setEndCallback}
          />
          <ButtonWrapper type="button" onClick={() => setEnd()}>
            end
          </ButtonWrapper>
        </TimestampSpan>
      </TimestampContainer>
    </React.Fragment>
  );
};

export default TimestampsHandler;

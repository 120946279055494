import { METHODS, request } from '../axiosInstances';

export default {
  get: (params = {}) => request(METHODS.GET, `/review/actions/`, { params }),
  create: (itemId: number, params: Record<string, unknown>) =>
    request(METHODS.POST, `/review/actions/${itemId}/`, { params }),
  update: (params: Record<string, unknown>) =>
    request(METHODS.PUT, `/review/actions/status/`, { params }),
  delete: (itemId: number, payload: Record<string, unknown>) =>
    request(METHODS.DELETE, `/review/actions/${itemId}/`, { payload }),
};

import { combineReducers } from 'redux';
import { reducer as form, FormStateMap } from 'redux-form';
import { Epic, combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { of, merge } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Loading } from 'src/ducks/factories/loading/types';
import { Pagination } from 'src/ducks/factories/pagination/types';
import { Search } from 'src/ducks/factories/search/types';
import { createError } from 'src/ducks/global/errorHandling';
import { handleFormErrors } from 'src/utils/forms';

import { Anything } from '../utils/globalTypes';
import assetStrategy, {
  __REDUX_STATE_KEY__ as __ASSETS_MANAGEMENT_REDUX_STATE_KEY__,
  AssetsManagementReducer,
  epics as assetStrategyEpics,
} from './assetsManagement';
import channelManagement, {
  __REDUX_STATE_KEY__ as __CHANNELS_MANAGEMENT_REDUX_STATE_KEY__,
  ChannelsManagementReducer,
  epics as channelManagementEpics,
} from './channelsManagement';
import claimAnalytics, {
  __REDUX_STATE_KEY__ as __CLAIM_ANALYTICS_REDUX_STATE_KEY__,
  ClaimAnalyticsReducer,
  epics as claimAnalyticsEpics,
} from './claimAnalytics';
import claim, {
  __REDUX_STATE_KEY__ as __CLAIM_REDUX_STATE_KEY__,
  ClaimReducer,
  epics as claimEpics,
} from './claims';
import confirmedMatches, {
  __REDUX_STATE_KEY__ as __CONFIRMED_MATCHES_REDUX_STATE_KEY__,
  ConfirmedMatchesReducer,
  epics as confirmedMatchesEpics,
} from './confirmedMatches';
import filters, {
  __REDUX_STATE_KEY__ as __FILTER_REDUX_STATE_KEY__,
  epics as filterEpics,
  FiltersState,
} from './filters';
import { globalEpics } from './global';
import globalReview, {
  __REDUX_STATE_KEY__ as __GLOBAL_REVIEW_REDUX_STATE_KEY__,
  GlobalReviewReducer,
  epics as globalReviewEpics,
} from './globalReview';
import globalSearch, {
  __REDUX_STATE_KEY__ as __GLOBAL_SEARCH_REDUX_STATE_KEY__,
  GlobalSearchReducer,
} from './globalSearch';
import keywordManagement, {
  __REDUX_STATE_KEY__ as __QUERY_MANAGEMENT_REDUX_STATE_KEY__,
  KeywordManagementReducer,
  epics as keywordManagementEpics,
} from './keywordManagement';
import leadsGeneration, {
  __REDUX_STATE_KEY__ as __LEADS_GENERATION_REDUX_STATE_KEY__,
  LeadsGenerationReducer,
  epics as leadsGenerationEpics,
} from './leadsGeneration';
import legacyClaims, {
  __REDUX_STATE_KEY__ as ___LEGACY_CLAIMS_REDUX_STATE_KEY__,
  LegacyClaimsReducer,
  epics as legacyClaimsEpics,
} from './legacyClaims';
import legacyFilters, {
  __REDUX_STATE_KEY__ as __LEGACY_FILTER_REDUX_STATE_KEY__,
  epics as legacyFilterEpics,
  FiltersState as LegacyFiltersState,
} from './legacyFilters';
import review, {
  __REDUX_STATE_KEY__ as __REVIEW_REDUX_STATE_KEY__,
  ReviewReducer,
  epics as reviewEpics,
} from './review';
import reviewHistory, {
  __REDUX_STATE_KEY__ as __REVIEW_HISTORY_REDUX_STATE_KEY__,
  ReviewHistoryReducer,
  epics as reviewHistoryEpics,
} from './reviewHistory';
import router, { __REDUX_STATE_KEY__ as __ROUTER_REDUX_STATE_KEY__, RouterReducer } from './router';
import ui, { UI } from './ui';
import user, {
  __REDUX_STATE_KEY__ as __USER_REDUX_STATE_KEY__,
  User,
  epics as userEpics,
} from './user';

export default combineReducers<AppState>({
  ui,
  form,
  [__USER_REDUX_STATE_KEY__]: user,
  [__FILTER_REDUX_STATE_KEY__]: filters,
  [__LEGACY_FILTER_REDUX_STATE_KEY__]: legacyFilters,
  [__REVIEW_REDUX_STATE_KEY__]: review,
  [__REVIEW_HISTORY_REDUX_STATE_KEY__]: reviewHistory,
  [__CONFIRMED_MATCHES_REDUX_STATE_KEY__]: confirmedMatches,
  [__ASSETS_MANAGEMENT_REDUX_STATE_KEY__]: assetStrategy,
  [__CHANNELS_MANAGEMENT_REDUX_STATE_KEY__]: channelManagement,
  [___LEGACY_CLAIMS_REDUX_STATE_KEY__]: legacyClaims,
  [__GLOBAL_REVIEW_REDUX_STATE_KEY__]: globalReview,
  [__LEADS_GENERATION_REDUX_STATE_KEY__]: leadsGeneration,
  [__CLAIM_REDUX_STATE_KEY__]: claim,
  [__QUERY_MANAGEMENT_REDUX_STATE_KEY__]: keywordManagement,
  [__CLAIM_ANALYTICS_REDUX_STATE_KEY__]: claimAnalytics,
  [__GLOBAL_SEARCH_REDUX_STATE_KEY__]: globalSearch,
  [__ROUTER_REDUX_STATE_KEY__]: router,
});

export const rootEpic: Epic = (
  action$: ActionsObservable<Anything>,
  state$: StateObservable<AppState>
) =>
  combineEpics(
    userEpics,
    filterEpics,
    legacyFilterEpics,
    reviewEpics,
    reviewHistoryEpics,
    confirmedMatchesEpics,
    assetStrategyEpics,
    legacyClaimsEpics,
    channelManagementEpics,
    globalReviewEpics,
    claimEpics,
    leadsGenerationEpics,
    keywordManagementEpics,
    claimAnalyticsEpics,
    ...globalEpics
  )(action$, state$).pipe(
    catchError((error, source$) =>
      error.errors ? merge(source$, of(createError(handleFormErrors(error.errors)))) : source$
    )
  );

export interface AppState {
  ui: UI;
  form: FormStateMap;
  [__USER_REDUX_STATE_KEY__]: User;
  [__REVIEW_HISTORY_REDUX_STATE_KEY__]: ReviewHistoryReducer & Loading & Pagination;
  [__FILTER_REDUX_STATE_KEY__]: FiltersState & Loading;
  [__LEGACY_FILTER_REDUX_STATE_KEY__]: LegacyFiltersState & Loading;
  [__REVIEW_REDUX_STATE_KEY__]: ReviewReducer & Loading & Pagination & Search;
  [__ASSETS_MANAGEMENT_REDUX_STATE_KEY__]: AssetsManagementReducer & Loading & Pagination & Search;
  [__CONFIRMED_MATCHES_REDUX_STATE_KEY__]: ConfirmedMatchesReducer & Loading & Pagination & Search;
  [___LEGACY_CLAIMS_REDUX_STATE_KEY__]: LegacyClaimsReducer & Loading & Pagination & Search;
  [__CHANNELS_MANAGEMENT_REDUX_STATE_KEY__]: ChannelsManagementReducer &
    Loading &
    Pagination &
    Search;
  [__GLOBAL_REVIEW_REDUX_STATE_KEY__]: GlobalReviewReducer & Loading & Pagination & Search;
  [__LEADS_GENERATION_REDUX_STATE_KEY__]: LeadsGenerationReducer & Loading & Pagination & Search;
  [__CLAIM_REDUX_STATE_KEY__]: ClaimReducer & Loading & Pagination & Search;
  [__QUERY_MANAGEMENT_REDUX_STATE_KEY__]: KeywordManagementReducer & Loading & Pagination & Search;
  [__CLAIM_ANALYTICS_REDUX_STATE_KEY__]: ClaimAnalyticsReducer & Loading;
  [__GLOBAL_SEARCH_REDUX_STATE_KEY__]: GlobalSearchReducer;
  [__ROUTER_REDUX_STATE_KEY__]: RouterReducer;
}

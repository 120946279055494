import React from 'react';
import { WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import TimestampsHandler from 'src/components/TimestampsHandler';
import { onChangeTimestamp } from 'src/utils/common';
import { Timestamp } from 'src/utils/constants';

import { Anything } from '../../../utils/globalTypes';

export type Props = {
  id: string;
  videoRef: Anything; // no need to have a type for this react ref
  timestamp: Timestamp;
} & WrappedFieldProps &
  WrappedFieldMetaProps;

const BaseTimestampsHandler: React.FC<Props> = ({ videoRef, input: { value, onChange } }) => {
  return (
    <TimestampsHandler
      start={value.matched_from}
      end={value.matched_to}
      setStart={(num?: number) => {
        onChangeTimestamp('matched_from', videoRef.current.getCurrentTime(), value, onChange, num);
      }}
      setEnd={(num?: number) => {
        onChangeTimestamp('matched_to', videoRef.current.getCurrentTime(), value, onChange, num);
      }}
    />
  );
};

export default BaseTimestampsHandler;

import React from 'react';

type Props = {
  children: React.ReactNode;
  callback(e: React.KeyboardEvent): void;
};

class EscHandler extends React.PureComponent<Props> {
  componentDidMount() {
    // listen to escape keypresses
    document.addEventListener('keyup', this.handleESCKeypress);
  }

  componentWillUnmount() {
    // stop listening to escape keypresses
    document.removeEventListener('keyup', this.handleESCKeypress);
  }

  handleESCKeypress = (e: KeyboardEvent) => {
    const event = e as unknown as React.KeyboardEvent<HTMLElement>;
    if (event.key === 'Escape') {
      this.props.callback(event);
    }
  };

  render() {
    return this.props.children;
  }
}

export default EscHandler;

import { Theme as IctinusTheme } from '@orfium/ictinus';

export type Theme = {
  fontFamily: string;
  fontFamilyAlt: string;
  gridColumnGap: string;
  colors: {
    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    headerColor: string;
    purple: string;
    brown1: string;
    brown2: string;
    orange: string;
    blue: string;
    ciel: string;
    error: string;
    success: string;
    buttons: {
      green: string;
      blue: string;
      red: string;
      grey: string;
      lightGrey: string;
      dark: string;
      alert: string;
    };
    snackbar: {
      blue: string;
      red: string;
      orange: string;
    };
    tags: {
      green: string;
      purple: string;
      blue: string;
    };
    siteBackground: string;
    siteDarkBackground: string;
    staticPagesBackground: string;
    formElementText: string;
    formElementPlaceholder: string;
    formElementError: string;
    formElementFontSize: string;
    defaultText: string;
    tooltipBackground: string;
    libraryApp: string;
    distributionApp: string;
    earningsApp: string;
    analyticsApp: string;
    warningLabel: string;
    menu: string;
    menuText: string;

    audiomatch: string;
  };
  /* Heights & Widths */
  sidebarMaxWidth: string;
  sidebarMinWidth: string;
  headerHeight: string;
  snackbarItemHeight: string;
};

const colors = {
  purple: '#a2139f',
  brown1: '#2f2a27',
  brown2: '#231f20',
  orange: '#BF360C',
  green: '#6eb323',
  blue: '#68b2c8',
  newBlue: '#4945EE',
  red: '#ec0000',
  ciel: '#27aa90',
};

export const theme: Theme = {
  fontFamily: 'Roboto, sans-serif',
  fontFamilyAlt: 'Open Sans, sans-serif',
  gridColumnGap: '20px',

  colors: {
    /* Base palette */
    grey1: '#f0f2f4',
    grey2: '#b2bdc4',
    grey3: '#f5f5f6',
    grey4: '#979797',

    headerColor: '#231f20',

    purple: `${colors.purple}`,
    brown1: `${colors.brown1}`,
    brown2: `${colors.brown2}`,
    orange: `${colors.orange}`,
    ciel: `${colors.ciel}`,
    blue: `${colors.newBlue}`,

    error: `${colors.red}`,
    success: `${colors.green}`,

    buttons: {
      green: `${colors.green}`,
      blue: `${colors.blue}`,
      red: `${colors.red}`,
      grey: '#7b7b7b',
      lightGrey: '#b2bdc4',
      dark: '#000',
      alert: `${colors.orange}`,
    },
    tags: {
      green: '#81b745',
      purple: '#901569',
      blue: '#155bad',
    },
    snackbar: {
      blue: `${colors.blue}`,
      red: `${colors.red}`,
      orange: `${colors.orange}`,
    },

    /* Elements & Components colors */
    siteBackground: '#fff',
    siteDarkBackground: '#111',
    staticPagesBackground: '#f3f3f3',
    formElementText: '#4a4a4a',
    formElementPlaceholder: '#aaaaaa',
    formElementError: '#ca3f3f',
    formElementFontSize: '12px',
    defaultText: '#686868',
    tooltipBackground: '#2b2b2b',

    libraryApp: '#28aa90',
    distributionApp: '#e6481a',
    earningsApp: '#aa256d',
    analyticsApp: '#288eaa',
    warningLabel: '#ffdcca',

    menu: '#fff',
    menuText: '#111',

    audiomatch: '#901569',
  },

  /* Heights & Widths */
  sidebarMaxWidth: '260px',
  sidebarMinWidth: '50px',
  headerHeight: '65px',
  snackbarItemHeight: '45px',
};

export const defaultTheme = (ancestorTheme: IctinusTheme) => ({
  ...ancestorTheme,
  ...theme,
});

/* Declare any static variables here e.g. $gray: #888; */
export default theme;

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  almostLaptopL: '1439px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

import styled from '@emotion/styled';
import { grid } from 'src/theme/functions';

export const Wrapper = styled.div`
  ${grid};
  grid-template-columns: repeat(16, 1fr);
`;

export const ContentWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 15;
  align-self: center;

  @media only screen and (max-width: 1439px) {
    grid-column-start: 2;
    grid-column-end: 16;
  }
`;

export const FormWrapper = styled.div`
  padding: 20px;
`;

import { Icon } from '@orfium/ictinus';
import React from 'react';
import Tooltip from 'src/components/Tooltip';

import { GuidelinesContainer } from './Guidelines.style';

type Props = {
  tooltipContent: string;
};

const Guidelines = ({ tooltipContent }: Props) => {
  return (
    <Tooltip content={tooltipContent} placement={'bottom'}>
      <GuidelinesContainer>
        <Icon name={'info'} color={'vividBlue'} />
        File Guidelines
      </GuidelinesContainer>
    </Tooltip>
  );
};

export default Guidelines;

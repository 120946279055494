import head from 'lodash/head';

import { Anything } from '../../../utils/globalTypes';
import { FiltersState, LastResponseOptions } from '../types';
import * as types from './types';

const buildOptions = (arr: types.SortType[]) =>
  arr.map((item) => ({
    value: item.value,
    label: item.text,
    options: item.options.map((opt: LastResponseOptions) => ({
      value: opt.value,
      label: opt.text,
      selected: Boolean(opt.default),
    })),
  }));

export const normalizationData = (
  response: types.ResponseType,
  strategy?: string
): FiltersState => {
  const newObj: FiltersState = {
    actions: [],
    queues: [],
    filters: [],
  };

  newObj.filters = Object.keys(response.filters).map((item) => ({
    strategy: item,
    selected: item === strategy,
    filters: !response.filters[item] ? [] : buildOptions(response.filters[item]),
    sort: !response.sort[item] ? [] : buildOptions(response.sort[item]),
  }));

  if (response.actions) {
    const actionKey = head(Object.keys(response.actions));
    newObj.actions = (
      strategy ? response.actions[strategy] : actionKey ? response.actions[actionKey] : []
    ).map((item: Anything) => ({
      value: item.value,
      type: item.type,
      label: item.text,
    }));
  }

  if (response.queues) {
    newObj.queues = response.queues.map((item) => ({
      value: item.id.toString(),
      selected: Boolean(item.default),
      label: item.display_name,
    }));
  }
  return newObj;
};

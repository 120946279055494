import React from 'react';

import { LabelClass } from './Label.style';

type Props = { dark?: boolean };

const Label: React.FC<Props & React.HTMLProps<HTMLLabelElement>> = ({ children, dark, htmlFor }) =>
  children ? (
    <LabelClass dark={dark} htmlFor={htmlFor}>
      {children}
    </LabelClass>
  ) : null;

export default Label;

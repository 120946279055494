/**
 * takes a time in seconds and formats it to HH:mm:ss
 *
 * timeInSeconds {Number} the time in seconds
 * @returns {String} The time with the correct format
 */
export const secondsToTime = (timeInSeconds: number, showHours = true): string => {
  if (!timeInSeconds) {
    return '00:00';
  }
  // a function to pad number passed with zero digits if smaller
  const pad = (num: number, size: number) => ('000' + num).slice(size * -1);
  const secondsPerHour = 3600;
  const parsedTime = parseInt(timeInSeconds.toFixed(0), 10);
  const hours = Math.floor(parsedTime / secondsPerHour);
  const minutes = Math.floor((parsedTime - hours * secondsPerHour) / 60);
  const seconds = parsedTime - hours * secondsPerHour - minutes * 60;

  return (
    (showHours || hours > 0 ? (hours >= 100 ? hours : pad(hours, 2)) + ':' : '') +
    pad(minutes, 2) +
    ':' +
    pad(seconds, 2)
  );
};
/**
 * takes a time in seconds and formats it to ## m.
 *
 * secondsToMinutes {Number} the time in seconds
 * @returns {String} The time with the correct format
 */
export const secondsToMinutes = (timeInSeconds: number): string => {
  if (!timeInSeconds) {
    return '0';
  }

  const secondsPerHour = 3600;
  const parsedTime = parseInt(timeInSeconds.toFixed(0), 10);
  const hours = Math.floor(parsedTime / secondsPerHour);
  const minutes = Math.floor((parsedTime - hours * secondsPerHour) / 60);

  return `${minutes} minute${minutes > 1 ? 's' : ''}`;
};

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';

export const __REDUX_STATE_KEY__ = 'filters';
export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getFilters = (state: AppState) => getReduxStateSlice(state);

export const getStrategies = (state: AppState) => getReduxStateSlice(state).strategies;

export const getSort = (state: AppState) => getReduxStateSlice(state).sort;

export const getFilterOptions = (state: AppState) => getReduxStateSlice(state).filters;

export const getAddedFilters = (state: AppState) => getReduxStateSlice(state).added;

export const getSelectedStrategy = (state: AppState) =>
  getStrategies(state).find((strategy) => strategy.value === getReduxStateSlice(state).strategy);

export const filterLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

import get from 'lodash/get';
import { ofType, ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { pushAlert, ERROR_TYPE } from 'src/ducks/ui/alert';
import { ALERTS } from 'src/utils/constants';

import { ActionCreateError, CREATE_ERROR } from './types';

const generalErrorHandlingEpic = (action$: ActionsObservable<ActionCreateError>) =>
  action$.pipe(
    ofType(CREATE_ERROR),
    mergeMap(({ payload }) =>
      of(
        pushAlert({
          type: ALERTS.GLOBAL,
          props: {
            type: ERROR_TYPE,
            messages: get(payload, '_error'),
          },
        })
      )
    )
  );

export default {
  generalErrorHandlingEpic,
};

import { LeadsGenerationCountResponse } from 'src/ducks/leadsGeneration';

import { METHODS, requestV3 } from '../axiosInstances';

export default {
  get: <T>({ url = '', ...params } = {}) => requestV3<T>(METHODS.GET, `/leads/${url}/`, { params }),
  getCount: ({ url = '', ...params } = {}) =>
    requestV3<LeadsGenerationCountResponse>(METHODS.GET, `/leads/${url}/count/`, { params }),
  action: <T>(params = {}) => requestV3<T>(METHODS.PUT, `/leads/action/`, { params }),
};

import get from 'lodash/get';
import urls from 'src/urls';
import { OPERATIONS } from 'src/utils/constants';

import { Ability, User, Account } from './types';

/**
 * This function name 'can' takes the an action, url and the ability of this in order to fetch if this has the ability to access it
 * I: the actions requested
 * This: the url or operation of what is requeste
 * ability: the ability returned based on what is being calculated from abilityBuilder
 **/
export const can = ({
  I,
  This,
  ability,
}: {
  I: string;
  This: string;
  ability: Ability | undefined;
}) => {
  return Boolean(get(get(ability, I), This, false));
};

/**
 * It return an object that defines for each url/operation a boolean that indicates that this can be accessed for READ,WRITE etc
 **/
export const abilityBuilder = (userInfo: User) => {
  const selectedAccount = userInfo.accounts.find(({ selected }) => selected) as Account;
  const isAllClientSelected = selectedAccount?.id === 0;
  const allClientsUrlThatCanShow = [urls.reviewHistory(), urls.globalReview()];
  const demoAccountUrlThatCanShow = [urls.reviewQueue(), urls.confirmedMatches()];

  // Demo accounts in staging have this group name. Used like this till we get another predicate from backend.
  const isDemoAccount = !!userInfo.groups.find(
    (group) => group.name === 'youtube compliance demo users'
  );

  const allUrlsToCheck = [
    urls.globalReview(),
    urls.leadsGeneration(),
    urls.reviewQueue(),
    urls.reviewHistory(),
    urls.claimerAnalytics(),
    urls.claimAnalytics(),
    urls.confirmedMatches(),
    urls.systemManagement(),
    urls.assetsManagement(),
    urls.channelsManagement(),
    urls.legacyClaims(),
    urls.keywordManagement(),
    urls.claimList(),
  ];

  const buildedUrlsWithAccess = allUrlsToCheck.reduce<{ [index: string]: boolean }>((acc, url) => {
    acc[url] = isDemoAccount
      ? demoAccountUrlThatCanShow.includes(url)
      : isAllClientSelected
      ? allClientsUrlThatCanShow.includes(url)
      : true;

    return acc;
  }, {});

  return {
    [OPERATIONS.READ]: {
      ...buildedUrlsWithAccess,
      adminLink: !isDemoAccount,
      policyLinks: isDemoAccount,
    },
  } as Ability;
};

export const pickLandingPage = (ability: Ability | undefined) => {
  if (can({ I: OPERATIONS.READ, This: urls.globalReview(), ability })) {
    return `${urls.globalReview()}?strategy=all`;
  } else if (can({ I: OPERATIONS.READ, This: urls.reviewQueue(), ability })) {
    return urls.reviewQueue();
  }
  return `${urls.globalReview()}?strategy=all`;
};

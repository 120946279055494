import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { SET_PAGINATION_PAGE, SetPaginationPageAction } from 'src/ducks/factories/pagination/types';
import {
  CHANGE_FILTERS,
  CHANGE_SORT,
  ChangeFilterAction,
  ChangeSortAction,
  FETCH_FILTERS_SUCCESS,
  FetchFiltersSuccessAction,
  getFilterOptions,
  getSelectedStrategy,
  getSort,
  Strategy,
} from 'src/ducks/filters';
import { handleParams } from 'src/ducks/filters/utils';
import {
  fetchGlobalReviewsFail,
  setGlobalReviews,
  globalReviewsLoadingActions,
  setGlobalReviewsCount,
  globalReviewsPaginationActions,
  fetchGlobalReviewsCountRequest,
  fetchGlobalReviewsRequest,
  removeGlobalReviews,
  fetchGlobalReviewsCountFail,
} from 'src/ducks/globalReview/actions';
import {
  __REDUX_STATE_KEY__,
  getGlobalReviews,
  getGlobalReviewsCount,
  getGlobalReviewsIsCountError,
  getGlobalReviewsIsCountLoading,
  globalReviewsPaginationSelectors,
} from 'src/ducks/globalReview/selectors';
import {
  FETCH_GLOBAL_REVIEWS_COUNT_REQUEST,
  FETCH_GLOBAL_REVIEWS_REQUEST,
  FetchGlobalReviewsCountRequestAction,
  FetchGlobalReviewsRequestAction,
  REFETCH_GLOBAL_REVIEWS,
  RefetchGlobalReviewsAction,
  REMOVE_GLOBAL_REVIEWS,
  RemoveGlobalReviewsAction,
  TRIGGER_GLOBAL_REVIEWS_ACTION,
  TriggerGlobalReviewsAction,
} from 'src/ducks/globalReview/types';
import { getGlobalSearch, SET_GLOBAL_SEARCH, SetGlobalSearchAction } from 'src/ducks/globalSearch';
import { ERROR_TYPE, pushAlert, SUCCESS_TYPE } from 'src/ducks/ui/alert';
import { globalReviewAPI } from 'src/providers';
import { ALERTS, GlobalReviewPaginationLimit, REVIEW_PAGES } from 'src/utils/constants';

const prepareParams = (state: AppState, selected?: Strategy, pageToFetch?: number) => {
  const filters = getFilterOptions(state);
  const sort = getSort(state);
  const q = getGlobalSearch(state);

  return handleParams(filters, sort, selected, q, pageToFetch);
};

const triggerFetchActionsBasedOnStateFilters = (state: AppState, pageToFetch?: number) => {
  const selected = getSelectedStrategy(state);

  if (selected?.value && !Object.values<string>(REVIEW_PAGES).includes(selected?.value as string)) {
    return of();
  }

  const params = prepareParams(state, selected, pageToFetch || 1);

  return pageToFetch
    ? concat(
        of(globalReviewsPaginationActions.setPaginationPage(params.page)),
        of(fetchGlobalReviewsCountRequest(params))
      )
    : concat(
        of(setGlobalReviews([])),
        of(
          setGlobalReviewsCount({
            count: 0,
            estimate: true,
            isLoading: true,
            isError: false,
          })
        ),
        of(globalReviewsLoadingActions.setLoading(true)),
        of(globalReviewsPaginationActions.setPaginationPage(1)),
        of(fetchGlobalReviewsCountRequest(params))
      );
};

//TODO: On FetchFiltersSuccessAction maybe check to keep the page from the url.
const filtersSucceededEpic = (
  action$: ActionsObservable<
    | FetchFiltersSuccessAction
    | ChangeFilterAction
    | ChangeSortAction
    | SetGlobalSearchAction
    | RefetchGlobalReviewsAction
  >,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(
      FETCH_FILTERS_SUCCESS,
      CHANGE_FILTERS,
      CHANGE_SORT,
      SET_GLOBAL_SEARCH,
      REFETCH_GLOBAL_REVIEWS
    ),
    switchMap(() => {
      return triggerFetchActionsBasedOnStateFilters(state$.value);
    })
  );

const fetchGlobalReviewEpic = (
  action$: ActionsObservable<FetchGlobalReviewsRequestAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_GLOBAL_REVIEWS_REQUEST),
    switchMap(({ payload }) => {
      return concat(
        of(globalReviewsLoadingActions.setLoading(true)),
        from(globalReviewAPI.single.get({ ...payload }).request()).pipe(
          mergeMap((data) => {
            // If count is not yet fetched but the table data are, display the data and loading instead
            // of count to unblock the users from pointlessly waiting for the total count.
            const count = getGlobalReviewsCount(state$.value);
            const isLoading = getGlobalReviewsIsCountLoading(state$.value);
            const isError = getGlobalReviewsIsCountError(state$.value);

            if (count !== 0 && !isLoading && !isError) {
              return of(setGlobalReviews(data));
            }

            const mockCount =
              data.length === GlobalReviewPaginationLimit
                ? payload.page * data.length + 1
                : data.length;

            return of(
              setGlobalReviews(data),
              setGlobalReviewsCount({
                count: mockCount,
                estimate: true,
                isLoading: true,
                isError,
              }),
              globalReviewsPaginationActions.setPaginationTotalPages(
                Math.ceil(mockCount / GlobalReviewPaginationLimit)
              )
            );
          }),
          catchError(() => of(fetchGlobalReviewsFail()))
        ),
        of(globalReviewsLoadingActions.setLoading(false))
      );
    })
  );

const fetchGlobalReviewCountEpic = (
  action$: ActionsObservable<FetchGlobalReviewsCountRequestAction>
) =>
  action$.pipe(
    ofType(FETCH_GLOBAL_REVIEWS_COUNT_REQUEST),
    switchMap(({ payload }) =>
      from(
        globalReviewAPI.single
          .getCount({
            ...payload,
          })
          .request()
      ).pipe(
        mergeMap((data) =>
          of(
            setGlobalReviewsCount({ ...data, isLoading: false, isError: false }),
            globalReviewsPaginationActions.setPaginationTotalPages(
              Math.ceil(data.count / GlobalReviewPaginationLimit)
            )
          )
        ),
        catchError(() => of(fetchGlobalReviewsCountFail()))
      )
    )
  );

const changePageEpic = (
  action$: ActionsObservable<SetPaginationPageAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REDUX_STATE_KEY__)),
    switchMap(({ payload }) => {
      const selected = getSelectedStrategy(state$.value);

      if (
        (selected?.value &&
          !Object.values<string>(REVIEW_PAGES).includes(selected?.value as string)) ||
        !selected
      ) {
        return of();
      }

      const params = prepareParams(state$.value, selected, payload);

      return concat(
        of(globalReviewsLoadingActions.setLoading(true)),
        of(fetchGlobalReviewsRequest(params)),
        of(fetchGlobalReviewsCountRequest(params)),
        of(globalReviewsLoadingActions.setLoading(false))
      );
    })
  );

const actionEpic = (
  action$: ActionsObservable<TriggerGlobalReviewsAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(TRIGGER_GLOBAL_REVIEWS_ACTION),
    switchMap(({ payload: { action, video_ids } }) =>
      concat(
        of(globalReviewsLoadingActions.setLoading(true)),
        from(
          globalReviewAPI.single
            .action({
              video_ids,
              strategy: getSelectedStrategy(state$.value)?.value,
              action,
            })
            .request()
        ).pipe(
          mergeMap(() =>
            of(
              removeGlobalReviews(video_ids),
              pushAlert({
                type: ALERTS.GLOBAL,
                props: {
                  type: SUCCESS_TYPE,
                  messages: [`${video_ids.length} item(s) have been hidden.`],
                },
              })
            )
          ),
          catchError(() =>
            of(
              pushAlert({
                type: ALERTS.GLOBAL,
                props: {
                  type: ERROR_TYPE,
                  messages: [`Something went wrong! Item(s) were not hidden. Please try again.`],
                },
              }),
              globalReviewsLoadingActions.setLoading(false)
            )
          )
        )
      )
    )
  );

const removeGlobalReviewEpic = (
  action$: ActionsObservable<RemoveGlobalReviewsAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(REMOVE_GLOBAL_REVIEWS),
    mergeMap(() => {
      const page = globalReviewsPaginationSelectors.getPaginationPage(state$.value);
      const list = getGlobalReviews(state$.value);
      const countItems = getGlobalReviewsCount(state$.value);

      if (countItems !== 0) {
        if (list.length === 0) {
          return page > 1
            ? triggerFetchActionsBasedOnStateFilters(state$.value, page - 1)
            : triggerFetchActionsBasedOnStateFilters(state$.value);
        } else if (list.length !== GlobalReviewPaginationLimit) {
          return triggerFetchActionsBasedOnStateFilters(state$.value, page);
        }
      }

      return of();
    })
  );

export default {
  filtersSucceededEpic,
  fetchGlobalReviewEpic,
  fetchGlobalReviewCountEpic,
  changePageEpic,
  actionEpic,
  removeGlobalReviewEpic,
};

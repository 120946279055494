import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { withSearchActions } from 'src/ducks/factories/search';

import { simpleAction } from '../utils';
import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_ASSETS_FAILURE,
  FETCH_ASSETS_REQUEST,
  SET_ASSETS,
  UPDATE_ASSETS,
  SET_SELECTED_ASSETS,
  UPDATE_SELECTED_ASSETS,
  FETCH_ASSETS_COUNT_REQUEST,
  SET_ASSETS_COUNT,
  FetchAssetsRequestAction,
  FetchAssetsRequestFailureAction,
  FetchAssetsResponseType,
  SetAssetsAction,
  SetSelectedAssetsAction,
  UpdateSelectedAssetsAction,
  UpdateAssetsAction,
  UpdateAssetsType,
  FetchAssetsCountRequestAction,
  AssetsManagementParams,
} from './types';

export const setAssets = (payload: FetchAssetsResponseType): SetAssetsAction =>
  simpleAction(SET_ASSETS, payload);

export const fetchAssetsRequest = (params: AssetsManagementParams): FetchAssetsRequestAction =>
  simpleAction(FETCH_ASSETS_REQUEST, params);

export const fetchAssetsFailure = (): FetchAssetsRequestFailureAction =>
  simpleAction(FETCH_ASSETS_FAILURE);

export const fetchAssetsCountRequest = (
  params: AssetsManagementParams
): FetchAssetsCountRequestAction => simpleAction(FETCH_ASSETS_COUNT_REQUEST, params);

export const setAssetsCount = (data: number): { type: string; payload: number } =>
  simpleAction(SET_ASSETS_COUNT, data);

export const updateAssets = (data: UpdateAssetsType): UpdateAssetsAction =>
  simpleAction(UPDATE_ASSETS, data);

export const setSelectedAssets = (payload: number[]): SetSelectedAssetsAction =>
  simpleAction(SET_SELECTED_ASSETS, payload);

export const updateSelectedAssets = (payload: number[]): UpdateSelectedAssetsAction =>
  simpleAction(UPDATE_SELECTED_ASSETS, payload);

export const assetsManagementLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const assetsManagementPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

export const assetsManagementSearchActions = withSearchActions(__REDUX_STATE_KEY__);

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { AppState } from 'src/ducks';
import { getSidebarOpenState, openSidebar, closeSidebar } from 'src/ducks/ui/sidebar';
import { getAbility } from 'src/ducks/user';

import Sidebar, { ReduxActions, ReduxProps } from './Sidebar';

const mapStateToProps = (state: AppState) => ({
  sidebarIsOpen: getSidebarOpenState(state),
  ability: getAbility(state),
});

const mapDispatchToProps = { openSidebar, closeSidebar };

export default compose(
  withRouter,
  connect<ReduxProps, ReduxActions, unknown, AppState>(mapStateToProps, mapDispatchToProps)
)(Sidebar) as React.ComponentType;

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setReviewHistory, ReviewHistoryUpdateData } from 'src/ducks/reviewHistory';
import { withOverlay } from 'src/hoc';
import { FORMS } from 'src/utils/constants';

import ReviewHistoryEditAsset from './ReviewHistoryEditAsset';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: ReviewHistoryUpdateData) => {
    dispatch(setReviewHistory({ formName: FORMS.REVIEW_HISTORY_EDIT_ASSET, ...values }));
  },
});

export default withOverlay({
  overlayId: FORMS.REVIEW_HISTORY_EDIT_ASSET,
  warnBeforeUnmount: false,
})(connect(null, mapDispatchToProps)(ReviewHistoryEditAsset));

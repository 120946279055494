import startCase from 'lodash/startCase';
import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';
import { withSearchReducer } from 'src/ducks/factories/search';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  ClaimActionTypes,
  ClaimReducer,
  SET_CLAIMS,
  SET_CLAIMS_COUNT,
  SET_CLAIMS_SUMMARY,
  Summary,
} from './types';

const initialState: ClaimReducer = {
  list: [],
  countItems: 0,
  estimate: false,
  summary: {},
  exportedDate: '',
};

function reducer(state = initialState, action: ClaimActionTypes): ClaimReducer {
  switch (action.type) {
    case SET_CLAIMS:
      return {
        ...state,
        list: action.payload,
      };
    case SET_CLAIMS_COUNT:
      return {
        ...state,
        countItems: action.payload.count,
        estimate: Boolean(action.payload.estimate),
      };
    case SET_CLAIMS_SUMMARY:
      return {
        ...state,
        summary: Object.keys(action.payload.strategies_info)
          .sort((a, b) => a.localeCompare(b))
          .reduce<Partial<Summary>>((acc, sumKey) => {
            acc[startCase(sumKey)] = action.payload.strategies_info[sumKey];

            return acc;
          }, {}),
        exportedDate: action.payload.exported_date,
      };
    default:
      return state;
  }
}

export default compose(
  withSearchReducer<ClaimReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withPaginationReducer<ClaimReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withLoadingReducer<ClaimReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(reducer);

import React from 'react';
import Container from 'src/components/Container';
import ReviewHistoryForm from 'src/components/Forms/ReviewHistoryForm';
import { ReviewHistoryUpdateData, ReviewHistoryAsset } from 'src/ducks/reviewHistory';

import { Anything } from '../../utils/globalTypes';
import { Wrapper, ContentWrapper, FormWrapper } from './ReviewHistoryEditAsset.style';

type Props = {
  onSubmit(data: ReviewHistoryUpdateData): void;
  closeOverlay(): void;
  asset: ReviewHistoryAsset;
  getVideoDuration: () => number;
};

const ReviewHistoryEditAsset: React.FC<Props> = ({ closeOverlay, asset, onSubmit }) => {
  return (
    <Container centered>
      <Wrapper>
        <ContentWrapper>
          <FormWrapper>
            <ReviewHistoryForm
              onSubmit={({ otherAssets }: Anything) => {
                onSubmit({
                  video_id: asset.video.video_id,
                  action: otherAssets[0].action ? otherAssets[0].action.value : '',
                  asset_id: otherAssets[0].option.value,
                  matches: otherAssets[0].matches,
                });
              }}
              triggerClose={closeOverlay}
              asset={asset}
            />
          </FormWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default ReviewHistoryEditAsset;

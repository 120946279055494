import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import {
  fetchAssetsRequest,
  fetchAssetsCountRequest,
  assetsManagementPaginationActions,
  assetsManagementSearchSelectors,
} from 'src/ducks/assetsManagement';
import {
  fetchChannelsRequest,
  fetchChannelsCountRequest,
  channelsManagementPaginationActions,
  channelsManagementSearchSelectors,
} from 'src/ducks/channelsManagement';
import {
  fetchConfirmedMatchesCountRequest,
  fetchConfirmedMatchesRequest,
  confirmedMatchesPaginationActions,
  confirmedMatchesSearchSelectors,
} from 'src/ducks/confirmedMatches';
import {
  fetchLegacyClaimsRequest,
  fetchLegacyClaimsCountRequest,
  legacyClaimsPaginationActions,
  legacyClaimsSearchSelectors,
} from 'src/ducks/legacyClaims';
import { getFilters } from 'src/ducks/legacyFilters';
import { normalizationData } from 'src/ducks/legacyFilters/normalization/utils';
import {
  fetchReviewsCountRequest,
  fetchReviewsRequest,
  reviewPaginationActions,
  searchSelectors,
} from 'src/ducks/review';
import { legacyFiltersAPI } from 'src/providers';
import { apiPagesMap, PAGES, PagesType } from 'src/utils/constants';

import { Anything } from '../../utils/globalTypes';
import { fetchFiltersFail, fetchFiltersSuccess, filterLoadingActions } from './actions';
import {
  CHANGE_DYNAMIC_FILTER,
  CHANGE_LEGACY_FILTERS,
  FETCH_LEGACY_FILTERS,
  FetchFiltersAction,
  FiltersActionTypes,
} from './types';
import { handleReviewParams, combineNormData, handleStrategiesParams } from './utils';

type StrategiesFunc = {
  // TODO fix these definitions
  // eslint-disable-next-line @typescript-eslint/ban-types
  provider: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getData: Function[];
  paginationsActions: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    resetPagination: Function;
  };
  searchSelectors: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    getSearchValue: Function;
  };
};

type StrategiesFuncsMap = {
  [index: string]: StrategiesFunc;
};

type getDataProps = {
  data?: Anything;
  page?: number;
  searchValue?: Anything;
};

const mappedStrategies = (value: PagesType) => {
  const strategiesFuncsMap: StrategiesFuncsMap = {
    [PAGES.ASSET]: {
      provider: (data: Anything) => legacyFiltersAPI.single.options(data),
      getData: [
        ({ data, page, searchValue }: getDataProps) =>
          fetchReviewsRequest(handleReviewParams(data, page, searchValue)),
        ({ data, page, searchValue }: getDataProps) =>
          fetchReviewsCountRequest(handleReviewParams(data, page, searchValue)),
        () => reviewPaginationActions.resetPagination(),
      ],
      paginationsActions: reviewPaginationActions,
      searchSelectors: searchSelectors,
    },
    [PAGES.CONFIRMED_MATCHES]: {
      provider: (data: Anything) => legacyFiltersAPI.single.options(data),
      getData: [
        ({ data, page, searchValue }: getDataProps) =>
          fetchConfirmedMatchesRequest(handleReviewParams(data, page, searchValue)),
        ({ data, page, searchValue }: getDataProps) =>
          fetchConfirmedMatchesCountRequest(handleReviewParams(data, page, searchValue)),
        () => confirmedMatchesPaginationActions.resetPagination(),
      ],
      paginationsActions: confirmedMatchesPaginationActions,
      searchSelectors: confirmedMatchesSearchSelectors,
    },
    [PAGES.ASSETS_MANAGEMENT]: {
      provider: (data: Anything) => legacyFiltersAPI.single.options(data),
      getData: [
        ({ data, page, searchValue }: getDataProps) =>
          fetchAssetsRequest(handleStrategiesParams(data, page, searchValue)),
        ({ data, page, searchValue }: getDataProps) =>
          fetchAssetsCountRequest(handleStrategiesParams(data, page, searchValue)),
        () => assetsManagementPaginationActions.resetPagination(),
      ],
      paginationsActions: assetsManagementPaginationActions,
      searchSelectors: assetsManagementSearchSelectors,
    },
    [PAGES.LEGACY_CLAIMS]: {
      provider: (data: Anything) => legacyFiltersAPI.single.options(data),
      getData: [
        ({ data, page, searchValue }: getDataProps) =>
          fetchLegacyClaimsRequest(handleStrategiesParams(data, page, searchValue)),
        ({ data, page, searchValue }: getDataProps) =>
          fetchLegacyClaimsCountRequest(handleStrategiesParams(data, page, searchValue)),
        () => legacyClaimsPaginationActions.resetPagination(),
      ],
      paginationsActions: legacyClaimsPaginationActions,
      searchSelectors: legacyClaimsSearchSelectors,
    },
    [PAGES.CHANNELS_MANAGEMENT]: {
      provider: (data: Anything) => legacyFiltersAPI.single.options(data),
      getData: [
        ({ data, page, searchValue }: getDataProps) =>
          fetchChannelsRequest(handleStrategiesParams(data, page, searchValue)),
        ({ data, page, searchValue }: getDataProps) =>
          fetchChannelsCountRequest(handleStrategiesParams(data, page, searchValue)),
        () => channelsManagementPaginationActions.resetPagination(),
      ],
      paginationsActions: channelsManagementPaginationActions,
      searchSelectors: channelsManagementSearchSelectors,
    },
  };
  return get(strategiesFuncsMap, value);
};

const fetchFiltersEpic = (
  action$: ActionsObservable<FetchFiltersAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_LEGACY_FILTERS),
    switchMap(({ payload: { strategy, loading = true } }) => {
      const mappedStrategy = mappedStrategies((strategy as PagesType) || PAGES.ASSET);
      return concat(
        of(filterLoadingActions.setLoading(loading)),
        from(mappedStrategy.provider({ type: apiPagesMap(strategy) }).request()).pipe(
          mergeMap((data: Anything) => {
            const normData = normalizationData(data, strategy);
            // We are doing this because we want to keep the user-selected filters when the user refresh it's pages
            // but also to add the potentially new filters from the API
            // On confirmed matches page there would be ‘no queues’ section thus the array will be empty
            const flatData = flatMap(pick(getFilters(state$.value), ['queues', 'filters']));

            const persistedState = merge({}, getFilters(state$.value));

            const newNormData = flatData.length
              ? combineNormData(persistedState, normData, strategy)
              : normData;

            return of(
              fetchFiltersSuccess(newNormData),
              ...mappedStrategy.getData.map((action) =>
                action({
                  data: newNormData,
                  page: 1,
                  searchValue: mappedStrategy.searchSelectors.getSearchValue(state$.value),
                })
              )
            );
          }),
          catchError(() =>
            of(fetchFiltersFail(), mappedStrategy.paginationsActions.resetPagination())
          )
        ),
        of(filterLoadingActions.setLoading(false))
      );
    })
  );

const changeFiltersEpic = (
  action$: ActionsObservable<FiltersActionTypes>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(CHANGE_LEGACY_FILTERS, CHANGE_DYNAMIC_FILTER),
    switchMap(({ payload: { strategy } }) => {
      const mappedStrategy = mappedStrategies(strategy ? (strategy as PagesType) : PAGES.ASSET);

      return concat(
        from(mappedStrategy.provider({ type: apiPagesMap(strategy) }).request()).pipe(
          mergeMap(() => {
            return of(
              ...mappedStrategy.getData.map((action) =>
                action({
                  data: getFilters(state$.value),
                  page: 1,
                  searchValue: mappedStrategy.searchSelectors.getSearchValue(state$.value),
                })
              )
            );
          }),
          catchError(() =>
            of(fetchFiltersFail(), mappedStrategy.paginationsActions.resetPagination())
          )
        )
      );
    })
  );

export default {
  changeFiltersEpic,
  fetchFiltersEpic,
};

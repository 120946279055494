import { SimpleAction } from 'src/ducks/utils';

export const OPEN_SIDEBAR: string = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR: string = 'CLOSE_SIDEBAR';

export type ActionTypes = typeof OPEN_SIDEBAR | typeof CLOSE_SIDEBAR;

export type SidebarAction = SimpleAction<ActionTypes, Sidebar>;

export interface Sidebar {
  open: boolean;
}

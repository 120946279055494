import { compose } from 'redux';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { withPaginationReducer } from 'src/ducks/factories/pagination';
import { withSearchReducer } from 'src/ducks/factories/search';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  ChannelsActionTypes,
  ChannelsManagementReducer,
  FETCH_CHANNELS_FAILURE,
  SET_CHANNELS,
  SET_CHANNELS_COUNT,
  UPDATE_SELECTED_CHANNELS,
} from './types';

const initialState: ChannelsManagementReducer = {
  list: [],
  countItems: 0,
  selected: [],
};

function channelManagement(
  state = initialState,
  action: ChannelsActionTypes
): ChannelsManagementReducer {
  switch (action.type) {
    case SET_CHANNELS:
      return {
        ...state,
        list: action.payload.results,
      };
    case SET_CHANNELS_COUNT:
      return {
        ...state,
        countItems: action.payload,
      };
    case FETCH_CHANNELS_FAILURE:
      return {
        ...state,
        list: [],
      };
    case UPDATE_SELECTED_CHANNELS:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
}

export default compose(
  withSearchReducer<ChannelsManagementReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withPaginationReducer<ChannelsManagementReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  }),
  withLoadingReducer<ChannelsManagementReducer>({
    prefix: __REDUX_STATE_KEY__,
    baseReducerState: initialState,
  })
)(channelManagement);

import { Icon } from '@orfium/ictinus';
import React from 'react';

import { Wrapper, IconWrapper, MessageWrapper, Message } from './InlineAlert.style';

export type Props = {
  type: 'error' | 'success';
  onClose?: () => void;
};

const InlineAlert: React.FC<Props> = ({ children, type, onClose }) => {
  const iconName = type === 'error' ? 'closeTag' : 'success';
  return (
    <Wrapper type={type}>
      <MessageWrapper>
        <IconWrapper type={type} data-testid={iconName}>
          <Icon size={20} name={iconName} />
        </IconWrapper>
        <Message>{children}</Message>
      </MessageWrapper>
      {type === 'error' ? (
        <IconWrapper type={'close'} onClick={onClose} pointer={Boolean(onClose)}>
          <Icon size={20} name={'close'} />
        </IconWrapper>
      ) : null}
    </Wrapper>
  );
};

export default InlineAlert;

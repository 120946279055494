import { Anything } from '../../utils/globalTypes';

export const FETCH_REVIEWS_REQUEST = 'FETCH_REVIEWS_REQUEST';
export const SET_REVIEWS = 'SET_REVIEWS';
export const UPDATE_REVIEW_REQUEST = 'UPDATE_REVIEW_REQUEST';
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE';
export const FETCH_REVIEWSCOUNT_REQUEST = 'FETCH_REVIEWSCOUNT_REQUEST';
export const SET_REVIEWSCOUNT = 'SET_REVIEWSCOUNT';
export const REMOVE_REVIEW = 'REMOVE_REVIEW';
export const SET_PAGE = 'SET_PAGE';
export const RESET_REVIEW_PAGINATION = 'RESET_REVIEW_PAGINATION';
export const SHOW_UNDO_BUTTON = 'SHOW_UNDO_BUTTON';
export const UNDO_ACTION = 'UNDO_ACTION';

export interface UndoAction {
  type: typeof UNDO_ACTION;
  payload: string;
}

export interface SetReviewsAction {
  type: typeof SET_REVIEWS;
  payload: Review[];
}

export interface RemoveReviewAction {
  type: typeof REMOVE_REVIEW;
  payload: number;
}

export interface SetReviewsCountAction {
  type: typeof SET_REVIEWSCOUNT;
  payload: number;
}

export interface SetPageAction {
  type: typeof SET_PAGE;
  payload: number;
}

export interface FetchReviewsRequestAction {
  type: typeof FETCH_REVIEWS_REQUEST;
  payload: ReviewParams;
}

export interface FetchReviewsCountRequestAction {
  type: typeof FETCH_REVIEWSCOUNT_REQUEST;
  payload: ReviewParams;
}

export interface FetchReviewsFailureAction {
  type: typeof FETCH_REVIEWS_FAILURE;
}

export interface ResetReviewPagination {
  type: typeof RESET_REVIEW_PAGINATION;
}

export interface UpdateReviewsRequestAction {
  type: typeof UPDATE_REVIEW_REQUEST;
  payload: UpdateReviewData;
}

export interface EnableUndoButton {
  type: typeof SHOW_UNDO_BUTTON;
}

export type ReviewActionTypes =
  | UndoAction
  | EnableUndoButton
  | ResetReviewPagination
  | SetPageAction
  | RemoveReviewAction
  | FetchReviewsFailureAction
  | SetReviewsAction
  | SetReviewsCountAction
  | FetchReviewsRequestAction
  | UpdateReviewsRequestAction;

export type UpdateReviewData = {
  id: number;
  video_id: string;
  actions: Record<string, unknown>[];
  formName?: string;
};
//TODO: REMOVE NULL WHEN API IS FIXED.
export interface ReviewReducer {
  list: Review[];
  countItems: number | null;
  showUndoButton: boolean;
}

export interface Review {
  id: number;
  query: number;
  score: number;
  assigned_to: AssignedTo;
  video: Video;
  asset: Asset;
  matches: MatchesEntity;
}

export interface AssignedTo {
  id: number;
  display_name: string;
}

export interface Video {
  service: string;
  external_id: string;
  uploaded: string;
  title: string;
  description: string;
  total_views: number;
  daily_views: number;
  channel: Channel;
  traffic_views: number;
  watch_time: number;
}

export interface Channel {
  id: string;
  title: string;
}

export interface Segments {
  from: number;
  to: number;
  score: string;
}

export interface Asset {
  title: string;
  artist: string;
  asset_id: string;
  reference_url: string;
}

export interface MatchedAsset {
  title: string;
  artist: string;
  asset_id: string;
  reference_url: string;
  segments?: Segments[];
}

export interface MatchesEntity {
  url?: string;
  type?: string;
  results?: MatchesEntityResult[];
}

export type MatchesEntityResult = {
  score: number;
  asset: MatchedAsset;
};

export type ReviewParams = {
  [key: string]: Anything;
  page: number;
  queue: number;
  sort_by: string;
  strategy: string;
  withLoading: boolean;
};

import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { withSearchActions } from 'src/ducks/factories/search';
import { simpleAction } from 'src/ducks/utils';

import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_GLOBAL_REVIEWS_COUNT_FAILURE,
  FETCH_GLOBAL_REVIEWS_COUNT_REQUEST,
  FETCH_GLOBAL_REVIEWS_FAILURE,
  FETCH_GLOBAL_REVIEWS_REQUEST,
  FetchGlobalReviewsCountFailureAction,
  FetchGlobalReviewsCountRequestAction,
  FetchGlobalReviewsFailureAction,
  FetchGlobalReviewsRequestAction,
  GlobalReview,
  GlobalReviewParams,
  GlobalReviewsTriggerActionParams,
  SET_GLOBAL_REVIEWS,
  SET_GLOBAL_REVIEWS_COUNT,
  SetGlobalReviewsAction,
  SetGlobalReviewsCountAction,
  TRIGGER_GLOBAL_REVIEWS_ACTION,
  TriggerGlobalReviewsAction,
  RemoveGlobalReviewsAction,
  REMOVE_GLOBAL_REVIEWS,
  SetGlobalReviewCount,
  RefetchGlobalReviewsAction,
  REFETCH_GLOBAL_REVIEWS,
} from './types';

export const fetchGlobalReviewsRequest = (
  params: GlobalReviewParams
): FetchGlobalReviewsRequestAction => simpleAction(FETCH_GLOBAL_REVIEWS_REQUEST, params);

export const fetchGlobalReviewsFail = (): FetchGlobalReviewsFailureAction =>
  simpleAction(FETCH_GLOBAL_REVIEWS_FAILURE);

export const setGlobalReviews = (reviews: GlobalReview[]): SetGlobalReviewsAction =>
  simpleAction(SET_GLOBAL_REVIEWS, reviews);

export const removeGlobalReviews = (reviewsIds: (string | number)[]): RemoveGlobalReviewsAction =>
  simpleAction(REMOVE_GLOBAL_REVIEWS, reviewsIds);

export const fetchGlobalReviewsCountRequest = (
  params: GlobalReviewParams
): FetchGlobalReviewsCountRequestAction => simpleAction(FETCH_GLOBAL_REVIEWS_COUNT_REQUEST, params);

export const fetchGlobalReviewsCountFail = (): FetchGlobalReviewsCountFailureAction =>
  simpleAction(FETCH_GLOBAL_REVIEWS_COUNT_FAILURE);

export const setGlobalReviewsCount = (payload: SetGlobalReviewCount): SetGlobalReviewsCountAction =>
  simpleAction(SET_GLOBAL_REVIEWS_COUNT, payload);

export const triggerGlobalReviewsAction = (
  payload: GlobalReviewsTriggerActionParams
): TriggerGlobalReviewsAction => simpleAction(TRIGGER_GLOBAL_REVIEWS_ACTION, payload);

export const refetchGlobalReviewsAction = (): RefetchGlobalReviewsAction =>
  simpleAction(REFETCH_GLOBAL_REVIEWS);

export const globalReviewsLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const globalReviewsPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

export const globalReviewsSearchActions = withSearchActions(__REDUX_STATE_KEY__);

import styled from '@emotion/styled';

type WrapperProps = {
  size?: 'small' | 'big';
};

export const Wrapper = styled.div<WrapperProps>`
  svg {
    width: ${({ size }) => (size === 'small' ? '14px' : '24px')};
    height: ${({ size }) => (size === 'small' ? '20px' : '30px')};
  }
`;

import dayjs from 'dayjs';
import uniqueId from 'lodash/uniqueId';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  ERROR,
  FileTransferItem,
  INFO,
  PROCESSING,
  ProcessingFile,
} from 'src/components/FileTransfer/types';
import { CustomContent } from 'src/components/Upload';
import { AppState } from 'src/ducks';
import { handleStrategiesParams } from 'src/ducks/legacyFilters/utils';
import { pagePicker } from 'src/ducks/utils';

import { useFileTransfer } from '../../components/FileTransfer';
import { fileHandlerAPI } from '../../providers';
import { createDownloadLink } from '../../utils/common';
import { PagesType } from '../../utils/constants';
import {
  DownloadS3CSVPayload,
  DownloadStaticPayload,
  FileHandlerContextProps,
  UploadCSVPayload,
  GetJobProgressPayload,
  DownloadURLPayload,
  FileItem,
} from './types';
import { cancelTokens, mappedContent, mappedPages, TIMEOUT, bytesConverter } from './utils';

const FileHandlerContext = React.createContext<FileHandlerContextProps>({
  downloading: [],
  uploading: [],
  hasItemUploading: () => false,
  uploadS3: () => {},
  downloadS3: () => {},
  downloadUrl: () => {},
  download: () => {},
  jobProgress: () => {},
  cancelUpload: () => {},
  cancelDownload: () => {},
});

FileHandlerContext.displayName = 'FileHandlerContext';

const FileHandlerProvider: React.FC = ({ children }) => {
  const [uploading, setUploading] = useState<FileItem[]>([]);
  const [downloading, setDownloading] = useState<FileItem[]>([]);
  const { items, updateItem, addItems, removeItems, removeUploads } = useFileTransfer();
  const location = useLocation();
  const selectedClient = useSelector((state: AppState) => state.user.accounts).find(
    (client) => client.selected
  )?.name;

  useEffect(() => {
    removeUploads();
  }, [location, removeUploads]);

  const addUploading = (id: FileItem) => setUploading((state) => [...state, id]);

  const removeUploading = (id: string) => setUploading((state) => state.filter((s) => s.id !== id));

  const updateUploading = (item: FileItem) =>
    setUploading((state) => {
      const index = state.findIndex((s) => s.id === item.id);

      if (index !== -1) {
        return [...state.slice(0, index), item, ...state.slice(index + 1)];
      }

      return state;
    });

  const addDownloading = (id: FileItem) => setDownloading((state) => [...state, id]);

  const removeDownloading = (id: string) =>
    setDownloading((state) => state.filter((s) => s.id !== id));

  const updateDownloading = (item: FileItem) =>
    setDownloading((state) => {
      const index = state.findIndex((s) => s.id === item.id);

      if (index !== -1) {
        return [...state.slice(0, index), item, ...state.slice(index + 1)];
      }

      return state;
    });

  const hasItemUploading = useCallback(
    (pageId: string) => Boolean(uploading.find((item) => item.id === pageId)),
    [uploading]
  );

  const errorHandler = (id: string, message: string = 'Upload failed.', fileId?: string) => {
    updateItem(id, (i: FileTransferItem) => {
      if (i.type === PROCESSING) {
        const index = fileId ? i.content.files.findIndex((file) => file.id === fileId) : 0;
        i.content.info = 'An error occured';
        i.content.files[index].message = message;
        i.content.files[index].status = ERROR;
      }
      return i;
    });
  };

  const cancelUpload = (page: PagesType) => {
    const mappedPage = mappedPages(page);

    cancelTokens[page].upload.cancel();
    removeUploading(mappedPage.upload.id);
    removeItems([mappedPage.upload.id]);
  };

  const cancelAllDownloads = (page: PagesType) => {
    const mappedPage = mappedPages(page);

    Object.entries(cancelTokens[page].download).forEach(([key, value]) => {
      value.cancel();
      removeDownloading(key);
    });
    removeItems([mappedPage.download.id]);
  };

  const cancelDownload = (page: PagesType, fileId: string) => {
    const mappedPage = mappedPages(page);

    cancelTokens[page].download[fileId].cancel();

    removeDownloading(fileId);
    updateItem(mappedPage.download.id, (i) => {
      if (i.type === 'processing') {
        const index = i.content.files.findIndex((file) => file.id === fileId);
        i.content.files.splice(index, 1);
      }
      return i;
    });
  };

  const modifyItems = (page: PagesType, files: ProcessingFile[], fileId?: string) => {
    const mappedPage = mappedPages(page as PagesType);
    const itemFound = items.find((item) => item.id === mappedPage.download.id);

    if (!itemFound) {
      addItems([
        {
          id: mappedPage.download.id,
          type: PROCESSING,
          content: {
            id: mappedPage.download.id,
            title: 'Downloading....',
            info: 'Processing download...',
            onClose: () => cancelAllDownloads(page),
            files: files,
          },
        },
      ]);
    } else {
      updateItem(mappedPage.download.id, (i) => {
        if (i.type === 'processing') {
          const exists = fileId ? !!i.content.files.find((file) => file.id === fileId) : !!fileId;
          if (!exists) {
            i.content.info = 'Processing download...';
            i.content.files.push(...files);
          }
        }
        return i;
      });
    }
  };

  const download = async (payload: DownloadStaticPayload) => {
    const { page, url, fileName } = payload;
    let { fileId } = payload;
    const mappedPage = mappedPages(page as PagesType);
    if (!fileId) {
      fileId = uniqueId(mappedPage.download.id);
      addDownloading({ id: fileId, state: 'download' });
    }

    const { request, cancelTokenSource } = fileHandlerAPI.single.download(url, (n, total) => {
      updateItem(mappedPage.download.id, (i) => {
        if (i.type === PROCESSING) {
          const index = i.content.files.findIndex((file) => file.id === fileId);
          i.content.info = 'Downloading...';
          i.content.files[index].message = 'Please wait until downloading has been complete';
          i.content.files[index].status = INFO;
          i.content.files[index].progress = n;
          i.content.files[index].size = bytesConverter(total);
          if (n === 100) {
            i.content.files[index].message = '';
            i.content.info = 'Process completed successfully';
            removeDownloading(i.content.files[index].id);
          }
        }
        return i;
      });
    });

    cancelTokens[page].download[fileId] = cancelTokenSource;

    const formattedFile = {
      id: fileId,
      name: fileName,
      size: '',
      status: INFO,
      progress: 0,
      message: 'Please wait until downloading has been complete',
      isIntermediate: true,
      onCancel: () => cancelDownload(page, fileId as string),
      onClear: () => cancelDownload(page, fileId as string),
    } as ProcessingFile;

    modifyItems(page, [formattedFile], fileId);

    await request()
      .then((data) => {
        updateDownloading({ id: fileId as string, state: 'downloading' });
        createDownloadLink(data, fileName);
        setTimeout(() => cancelDownload(page, fileId as string), TIMEOUT);
      })
      .catch(() => {
        updateUploading({ id: fileId as string, state: 'error' });
        errorHandler(mappedPage.download.id, 'Download failed.', fileId);
      });
  };

  const downloadS3 = (payload: DownloadS3CSVPayload) => {
    const { page, files } = payload;
    const mappedPage = mappedPages(page);

    const formattedFiles = files.map((f) => {
      const fileId = uniqueId(mappedPage.download.id);
      const fileName = f.key;

      return {
        id: fileId,
        name: fileName,
        size: '',
        status: PROCESSING,
        progress: 0,
        message: 'Please wait until downloading has been complete',
        onCancel: () => cancelDownload(page, fileId),
        onClear: () => cancelDownload(page, fileId),
      } as ProcessingFile;
    });

    modifyItems(page, formattedFiles);

    files.forEach(async (f, i) => {
      const { request, cancelTokenSource } = fileHandlerAPI.single.getPresigned(f.key, f.bucket);

      const { id, name } = formattedFiles[i];

      cancelTokens[page].download[id] = cancelTokenSource;

      await request()
        .then((data) => {
          addDownloading({ id, state: 'downloadS3' });
          download({ page, url: data.url, fileName: name, fileId: id });
        })
        .catch(() => {
          updateUploading({ id, state: 'error' });
          errorHandler(mappedPage.download.id, 'Download failed.', id);
        });
    });
  };

  const downloadUrl = async (payload: DownloadURLPayload) => {
    const { filters, page, paginationPage, q } = payload;
    const mappedPage = mappedPages(page);
    const fileId = uniqueId(mappedPage.download.id);
    const fileName = `${page}_${selectedClient}_${dayjs().format('DDMMYYYYHHmmss')}.csv`;

    const params = handleStrategiesParams(filters, pagePicker(paginationPage), q);
    const { page: __ignored, ...rest } = params;

    const { request, cancelTokenSource } = fileHandlerAPI.single.getURL(page, {
      ...rest,
    });

    cancelTokens[page].download[fileId] = cancelTokenSource;

    const formattedFile = {
      id: fileId,
      name: fileName,
      size: '',
      status: PROCESSING,
      progress: 0,
      message: 'Please wait until downloading has been complete',
      onCancel: () => cancelDownload(page, fileId),
      onClear: () => cancelDownload(page, fileId),
    } as ProcessingFile;

    modifyItems(page, [formattedFile]);

    await request()
      .then((data) => {
        addDownloading({ id: fileId, state: 'downloadUrl' });
        download({
          page,
          url: data.s3_download_url,
          fileId,
          fileName,
        });
      })
      .catch(() => {
        updateUploading({ id: fileId, state: 'error' });
        errorHandler(mappedPage.download.id, 'Download failed.', fileId);
      });
  };

  const jobProgress = async (payload: GetJobProgressPayload) => {
    const { page, id } = payload;
    const { request, cancelTokenSource } = fileHandlerAPI.single.getProgress(id);
    const mappedPage = mappedPages(page);

    cancelTokens[page].upload = cancelTokenSource;

    await request()
      .then(async (data) => {
        if (data.status === 'Success') {
          updateUploading({ id: mappedPage.upload.id, state: 'getJobProgressSuccess' });
          const { title, onError, results, onDownload } = mappedContent(page).customContent(
            data,
            downloadS3
          );
          updateItem(mappedPage.upload.id, (i) => {
            if (i.type === PROCESSING) {
              i.content.info = data.message;
              i.content.files[0].message = undefined;
              i.content.files[0].status = INFO;
              i.content.customContent = (
                <CustomContent
                  title={title}
                  onError={onError}
                  results={results}
                  onDownload={onDownload}
                />
              );
            }
            return i;
          });
          setTimeout(() => removeUploading(mappedPage.upload.id), TIMEOUT);
        } else if (data.status === 'Failed') {
          updateUploading({ id: mappedPage.upload.id, state: 'getJobProgressFailed' });
          errorHandler(mappedPage.upload.id, data.message);
        } else {
          updateUploading({ id: mappedPage.upload.id, state: 'getJobProgressTimedout' });
          setTimeout(() => {
            return jobProgress(payload);
          }, TIMEOUT);
        }
      })
      .catch(() => {
        updateUploading({ id: mappedPage.upload.id, state: 'error' });
        removeUploading(mappedPage.upload.id);
        errorHandler(mappedPage.upload.id);
      });
  };

  const uploadS3 = async (payload: UploadCSVPayload) => {
    const { request, cancelTokenSource } = fileHandlerAPI.single.getPresigned();
    const title = `${mappedContent(payload.page).title}  ${selectedClient}`;
    const mappedPage = mappedPages(payload.page as PagesType);
    const files = [
      {
        id: mappedPage.upload.id,
        name: payload.fileName,
        size: bytesConverter(payload.fileSize),
        status: PROCESSING,
        progress: 0,
        message: 'Please wait until file processing is complete',
        isIntermediate: true,
        onCancel: () => cancelUpload(payload.page),
        onClear: () => cancelUpload(payload.page),
      },
    ] as ProcessingFile[];
    addUploading({ id: mappedPage.upload.id, state: 'processing' });
    removeItems([mappedPage.upload.id]);
    addItems([
      {
        id: mappedPage.upload.id,
        type: PROCESSING,
        content: {
          id: mappedPage.upload.id,
          title,
          info: 'Uploading file...',
          onClose: () => {
            cancelUpload(payload.page);
            files.forEach((f) => f.onCancel());
          },
          files,
        },
      },
    ]);
    cancelTokens[payload.page].upload = cancelTokenSource;

    await request()
      .then(async (data) => {
        updateUploading({ id: mappedPage.upload.id, state: 'getPresigned' });
        const { key, bucket, url } = data;

        const { request, cancelTokenSource } = fileHandlerAPI.single.uploadS3(
          url,
          payload.file,
          (n) => {
            updateItem(mappedPage.upload.id, (i) => {
              if (i.type === PROCESSING) {
                i.content.files[0].status = INFO;
                i.content.files[0].progress = n;
              }
              return i;
            });
          }
        );
        cancelTokens[payload.page].upload = cancelTokenSource;

        updateItem(mappedPage.upload.id, (i) => {
          if (i.type === PROCESSING) {
            i.content.files[0].status = PROCESSING;
            i.content.files[0].progress = 0;
          }
          return i;
        });
        await request()
          .then(async () => {
            updateUploading({ id: mappedPage.upload.id, state: 'uploadS3' });
            const { request, cancelTokenSource } = fileHandlerAPI.single.upload({
              bucket,
              key,
              ...mappedPage.upload.getPayload(payload),
            });

            cancelTokens[payload.page].upload = cancelTokenSource;

            updateItem(mappedPage.upload.id, (i) => {
              if (i.type === PROCESSING) {
                i.content.info = 'Validating file...';
                i.content.files[0].status = PROCESSING;
              }

              return i;
            });

            await request()
              .then(async (data) => {
                updateUploading({ id: mappedPage.upload.id, state: 'upload' });
                await jobProgress({ page: payload.page, id: data.job_id });
              })
              .catch(() => {
                updateUploading({ id: mappedPage.upload.id, state: 'error' });
                errorHandler(mappedPage.upload.id);
              });
          })
          .catch(() => {
            updateUploading({ id: mappedPage.upload.id, state: 'error' });
            errorHandler(mappedPage.upload.id);
          });
      })
      .catch(() => {
        updateUploading({ id: mappedPage.upload.id, state: 'error' });
        errorHandler(mappedPage.upload.id);
      });
  };

  return (
    <FileHandlerContext.Provider
      value={{
        uploading,
        downloading,
        hasItemUploading,
        download,
        downloadS3,
        downloadUrl,
        cancelDownload,
        uploadS3,
        jobProgress,
        cancelUpload,
      }}
    >
      {children}
    </FileHandlerContext.Provider>
  );
};

const useFileHandler = () => React.useContext(FileHandlerContext);

export { FileHandlerProvider, useFileHandler };

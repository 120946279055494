import styled from '@emotion/styled';

type WrapperType = { size?: { width: number | string; height: number | string } };

export const Wrapper = styled.div<WrapperType>(({ size = { width: 'auto', height: 'auto' } }) => ({
  width: size.width,
  height: size.height,
  textAlign: 'center',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#cecece',
  margin: 'auto',
  position: 'relative',
}));

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';
import { withPaginationSelectors } from 'src/ducks/factories/pagination';
import { withSearchSelectors } from 'src/ducks/factories/search';

export const __REDUX_STATE_KEY__ = 'confirmed';
export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getConfirmedMatches = (state: AppState) => getReduxStateSlice(state).list;
export const getConfirmedMatchesCount = (state: AppState) => getReduxStateSlice(state).countItems;

export const confirmedMatchesLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

export const confirmedMatchesPaginationSelectors = withPaginationSelectors(__REDUX_STATE_KEY__);

export const confirmedMatchesSearchSelectors = withSearchSelectors(__REDUX_STATE_KEY__);

import { withLoadingActions } from 'src/ducks/factories/loading';
import { withPaginationActions } from 'src/ducks/factories/pagination';
import { withSearchActions } from 'src/ducks/factories/search';
import { simpleAction } from 'src/ducks/utils';

import { LEADS_PAGES } from '../../utils/constants';
import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FETCH_LEADS_GENERATION_COUNT_FAILURE,
  FETCH_LEADS_GENERATION_COUNT_REQUEST,
  FETCH_LEADS_GENERATION_FAILURE,
  FETCH_LEADS_GENERATION_REQUEST,
  FetchLeadsGenerationsCountFailureAction,
  FetchLeadsGenerationsCountRequestAction,
  FetchLeadsGenerationsFailureAction,
  FetchLeadsGenerationsRequestAction,
  LeadsGeneration,
  LeadsKeyword,
  LeadsGenerationCountResponse,
  LeadsGenerationParams,
  LeadsGenerationsTriggerActionParams,
  SET_LEADS_GENERATION,
  SET_LEADS_GENERATION_COUNT,
  SetLeadsGenerationsAction,
  SetLeadsGenerationsCountAction,
  TRIGGER_LEADS_GENERATION_ACTION,
  TriggerLeadsGenerationsAction,
  RemoveLeadsGenerationsAction,
  REMOVE_LEADS_GENERATION,
  LeadsChannel,
} from './types';

export const fetchLeadsGenerationsRequest = (
  params: LeadsGenerationParams
): FetchLeadsGenerationsRequestAction => simpleAction(FETCH_LEADS_GENERATION_REQUEST, params);

export const fetchLeadsGenerationsFail = (): FetchLeadsGenerationsFailureAction =>
  simpleAction(FETCH_LEADS_GENERATION_FAILURE);

export const setLeadsGenerations = (
  leads: LeadsGeneration[] | LeadsKeyword[] | LeadsChannel[],
  page:
    | LEADS_PAGES.TOP_VIDEOS
    | LEADS_PAGES.TOP_KEYWORDS
    | LEADS_PAGES.TOP_ASSETS
    | LEADS_PAGES.TOP_CHANNELS
): SetLeadsGenerationsAction => simpleAction(SET_LEADS_GENERATION, { list: leads, page });

export const removeLeadsGenerations = (
  leadsIds: (string | number)[]
): RemoveLeadsGenerationsAction => simpleAction(REMOVE_LEADS_GENERATION, leadsIds);

export const fetchLeadsGenerationsCountRequest = (
  params: LeadsGenerationParams
): FetchLeadsGenerationsCountRequestAction =>
  simpleAction(FETCH_LEADS_GENERATION_COUNT_REQUEST, params);

export const fetchLeadsGenerationsCountFail = (): FetchLeadsGenerationsCountFailureAction =>
  simpleAction(FETCH_LEADS_GENERATION_COUNT_FAILURE);

export const setLeadsGenerationsCount = (
  payload: LeadsGenerationCountResponse
): SetLeadsGenerationsCountAction => simpleAction(SET_LEADS_GENERATION_COUNT, payload);

export const triggerLeadsGenerationsAction = (
  payload: LeadsGenerationsTriggerActionParams
): TriggerLeadsGenerationsAction => simpleAction(TRIGGER_LEADS_GENERATION_ACTION, payload);

export const leadsGenerationLoadingActions = withLoadingActions(__REDUX_STATE_KEY__);

export const leadsGenerationPaginationActions = withPaginationActions(__REDUX_STATE_KEY__);

export const leadsGenerationSearchActions = withSearchActions(__REDUX_STATE_KEY__);

import dayjs from 'dayjs';
import uniq from 'lodash/uniq';
import { decideDateValueBasedOnSpan } from 'src/components/Filters/components/DatePickerFilter/utils';
import { withLoadingReducer } from 'src/ducks/factories/loading';
import { REHYDRATE } from 'src/typings/redux-persist-custom';
import { SPAN_DATE_PREFIX, START_DATE_PREFIX } from 'src/utils/constants';

import { __REDUX_STATE_KEY__ } from './selectors';
import * as types from './types';
import { FilterOption } from './types';

const initialState: types.FiltersState & types.FiltersStateKey = {
  actions: [],
  filters: [],
  sort: [],
  strategies: [],
  added: [],
};

function filters(state = initialState, action: types.FiltersActionTypes): types.FiltersState {
  switch (action.type) {
    case types.FETCH_FILTERS_SUCCESS:
      // adding _expiresAt for expiring the filters since the last fetch
      return { ...action.payload, _expiresAt: dayjs().add(5, 'hour') };
    case types.CHANGE_FILTERS:
      return {
        ...state,
        filters: state.filters.map((filter) => {
          // if filter in the iteration is start date and this is triggered by start date or span change do this
          if (
            filter.value === START_DATE_PREFIX &&
            (START_DATE_PREFIX === action.payload[0]?.changedKey ||
              SPAN_DATE_PREFIX === action.payload[0]?.changedKey)
          ) {
            /**
             * This value is only for datepickers with span and date
             * if filters span changed then calculate the new date based on span - to move the date to start of the week if its week
             * or start of the month if its month
             */
            const dateValue =
              SPAN_DATE_PREFIX === action.payload[0]?.changedKey
                ? dayjs(
                    decideDateValueBasedOnSpan(
                      filter.options[0]?.value,
                      action.payload[0]?.newKey.value
                    ).from
                  ).format('YYYY-MM-DD')
                : action.payload[0]?.newKey.value;

            return {
              ...filter,
              options: [
                {
                  value: dateValue,
                  label: dateValue,
                  selected: true,
                  default: true,
                } as FilterOption,
              ],
            };
          }

          const foundFilter = action.payload.find(({ changedKey }) => filter.value === changedKey);

          return {
            ...filter,
            options: filter.options.map((option) => ({
              ...option,
              selected: foundFilter ? foundFilter.newKey.value === option.value : option.selected,
            })),
          };
        }),
      };
    case types.ADD_FILTERS: {
      return {
        ...state,
        filters: state.filters.map((filter) => {
          if (action.payload.includes(filter.value)) {
            return { ...filter, added: true };
          }
          return filter;
        }),
        added: uniq(state.added.concat(action.payload)),
      };
    }
    case types.REMOVE_ADDED_FILTERS: {
      return {
        ...state,
        filters: state.filters.map((filter) => {
          if (action.payload.includes(filter.value)) {
            return { ...filter, added: false };
          }
          return filter;
        }),
        added: state.added.filter((value) => !action.payload.includes(value)),
      };
    }
    case types.RESET_FILTERS: {
      return initialState;
    }
    case types.CHANGE_SORT:
      return {
        ...state,
        sort: state.sort.map((sortItem) => {
          return {
            ...sortItem,
            selected: sortItem.value === action.payload.newValue.value,
          };
        }),
      };
    case REHYDRATE: {
      return {
        ...action.payload?.filters,
        ...initialState,
        added: action.payload?.filters?.added || [],
      };
    }
    default:
      return state;
  }
}

export default withLoadingReducer<types.FiltersState & types.FiltersStateKey>({
  prefix: __REDUX_STATE_KEY__,
  baseReducerState: initialState,
})(filters);

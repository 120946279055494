import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import head from 'lodash/head';
import inRange from 'lodash/inRange';
import last from 'lodash/last';
import numeral from 'numeral';
import React from 'react';
import { Review, Segments } from 'src/ducks/review';
import { Account } from 'src/ducks/user';
import globals from 'src/theme/globals';
import urls from 'src/urls';
import {
  ButtonStatus,
  secondEndHasToSkip,
  Timestamp,
  VideoStatus,
  SnackbarStatus,
} from 'src/utils/constants';
import { secondsToTime } from 'src/utils/timeHelpers';
import { v4 as uuidv4 } from 'uuid';

import { ConfirmedMatch } from '../ducks/confirmedMatches';
import { LegacyClaimsType } from '../ducks/legacyClaims';
import { ReviewHistory } from '../ducks/reviewHistory';
import { Anything } from './globalTypes';

export const getAssetKey = (asset: ReviewHistory | Review | ConfirmedMatch | LegacyClaimsType) =>
  asset.id;

/**
 * returns a the selected client from the list
 *
 * userAccounts {Account[]} the array of the user accounts which holds all the info around them
 * @returns {Account} The account picked
 */
export const pickSelectedAccount = (userAccounts: Account[]) =>
  head(userAccounts.filter((client) => client.selected));

/**
 * returns the url for youtube channel with the specified id
 *
 * channelId {String} the youtube channel ID
 * @returns {String} The youtube url
 */
export const getYoutubeChannelLink = (channelId: string) =>
  `https://www.youtube.com/channel/${channelId}`;

/**
 * returns the url for youtube video with the specified id
 *
 * videoId {String} the youtube video ID
 * @returns {String} The youtube url
 */
export const getYoutubeLink = (videoId: string) => `https://www.youtube.com/watch?v=${videoId}`;

/**
 * returns if the url is external or internal
 *
 * url {String} the url to check
 * @returns {Boolean} true or false
 */
export const isExternalURL = (url: string) =>
  url.indexOf('http://') === 0 || url.indexOf('https://') === 0;

/**
 * returns the youtube link for claim based on the account id and videoId passed
 *
 * accountId {String} the account id based on the user selected client
 * videoId {String} this is a single id or a comma separated string of ids
 * @returns {String} The youtube claim url
 */
export const claimLink = (accountId: string, videoId: string) =>
  `https://studio.youtube.com/owner/${accountId}/claims/manual?o=${accountId}&filter=[{"name":"KEYWORD","value":{"name":"CONTAINS","value":"${videoId}"}}]&sort={"columnType":"video","sortOrder":"DESCENDING"}`;

/**
 * returns the youtube link for claim based on the account id and videoId passed filtered by channel_id
 *
 * accountId {String} the account id based on the user selected client
 * videoId {String} this is a single id or a comma separated string of ids
 * @returns {String} The youtube claim url
 */
export const claimLinkByChannelId = (accountId: string, videoId: string) =>
  `https://studio.youtube.com/owner/${accountId}/claims/manual?o=${accountId}&filter=[{"name":"CHANNEL_ID","value":{"name":"CONTAINS","value":"${videoId}"}}]&sort={"columnType":"video","sortOrder":"DESCENDING"}`;

/**
 * returns the youtube claim link for content id based on the account id and videoId passed
 *
 * accountId {String} the account id based on the user selected client
 * videoId {String} this is a single id or a comma separated string of ids
 * @returns {String} The youtube claim url
 */
export const youtubeClaimContentId = (accountId: string, videoId: string) =>
  `https://studio.youtube.com/owner/${accountId}/issues?o=${accountId}&filter=[{"isPinned":true,"name":"STATUS","value":["ACTION_REQUIRED"]},{"name":"VIDEO_ID","value":{"name":"IS","value":"${videoId}"}},{"name":"ISSUE_TYPE","value":[{"selectedChildrenIds":[],"selectedParentId":"ISSUE_TYPE_POTENTIAL_CLAIM"}]}]&sort={"columnType":"video","sortOrder":"DESCENDING"}`;

/**
 * returns the youtube asset link for content id based on the account id and videoId passed
 *
 * accountId {String} the account id based on the user selected client
 * videoId {String} this is a single id or a comma separated string of ids
 * @returns {String} The youtube claim url
 */
export const youtubeStudioAssetContentId = (accountId: string, videoId: string) =>
  `https://studio.youtube.com/owner/${accountId}/assets?o=${accountId}&filter=[{"isPinned":true,"name":"ASSET_OWNERSHIP","value":"ALL_ASSETS"},{"isPinned":true,"name":"ASSET_TYPE","value":["ASSET_TYPE_COMPOSITION","ASSET_TYPE_MUSIC_VIDEO","ASSET_TYPE_ART_TRACK","ASSET_TYPE_SOUND_RECORDING","ASSET_TYPE_MOVIE","ASSET_TYPE_EPISODE","ASSET_TYPE_WEB"]},{"name":"ASSET_ID","value":{"name":"IS","value":"${videoId}"}}]&sort={"columnType":"claimedVideos","sortOrder":"DESCENDING"}`;

/**
 * returns the youtube link for content id for asset based on the account id and videoId passed
 *
 * accountId {String} the account id based on the user selected client
 * videoId {String} this is a single id or a comma separated string of ids
 * @returns {String} The youtube claim url
 */
export const youtubeAssetContentId = (accountId: string, videoId: string) =>
  `https://studio.youtube.com/asset/${videoId}/metadata/overview?o=${accountId}`;

/**
 * returns a new guid which is guaranteed to be a unique ID
 *
 * @returns {String} The GUID string
 */
export const guid = uuidv4;
/**
 * returns the global review page url with q set as passed string
 *
 * @returns {String} The VH url of the global
 */
export const searchVideoHunter = (q: string, strategy: string = 'all') =>
  `${urls.globalReview()}?strategy=${strategy}&q=${q}`;

/**
 * returns the global review page url for the keyword strategy with keyword passed as filter
 *
 * @returns {String} The VH url of the keyword strategy
 */
export const navigateOnKeywordsAndSearchWithKeyword = (keyword: string) =>
  `${urls.globalReview()}?strategy=keywords&keyword=${keyword}`;

/**
 * Copy the text passed to the clipboard by creating a dummy input on the page and remove it
 *
 * @param text {String}
 * @return text {String}
 */
export const copyTextToClipboard = (text: string) => {
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('value', text);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);

  return text;
};

export const notEmpty = <TValue>(value: TValue | null | undefined | boolean): value is TValue =>
  value !== false && value !== null && value !== undefined;

type ButtonPallete = {
  green: string;
  blue: string;
  red: string;
  grey: string;
  lightGrey: string;
  dark: string;
  alert: string;
};
export const ButtonColorPicker = (
  colorStatus: string,
  pallete: ButtonPallete = globals.colors.buttons
) => {
  switch (colorStatus) {
    case ButtonStatus.Success:
      return pallete.green;
    case ButtonStatus.Info:
      return pallete.blue;
    case ButtonStatus.Danger:
      return pallete.red;
    case ButtonStatus.Neutral:
      return pallete.grey;
    case ButtonStatus.Default:
      return pallete.lightGrey;
    case ButtonStatus.Dark:
      return pallete.dark;
    case ButtonStatus.Alert:
      return pallete.alert;
    default:
      return pallete.green;
  }
};

type SnackbarPallete = {
  blue: string;
  red: string;
  orange: string;
};
export const SnackbarColorPicker = (
  colorStatus: string,
  pallete: SnackbarPallete = globals.colors.snackbar
) => {
  switch (colorStatus) {
    case SnackbarStatus.Info:
      return pallete.blue;
    case SnackbarStatus.Warning:
      return pallete.red;
    default:
      return pallete.orange;
  }
};

export const VideoColorPicker = (
  colorStatus: string,
  pallete: ButtonPallete = globals.colors.buttons
) => {
  switch (colorStatus) {
    case VideoStatus.Failure:
      return pallete.red;
    case VideoStatus.Confirmed:
      return pallete.green;
    default:
      return pallete.green;
  }
};

export const parseCurrentProgress = (num: number) => parseFloat(Math.floor(num).toFixed(2));

export const onChangeTimestamp = (
  type: 'matched_from' | 'matched_to',
  currrentProgress: number,
  timestamp: Timestamp,
  onChange: (t: Timestamp) => void,
  num?: number
): void => {
  const newPoint = typeof num !== 'undefined' ? num : parseCurrentProgress(currrentProgress);
  onChange({ ...timestamp, [type]: newPoint });
};

export const timestampIsValid = (timestamps: Timestamp[], getDuration: () => number) => {
  const duration = getDuration() || Number.MAX_SAFE_INTEGER;

  // check if start and end of each doesnt overlap each other
  const timestampValidationErrors = timestamps.map(({ matched_from: start, matched_to: end }) => {
    if (end <= start || (end === 0 && start === 0)) {
      return false;
    }
    if (start > duration || end > duration) {
      return false;
    }
    return start <= end;
  });

  // collect total durations for start and end to check at the end if its bigger than expected value
  const totalDurations = timestamps.reduce(
    (acc, asset) => {
      acc.total_end += asset.matched_to;
      acc.total_start += asset.matched_from;
      return acc;
    },
    { total_start: 0, total_end: 0 }
  );

  // collect all non overlapping timestamps - this array can be compared to the initial timestamps and if their lengths
  // are equal then there are no overlaps on the timestamps
  const nonOverlappingTimestamps = timestamps.reduce((acc: Timestamp[], asset, assetIndex) => {
    const overlappingAssets = timestamps.reduce((acc: Timestamp[], timestamp, timestampIndex) => {
      if (
        assetIndex !== timestampIndex &&
        (inRange(asset.matched_from, timestamp.matched_from, timestamp.matched_to) ||
          inRange(asset.matched_to, timestamp.matched_from, timestamp.matched_to))
      ) {
        acc.push(timestamp);
      }

      return acc;
    }, []);

    if (overlappingAssets.length === 0) {
      acc.push(asset);
    }

    return acc;
  }, []);

  return (
    nonOverlappingTimestamps.length === timestamps.length &&
    timestampValidationErrors.every(Boolean) &&
    totalDurations.total_start <= totalDurations.total_end - secondEndHasToSkip
  );
};

/**
 * takes an axios promise and returns the actual data from the request or the error
 *
 * axiosPromise {AxiosPromise} the axios promise
 * @returns {AxiosResponse | AxiosError} The API response
 */
export const axiosPromiseResult = <T = Anything>(axiosPromise: AxiosPromise) =>
  axiosPromise
    .then(({ data }: AxiosResponse<T>) => data)
    .catch((error: AxiosError) => {
      throw error.response ? error.response.data : error;
    });

export const createDownloadLink = (response: Anything, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([response?.data || response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getMatches = ({ from, to }: Segments, i: number, index: Segments[]) =>
  `${secondsToTime(from)} - ${secondsToTime(to)} ${
    index.length !== 1 && index.length - 1 !== i ? ', ' : ''
  }`;

export const getFilenameFromUrl = (name: string): string => last(name.split('/')) || name;

export const getSlicedFilename = (name: string): string => {
  const filename = getFilenameFromUrl(name);
  return filename.length >= 16
    ? `...${filename.slice(0, 12)}...${filename.substr(filename.length - 6)}`
    : filename;
};

export const formatNumber = (number: number | null, points?: boolean): string => {
  if (number === null) {
    return '-';
  }

  return numeral(number)
    .format(points ? '0,0' : '0.00a')
    .toString()
    .toUpperCase();
};

export function createCtx<ContextType>(): [
  () => ContextType,
  React.Provider<ContextType | undefined>
] {
  const ctx = React.createContext<ContextType | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);

    if (!c) {
      throw new Error('useCtx must be inside a Provider with a value');
    }

    return c;
  }

  return [useCtx, ctx.Provider];
}

/**
 * This function returns an array of days for the selected month
 * @param month
 * @param year
 */
export const getDaysInMonth = (month: number, year: number) => {
  const date = new Date(year, month, 1);
  const days = [];

  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const formatNumberWithStringLiteral = (labelValue: number) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? Math.abs(Number(labelValue)) / 1.0e9 + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? Math.abs(Number(labelValue)) / 1.0e6 + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? Math.abs(Number(labelValue)) / 1.0e3 + 'K'
    : Math.abs(Number(labelValue));
};

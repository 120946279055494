import styled from '@emotion/styled';
import { rem, transparentize } from 'polished';

export const UploadContainer = styled.div`
  position: relative;
  background: white;
  min-width: ${rem(437)};
`;

export const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.md} 0`};
  box-sizing: border-box;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: ${({ theme }) => `0 ${theme.spacing.xl} ${theme.spacing.xl}`};
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  font-weight: ${({ theme }) => theme.typography.weights.bold};
  font-size: ${({ theme }) => theme.typography.fontSizes[20]};
  color: ${({ theme }) => theme.utils.getColor('lightGrey', 650, 'flat')};
  padding-top: ${({ theme }) => theme.spacing.sm};
`;

export const DragDropContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => `${theme.spacing.xl} 0 ${theme.spacing.md} 0`};
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  border: ${rem(2)} dashed ${({ theme }) => theme.utils.getColor('darkBlue', 50, 'flat')};
  border-radius: ${rem(6)};
  background: ${({ theme }) => transparentize(0.85, theme.utils.getColor('darkBlue', 50, 'flat'))};

  &:focus {
    outline: none;
  }
`;

export const Guidelines = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

export const OrText = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizes[10]};
  font-weight: ${({ theme }) => theme.typography.weights.bold};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const TemplateText = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.md};
  font-weight: ${({ theme }) => theme.typography.weights.regular};
  font-size: ${({ theme }) => theme.typography.fontSizes[12]};
  color: ${({ theme }) => theme.utils.getColor('blue', 400, 'flat')};
  > span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ActiveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${rem(180)};
`;

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';
import { withPaginationSelectors } from 'src/ducks/factories/pagination';

export const __REDUX_STATE_KEY__ = 'reviewHistory';
export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getHistoryReviews = (state: AppState) => getReduxStateSlice(state).list;
export const getHistoryActions = (state: AppState) => getReduxStateSlice(state).actions;
export const reviewHistoryLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);
export const reviewHistoryPaginationSelectors = withPaginationSelectors(__REDUX_STATE_KEY__);

import {
  GlobalSearchActionTypes,
  GlobalSearchReducer,
  SET_GLOBAL_SEARCH,
  RESET_GLOBAL_SEARCH,
} from './types';

const initialState: GlobalSearchReducer = {
  q: '',
};

function globalSearch(state = initialState, action: GlobalSearchActionTypes): GlobalSearchReducer {
  switch (action.type) {
    case SET_GLOBAL_SEARCH:
      return {
        ...state,
        q: action.payload,
      };
    case RESET_GLOBAL_SEARCH:
      return initialState;
    default:
      return state;
  }
}

export default globalSearch;

import dayjs from 'dayjs';
import { RehydrateAction } from 'src/typings/redux-persist-custom';
import { PagesType } from 'src/utils/constants';

import { Anything } from '../../utils/globalTypes';

export const FETCH_FILTERS = 'FETCH_FILTERS';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const FETCH_FILTERS_FAILURE = 'FETCH_FILTERS_FAILURE';
export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const CHANGE_SORT = 'CHANGE_SORT';
export const ADD_FILTERS = 'ADD_FILTERS';
export const REMOVE_ADDED_FILTERS = 'REMOVE_ADDED_FILTERS';

export interface FetchFiltersAction {
  type: typeof FETCH_FILTERS;
  payload: {
    strategy: PagesType;
    loading?: boolean;
  };
}

export interface FetchFiltersSuccessAction {
  type: typeof FETCH_FILTERS_SUCCESS;
  payload: FiltersState;
}

export interface FetchFiltersFailureAction {
  type: typeof FETCH_FILTERS_FAILURE;
}

export interface ChangeFilterAction {
  type: typeof CHANGE_FILTERS;
  payload: {
    newKey: FilterOption;
    changedKey: string;
  }[];
}

export interface AddFilterAction {
  type: typeof ADD_FILTERS;
  payload: string[];
}

export interface RemoveAddedFilterAction {
  type: typeof REMOVE_ADDED_FILTERS;
  payload: string[];
}

export interface ResetFilterAction {
  type: typeof RESET_FILTERS;
  payload?: Anything;
}

export interface ChangeSortAction {
  type: typeof CHANGE_SORT;
  payload: {
    newValue: Sort;
  };
}

export type FiltersActionTypes =
  | ResetFilterAction
  | FetchFiltersAction
  | FetchFiltersSuccessAction
  | ChangeFilterAction
  | ChangeSortAction
  | AddFilterAction
  | RemoveAddedFilterAction
  | RehydrateAction;

export type FiltersStateKey = {
  [key: string]: Anything[];
};

export type FiltersState = {
  strategy?: string;
  strategies: Strategy[];
  actions: Action[];
  sort: Sort[];
  filters: Filter[];
  added: string[];
  _expiresAt?: dayjs.Dayjs;
};

export type Filter = {
  label: string;
  value: string;
  options: FilterOption[];
};
export type FilterOption = {
  value: number | string;
  label: string;
  default: boolean;
  selected: boolean;
};
export type Sort = {
  value: string;
  label: string;
  default: boolean;
  selected: boolean;
};
export type Action = { label: string; type: string };
export type Strategy = {
  value: string | number;
  label: string;
  default: boolean;
  selected: boolean;
  actions: string[];
  sort: string[];
  filters: {
    main: string[];
    more: string[];
  };
};

export type FilterParams = {
  [key: string]: string | number | undefined;
  strategy?: string;
  q?: string;
  page: number;
  sort_by: undefined | string;
};

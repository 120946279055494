import { Global } from '@emotion/react';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import durationPlugin from 'dayjs/plugin/duration';
import weekday from 'dayjs/plugin/weekday';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { setAxiosToken, setAxiosAccount, deleteAxiosHeaders } from 'src/providers/axiosInstances';
import { __TOKEN__, __ACCOUNT__ } from 'src/utils/constants';
import cookies from 'src/utils/cookies';

import App from './App';
import { AppGlobalCss } from './index.style';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './store';
import { Anything } from './utils/globalTypes';

/**
 * This introduce the duration functionality for the dayjs and it gives us the functionality to calculate it
 **/
dayjs.extend(durationPlugin);
/**
 * This introduce the weekday functionality for the day js giving us the opportunity to handle
 * week dates and go back and forth with ease
 **/
dayjs.extend(weekday);

Sentry.init({
  environment: !process.env.REACT_APP_STAGE ? 'development' : process.env.REACT_APP_STAGE,
  dsn: process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_SENTRY_DSN : undefined,
  release:
    process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE
      ? process.env.REACT_APP_SENTRY_RELEASE
      : undefined,
  // @ts-ignore
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

// add an extension to dayjs on app startup
dayjs.extend(customParseFormat);

// check that if there is a token stored it will set axios tokens for the api calls
// otherwise it will delete it.
const token = cookies.get(__TOKEN__);

if (token) {
  setAxiosToken(token);
} else {
  deleteAxiosHeaders();
}

const account = cookies.get(__ACCOUNT__);
if (account) {
  setAxiosAccount(account);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

const render = (Component: Anything) =>
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Global styles={AppGlobalCss} />
        <Component />
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

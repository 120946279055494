import styled from '@emotion/styled';

import { Props } from './InlineAlert';

export const Wrapper = styled.div<Props>`
  padding: 0 16px;
  height: 50px;
  background-color: ${({ type, theme }) =>
    theme.utils.getColor(type === 'error' ? 'red' : 'green', 250)};
  font-size: 16px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.utils.getColor('darkGrey', 750)};
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

export const Message = styled.div`
  margin-left: 12px;
`;

export const IconWrapper = styled.div<{ type: 'error' | 'success' | 'close'; pointer?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};

  svg {
    path {
      fill: ${({ theme }) => theme.utils.getColor('darkGrey', 750)};
    }
  }
`;

import {
  RESET_SEARCH,
  SET_SEARCH,
  SetSearchAction,
  ResetSearchAction,
} from 'src/ducks/factories/search/types';
import { simpleAction } from 'src/ducks/utils';

const searchActions = (prefix: string) => {
  const setSearchValue = (payload: string): SetSearchAction =>
    simpleAction(SET_SEARCH(prefix), payload);
  const resetSearch = (): ResetSearchAction => simpleAction(RESET_SEARCH(prefix));

  return {
    setSearchValue,
    resetSearch,
  };
};
export default searchActions;

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow-y: auto;
  padding: 25px 0;
  will-change: scroll-position;
`;

export const CloseBtn = styled.button`
  right: 10px;
  top: 10px;
  position: fixed;
  padding: 15px;
  outline: 0;
  svg {
    width: 26px;
    height: 26px;

    path {
      {transition(0.1)};
      fill: ${({ theme }) => theme.colors.grey4};
    }
  }

  &:hover svg path {
    fill: ${({ theme }) => theme.colors.grey4}; // darken($closeButtonColor, 15%);
  }

  &:active svg path {
    fill: ${({ theme }) => theme.colors.grey4}; // darken($closeButtonColor, 30%);
  }
`;

import {
  SET_GLOBAL_SEARCH,
  SetGlobalSearchAction,
  RESET_GLOBAL_SEARCH,
  ResetGlobalSearchAction,
} from 'src/ducks/globalSearch/types';
import { simpleAction } from 'src/ducks/utils';

export const setGlobalSearch = (payload: string): SetGlobalSearchAction =>
  simpleAction(SET_GLOBAL_SEARCH, payload);

export const resetGlobalSearch = (): ResetGlobalSearchAction => simpleAction(RESET_GLOBAL_SEARCH);

import styled from '@emotion/styled';
import { size } from 'src/theme/globals';

type WrapperProps = {
  centered: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  padding: 0; // this is the gutter
  height: 100%;
  margin: 0 ${(props) => (props.centered ? 'auto' : '0')};
  min-width: ${size.tablet};
  width: 100%;
`;

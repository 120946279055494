import styled from '@emotion/styled';
import Tippy from '@tippyjs/react';
import { rem } from 'polished';

export const TooltipStyle = styled(Tippy)`
  .tippy-content {
    background-color: ${({ theme }) => theme.utils.getColor('darkBlue', 750)};
    color: ${({ theme }) => theme.palette.white};
    border-radius: ${rem(4)};
    padding: ${({ theme }) => theme.spacing.sm};
    max-width: ${rem(160)};
    font-size: ${({ theme }) => theme.typography.fontSizes[12]};
  }
  .tippy-arrow {
    color: ${({ theme }) => theme.utils.getColor('darkBlue', 750)};
  }
`;

import React, { useState } from 'react';
import { createCtx } from 'src/utils/common';

type ContextProps = {
  selected: React.ReactText[];
  setSelected: (t: React.ReactText[]) => void;
};

const [selectedRowCtx, CtxProvider] = createCtx<ContextProps>();
export const useSelectedRow = selectedRowCtx;

export const SelectedRowProvider: React.FC<Record<string, unknown>> = (props) => {
  const [selected, setSelected] = useState<React.ReactText[]>([]);

  return <CtxProvider value={{ selected, setSelected }}>{props.children}</CtxProvider>;
};

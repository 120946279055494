import React from 'react';

import { StatusTypes } from '../../../../types';
import { LoaderContainer, Progress } from './LinearLoader.style';

type Props = {
  progress?: number;
  type: StatusTypes;
};

const IndeterminateLoader: React.FC<Props> = ({ progress, type }) => (
  <LoaderContainer>
    <Progress type={type} value={progress} max={100} />
  </LoaderContainer>
);

export default IndeterminateLoader;

import styled from '@emotion/styled';

export const SidebarWrapper = styled.aside`
  z-index: 100;
  height: auto;
`;

export const SidebarPolicyLinks = styled.div`
  z-index: 101;
  align-items: center;
  width: auto;
  font-size: 12px;
  position: relative;
  bottom: 94px;
  height: 140px;
  color: ${({ theme }) => theme.utils.getColor('primary', 400, 'normal')};
  display: flex;
  flex-direction: column;
  > a {
    padding-bottom: 8px;
  }
`;

import { lazy, LazyExoticComponent } from 'react';

import { Anything } from './globalTypes';

const STORAGE_KEY = 'force-refreshed';

export const lazyWithRetry = (componentImport: Anything): LazyExoticComponent<Anything> =>
  lazy(async () => {
    const hasBeenForceRefreshed = JSON.parse(window.localStorage.getItem(STORAGE_KEY) || 'false');

    try {
      const component = await componentImport();

      window.localStorage.setItem(STORAGE_KEY, 'false');

      return component;
    } catch (error) {
      if (!hasBeenForceRefreshed) {
        // If lazy load fails once , maybe it's just that a deployment was made.
        window.localStorage.setItem(STORAGE_KEY, 'true');
        return window.location.reload();
      }

      // Shit. It didn't crash because of a deployment issue. Throw the error!
      throw error;
    }
  });

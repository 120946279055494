import dayjs from 'dayjs';

/**
 * Utility function that decide the output of date based on the span provided - This is only for LeadsGenerations for now
 * if Week then returns the first day of the week - Monday
 * if Month first day of the month
 * if day or other does nothing
 **/
export const decideDateValueBasedOnSpan = (
  date: number | string | undefined = '',
  spanSelected: string | number
) => {
  let newDate = null;
  if (spanSelected === 'WEEK') {
    newDate = {
      from: dayjs(date).weekday(1).toDate(),
      to: dayjs(date).weekday(1).toDate(),
    };
  } else if (spanSelected === 'MONTH') {
    newDate = {
      from: dayjs(date).startOf('month').toDate(),
      to: dayjs(date).startOf('month').toDate(),
    };
  } else {
    newDate = {
      from: dayjs(date).toDate(),
      to: dayjs(date).toDate(),
    };
  }
  return newDate;
};

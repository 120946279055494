import axios, { CancelTokenSource } from 'axios';
import get from 'lodash/get';
import React from 'react';

import { PAGES, PagesType } from '../../utils/constants';
import {
  ASSETS_DOWNLOAD_ID,
  ASSETS_UPLOAD_ID,
  CHANNELS_DOWNLOAD_ID,
  CHANNELS_UPLOAD_ID,
  CONFIRMED_DOWNLOAD_ID,
  KEYWORD_MANAGEMENT_DOWNLOAD_ID,
  KEYWORD_MANAGEMENT_UPLOAD_ID,
  LEGACY_CLAIMS_DOWNLOAD_ID,
  LEGACY_CLAIMS_UPLOAD_ID,
} from './types';
import {
  UploadCSVPayload,
  pagesFuncsMap,
  pagesContentMap,
  DownloadS3CSVPayload,
  LegacyClaimsJobType,
} from './types';

export const TIMEOUT = 2500;

export const dropzoneConfig = {
  accept: '.csv',
  noClick: true,
  noKeyboard: true,
  maxFiles: 1,
};

export const bytesConverter = (x?: number) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0,
    n = x || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const mappedPages = (value: PagesType) => {
  const pagesFuncsMap: pagesFuncsMap = {
    [PAGES.CHANNELS_MANAGEMENT]: {
      upload: {
        id: CHANNELS_UPLOAD_ID,
        getPayload: ({ fileName }: UploadCSVPayload) => ({
          destination: 'channels',
          title: fileName,
        }),
      },
      download: {
        id: CHANNELS_DOWNLOAD_ID,
      },
    },
    [PAGES.ASSETS_MANAGEMENT]: {
      upload: {
        id: ASSETS_UPLOAD_ID,
        getPayload: ({ fileName }: UploadCSVPayload) => ({
          destination: 'assets',
          title: fileName,
        }),
      },
      download: {
        id: ASSETS_DOWNLOAD_ID,
      },
    },
    [PAGES.LEGACY_CLAIMS]: {
      upload: {
        id: LEGACY_CLAIMS_UPLOAD_ID,
        getPayload: ({ fileName, createVideos }: UploadCSVPayload) => ({
          destination: 'legacy',
          title: fileName,
          create_videos: !!createVideos,
        }),
      },
      download: {
        id: LEGACY_CLAIMS_DOWNLOAD_ID,
      },
    },
    [PAGES.CONFIRMED_MATCHES]: {
      upload: {
        id: 'NO_UPLOAD_HERE',
        getPayload: () => null,
      },
      download: {
        id: CONFIRMED_DOWNLOAD_ID,
      },
    },
    [PAGES.KEYWORD_MANAGEMENT]: {
      upload: {
        id: KEYWORD_MANAGEMENT_UPLOAD_ID,
        getPayload: ({ fileName }: UploadCSVPayload) => ({
          destination: 'keywords',
          title: fileName,
        }),
      },
      download: {
        id: KEYWORD_MANAGEMENT_DOWNLOAD_ID,
      },
    },
  };
  return get(pagesFuncsMap, value);
};

export const cancelTokens: {
  [index: string]: {
    download: {
      [index: string]: CancelTokenSource;
    };
    upload: CancelTokenSource;
  };
} = {
  [PAGES.ASSETS_MANAGEMENT]: {
    download: {},
    upload: axios.CancelToken.source(),
  },
  [PAGES.LEGACY_CLAIMS]: {
    download: {},
    upload: axios.CancelToken.source(),
  },
  [PAGES.CHANNELS_MANAGEMENT]: {
    download: {},
    upload: axios.CancelToken.source(),
  },
  [PAGES.CONFIRMED_MATCHES]: {
    download: {},
    upload: axios.CancelToken.source(),
  },
  [PAGES.KEYWORD_MANAGEMENT]: {
    download: {},
    upload: axios.CancelToken.source(),
  },
};

export const mappedContent = (value: PagesType) => {
  const pagesContentMap: pagesContentMap = {
    [PAGES.CHANNELS_MANAGEMENT]: {
      title: `Upload Channels for`,
      tooltipContent:
        'You should upload a CSV file with the following columns: "Channel ID, Enabled, Favorite, Content Type". The acceptable values on the “Enabled” & “Favorite” column are: "yes, no" and for Content Type “Substitutional Use, Films & TV, Trends, Youtubers” For more details, please go to the wiki.',
      customContent: (data, downloadS3) => {
        const successWithoutErrors = data?.status === 'Success' && data?.details?.faulty === 0;
        const title = `Channels affected: ${data.details?.current}/${data.details?.total}`;

        const onError = !successWithoutErrors
          ? () => {
              if (data?.output) {
                downloadS3({ page: PAGES.CHANNELS_MANAGEMENT, files: [data.output[1]] });
              }
            }
          : undefined;

        const results = {
          created: data.details?.new_cnt || 0,
          updated: data.details?.updated_cnt || 0,
          ignored: data.details?.ignored || 0,
          failed: data.details?.faulty || 0,
        };

        return { title, onError, results };
      },
    },
    [PAGES.ASSETS_MANAGEMENT]: {
      title: `Upload Assets for`,
      tooltipContent:
        'You can download a CSV with assets, and then reuse it to upload new assets or update existing ones. For more info, to see which fields are required, please read the manual in the wiki.',
      customContent: (data, downloadS3) => {
        const successWithoutErrors = data?.status === 'Success' && data?.details?.failed_cnt === 0;
        const title = `Assets affected: ${data.details?.current}/${data.details?.total}`;

        const onError = !successWithoutErrors
          ? () => {
              if (data?.output) {
                downloadS3({ page: PAGES.ASSETS_MANAGEMENT, files: [data.output[1]] });
              }
            }
          : undefined;

        const results = {
          created: data.details?.new_cnt || 0,
          updated: data.details?.updated_cnt || 0,
          reset: data.details?.reset_cnt || 0,
          failed: data.details?.failed_cnt || 0,
        };

        return { title, onError, results };
      },
    },
    [PAGES.LEGACY_CLAIMS]: {
      title: `Upload .CSV for`,
      tooltipContent:
        "You can upload a .csv with at least a column named 'Video ID'. Please verify that you give the right name to this column. This file may also include more columns, as downloaded from ContentID.",
      customContent: (
        data: LegacyClaimsJobType,
        downloadS3: (payload: DownloadS3CSVPayload) => void
      ) => {
        const results = {
          'videos uploaded': data.details?.total || 0,
          'claimed by ORFIUM': data.details?.already_claimed_by_orfium || 0,
          'claimed by others': data.details?.already_claimed_by_others || 0,
          'whitelisted from channels': data.details?.whitelisted || 0,
          'duplicate entries': data.details?.ignored || 0,
          'wrong entries': data.details?.faulty || 0,
          'claimable videos': data.details?.claimable || 0,
        };

        const onDownload = () => {
          if (data.output) downloadS3({ page: PAGES.LEGACY_CLAIMS, files: [data.output[0]] });
        };

        return { results, onDownload };
      },
    },
    [PAGES.KEYWORD_MANAGEMENT]: {
      title: `Upload Keywords for`,
      tooltipContent:
        'You should upload a single column .csv file. The header row should be named Keyword or Keywords.',
      customContent: (
        data: LegacyClaimsJobType,
        downloadS3: (payload: DownloadS3CSVPayload) => void
      ) => {
        const successWithoutErrors = data?.status === 'Success' && data?.details?.faulty === 0;
        const title = `Uploaded ${data.details?.total} Keywords`;

        const onError = !successWithoutErrors
          ? () =>
              data?.output?.length === 2 &&
              downloadS3({ page: PAGES.KEYWORD_MANAGEMENT, files: [data.output[1]] })
          : undefined;

        const results = {
          created: data.details?.new_cnt || 0,
          existing: data.details?.ignored || 0,
          failed: data.details?.faulty || 0,
        };

        return { title, onError, results };
      },
    },
  };
  return get(pagesContentMap, value);
};

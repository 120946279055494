import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { SET_PAGINATION_PAGE, SetPaginationPageAction } from 'src/ducks/factories/pagination/types';
import {
  CHANGE_FILTERS,
  CHANGE_SORT,
  ChangeFilterAction,
  ChangeSortAction,
  FETCH_FILTERS_SUCCESS,
  FetchFiltersSuccessAction,
  getFilterOptions,
  getSelectedStrategy,
  getSort,
} from 'src/ducks/filters';
import { handleParams } from 'src/ducks/filters/utils';
import { getGlobalSearch, SET_GLOBAL_SEARCH, SetGlobalSearchAction } from 'src/ducks/globalSearch';
import { legacyClaimsAPI } from 'src/providers';
import { ChannelsPaginationLimit, PAGES } from 'src/utils/constants';

import {
  fetchLegacyClaimsFailure,
  setLegacyClaims,
  fetchLegacyClaimsRequest,
  setLegacyClaimsCount,
  legacyClaimsLoadingActions,
  legacyClaimsPaginationActions,
  fetchLegacyClaimsCountRequest,
} from './actions';
import { __REDUX_STATE_KEY__ } from './selectors';
import {
  FetchLegacyClaimsRequestAction,
  FETCH_LEGACY_CLAIMS_REQUEST,
  FETCH_LEGACY_CLAIMS_COUNT_REQUEST,
  FetchLegacyClaimsCountRequestAction,
} from './types';

const triggerReactiveActionsBasedOnState = (state: AppState) => {
  const selected = getSelectedStrategy(state);

  if (selected?.value && PAGES.LEGACY_CLAIMS !== (selected?.value as string)) {
    return of();
  }
  const filters = getFilterOptions(state);
  const sort = getSort(state);
  const q = getGlobalSearch(state);
  const params = handleParams(filters, sort, selected, q, 1);
  return concat(
    of(legacyClaimsLoadingActions.setLoading(true)),
    of(legacyClaimsPaginationActions.setPaginationPage(1)),
    of(fetchLegacyClaimsCountRequest(params))
  );
};

const filtersSucceededEpic = (
  action$: ActionsObservable<
    FetchFiltersSuccessAction | ChangeFilterAction | ChangeSortAction | SetGlobalSearchAction
  >,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_FILTERS_SUCCESS, CHANGE_FILTERS, CHANGE_SORT, SET_GLOBAL_SEARCH),
    switchMap(() => {
      const state = state$.value;
      return triggerReactiveActionsBasedOnState(state);
    })
  );

const fetchLegacyClaimsEpic = (action$: ActionsObservable<FetchLegacyClaimsRequestAction>) =>
  action$.pipe(
    ofType(FETCH_LEGACY_CLAIMS_REQUEST),
    switchMap(({ payload }) => {
      const { ...rest } = payload;
      //TODO: REMOVE THIS WHEN API CHANGES.
      rest.type = 'legacy';

      return concat(
        of(legacyClaimsLoadingActions.setLoading(true)),
        from(
          legacyClaimsAPI.single
            .get({
              ...rest,
            })
            .request()
        ).pipe(
          mergeMap((data) => [setLegacyClaims(data)]),
          catchError(() => of(fetchLegacyClaimsFailure()))
        ),
        of(legacyClaimsLoadingActions.setLoading(false))
      );
    })
  );

const fetchLegacyClaimsCountRequestEpic = (
  action$: ActionsObservable<FetchLegacyClaimsCountRequestAction>
) =>
  action$.pipe(
    ofType(FETCH_LEGACY_CLAIMS_COUNT_REQUEST),
    switchMap(({ payload }) => {
      //TODO: REMOVE THIS WHEN API CHANGES.
      payload.type = 'legacy';
      return concat(
        of(legacyClaimsLoadingActions.setLoading(true)),
        from(
          legacyClaimsAPI.single
            .getCount({
              ...payload,
            })
            .request()
        ).pipe(
          mergeMap((data) =>
            of(
              setLegacyClaimsCount(data.count),
              // legacyClaimsPaginationActions.setPaginationPage(payload.page),
              legacyClaimsPaginationActions.setPaginationTotalPages(
                Math.ceil(data.count / ChannelsPaginationLimit)
              )
            )
          )
        ),
        of(legacyClaimsLoadingActions.setLoading(false))
      );
    })
  );

const changePageEpic = (
  action$: ActionsObservable<SetPaginationPageAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REDUX_STATE_KEY__)),
    switchMap((data) => {
      const selected = getSelectedStrategy(state$.value);
      const filters = getFilterOptions(state$.value);
      const sort = getSort(state$.value);
      const q = getGlobalSearch(state$.value);
      const params = handleParams(filters, sort, selected, q, data.payload);

      return concat(
        of(legacyClaimsLoadingActions.setLoading(true)),
        of(fetchLegacyClaimsRequest(params)),
        of(legacyClaimsLoadingActions.setLoading(false))
      );
    })
  );

export default {
  filtersSucceededEpic,
  fetchLegacyClaimsEpic,
  fetchLegacyClaimsCountRequestEpic,
  changePageEpic,
};

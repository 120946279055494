import React, { useRef } from 'react';
import { Form, InjectedFormProps } from 'redux-form';
import Button from 'src/components/Button';
import InlineAlert from 'src/components/InlineAlert';
import YoutubeEmbed from 'src/components/YoutubeEmbed';
import { NormalizedActionsOptions } from 'src/ducks/legacyFilters';
import { Review } from 'src/ducks/review';
import { ButtonStatus, defaultRejectOption } from 'src/utils/constants';
import { Timestamp } from 'src/utils/constants';

import {
  Wrapper,
  Heading,
  VideoContainer,
  YoutubeWrapper,
  DetailItem,
  ButtonWrapper,
} from './AssetForm.style';
import { AssetEditedRow } from './components';
import { FieldArrayRowAssetItem } from './components/AssetEditedRow/AssetEditedRow';

export type Props = {
  actions?: NormalizedActionsOptions[];
  triggerClose?(): void;
  asset: Review;
  getVideoDuration: () => number;
};

type FormAsset = {
  option: {
    value: string;
    reference_url: string;
  };
  action: { type?: string; value: string; label: string };
  matches?: Timestamp[];
};

export type FormArgs = {
  getVideoDuration: () => number;
  originalAssets: FormAsset[];
  otherAssets: FormAsset[];
  ytDuration?: number;
};

const AssetForm: React.FC<Props & InjectedFormProps<FormArgs, Props>> = ({
  handleSubmit,
  triggerClose = () => {},
  asset,
  pristine,
  actions,
  submitting,
  valid,
  submitFailed,
  error,
}) => {
  const {
    score,
    video: { title: videoTitle, external_id, total_views, daily_views },
  } = asset;
  const videoRef = useRef({ getDuration: () => 0, getCurrentTime: () => 0 });

  // trigger an onClose if provided
  const onClose = (e: React.MouseEvent) => {
    e.preventDefault();
    triggerClose();
  };
  return (
    <Form onSubmit={handleSubmit} data-testid="asset-form">
      <Wrapper>
        {error && submitFailed && <InlineAlert type="error">{error}</InlineAlert>}
        <VideoContainer>
          <YoutubeWrapper>
            <YoutubeEmbed ref={videoRef} width={360} height={220} videoId={external_id} />
          </YoutubeWrapper>
          <div>
            <Heading dangerouslySetInnerHTML={{ __html: videoTitle }} />
            <DetailItem>
              <span>Total Views</span>
              <span>{total_views.toLocaleString()}</span>
            </DetailItem>

            <DetailItem>
              <span>Daily Views</span>
              <span>{daily_views.toLocaleString()}</span>
            </DetailItem>

            <DetailItem>
              <span>Probability</span>
              <span>{score}</span>
            </DetailItem>
          </div>
        </VideoContainer>

        <FieldArrayRowAssetItem
          name="originalAssets"
          title="Original Asset"
          highlighted
          component={AssetEditedRow}
          disabled
          actions={[defaultRejectOption]}
        />
        <FieldArrayRowAssetItem
          name="otherAssets"
          title="Other Asset(s)"
          component={AssetEditedRow}
          autocomplete
          actions={actions}
          videoRef={videoRef}
          audioMatchSuggestions={asset.matches && asset.matches.results}
          rerenderOnEveryChange
        />

        <ButtonWrapper>
          <Button onClick={onClose} status={ButtonStatus.Danger} disabled={submitting}>
            Cancel Review
          </Button>
          <Button
            status={ButtonStatus.Success}
            disabled={!valid || pristine || submitting}
            loading={submitting}
          >
            Submit Review
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Form>
  );
};

export default AssetForm;

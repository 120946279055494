export const SET_SEARCH = (prefix = '') => `${prefix}_SET_SEARCH`;
export const RESET_SEARCH = (prefix = '') => `${prefix}_RESET_SEARCH`;

export type ResetSearchAction = {
  type: string;
  payload: Record<string, unknown>;
};

export type SetSearchAction = {
  type: string;
  payload: string;
};

export type SearchActionTypes = ResetSearchAction | SetSearchAction;

export type Search = {
  q: string;
};

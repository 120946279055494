import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';

export const __REDUX_STATE_KEY__ = 'claimAnalytics';

export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getClaimAnalyticsScale = (state: AppState) => getReduxStateSlice(state).scale;
export const getClaimAnalyticsBars = (state: AppState) => getReduxStateSlice(state).bars;
export const getClaimAnalyticsDonuts = (state: AppState) => getReduxStateSlice(state).donuts;
export const getClaimAnalyticsLastUpdated = (state: AppState) =>
  getReduxStateSlice(state).last_updated;

export const claimAnalyticsLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { flexCenter, transition } from 'src/theme/functions';

const type = (color: string) => keyframes`
  from {
    box-shadow: inset -2px 0px 0px ${color};
  }
  to {
    box-shadow: inset -2px 0px 0px transparent;
  }
`;

export const Wrapper = styled.div`
  ${flexCenter};
  flex-direction: column;

  h1,
  h2 {
    ${transition(0.6)};
    font-weight: 300;
  }
`;

export const H1 = styled.h1`
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: ${(props) => type(props.theme.colors.grey1)} 0.5s alternate infinite;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.grey2};
`;

export const H2 = styled.h2`
  color: #888;
`;

import { css } from '@emotion/react';
import { plainTextButton } from 'src/theme/functions';
import globals from 'src/theme/globals';

export const AppGlobalCss = css`
  html,
  body {
    overflow: hidden;
  }

  @font-face {
    font-family: ${globals.fontFamily};
    font-display: swap;
  }

  body {
    background: ${globals.colors.siteBackground};
    font-family: ${globals.fontFamily};
    margin: 0;
    padding: 0;
    min-height: 100vh;
    max-width: 100vw;
    &.no-scroll {
      overflow-y: hidden;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    ${plainTextButton}
  }

  div,
  span,
  a,
  aside,
  section,
  article,
  h1,
  h2,
  h3,
  h4,
  ol,
  ul,
  table,
  tr,
  th,
  td {
    padding: 0;
    margin: 0;
    border: none;
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: ${globals.fontFamilyAlt};
  }

  input,
  textarea,
  select,
  table {
    background: none;
    border: none;
    box-sizing: border-box;
  }
`;

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { updateReviewRequest, UpdateReviewData } from 'src/ducks/review';
import { withOverlay } from 'src/hoc';
import { FORMS } from 'src/utils/constants';

import ReviewQueueEditAsset from './ReviewQueueEditAsset';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: UpdateReviewData) => {
    dispatch(updateReviewRequest({ formName: FORMS.REVIEW_QUEUE_EDIT_ASSET, ...values }));
  },
});

export default withOverlay({ overlayId: FORMS.REVIEW_QUEUE_EDIT_ASSET, warnBeforeUnmount: false })(
  connect(null, mapDispatchToProps)(ReviewQueueEditAsset)
);

import dayjs from 'dayjs';
import { withLoadingReducer } from 'src/ducks/factories/loading';

import { __REDUX_STATE_KEY__ } from './selectors';
import * as types from './types';

const initialState: types.FiltersState & types.FiltersStateKey = {
  actions: [],
  queues: [],
  filters: [],
};

function filters(state = initialState, action: types.FiltersActionTypes): types.FiltersState {
  switch (action.type) {
    case types.FETCH_LEGACY_FILTERS_SUCCESS:
      // adding _expiresAt for expiring the filters since the last fetch
      return { ...action.payload, _expiresAt: dayjs().add(5, 'hour') };
    case types.CHANGE_LEGACY_FILTERS:
      return {
        ...state,
        [action.payload.changedKey]: state[action.payload.changedKey].map((filter) => ({
          ...filter,
          selected: filter.value === action.payload.options.value,
        })),
      };
    case types.CHANGE_DYNAMIC_FILTER:
      return {
        ...state,
        filters: [
          ...state.filters.map((el) => {
            if (el.strategy === action.payload.strategy) {
              const selectedKey = el[action.payload.selectedKey];
              return {
                ...el,
                [action.payload.selectedKey]:
                  Array.isArray(selectedKey) &&
                  selectedKey.map((opt: types.NormalizedSort) => {
                    if (opt.value === action.payload.itemValue) {
                      return {
                        value: opt.value,
                        label: opt.label,
                        options: opt.options.map((it) => ({
                          ...it,
                          selected: it.value === action.payload.keys.value,
                        })),
                      };
                    }
                    return opt;
                  }),
              };
            }
            return el;
          }),
        ],
      };
    case types.RESET_LEGACY_FILTERS: {
      return initialState;
    }
    default:
      return state;
  }
}

export default withLoadingReducer<types.FiltersState & types.FiltersStateKey>({
  prefix: __REDUX_STATE_KEY__,
  baseReducerState: initialState,
})(filters);

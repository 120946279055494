import styled from '@emotion/styled';

export const HeaderWrapper = styled.header`
  width: 100%;
  & > div {
    z-index: 1040;
  }
  & > div > div:nth-of-type(2) {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

import dayjs from 'dayjs';
import { PagesType } from 'src/utils/constants';

import { Anything } from '../../utils/globalTypes';

export const FETCH_LEGACY_FILTERS = 'FETCH_LEGACY_FILTERS';
export const FETCH_LEGACY_FILTERS_SUCCESS = 'FETCH_LEGACY_FILTERS_SUCCESS';
export const FETCH_LEGACY_FILTERS_FAILURE = 'FETCH_LEGACY_FILTERS_FAILURE';
export const CHANGE_LEGACY_FILTERS = 'CHANGE_LEGACY_FILTERS';
export const CHANGE_DYNAMIC_FILTER = 'CHANGE_DYNAMIC_FILTER';
export const RESET_LEGACY_FILTERS = 'RESET_LEGACY_FILTERS';

export interface FetchFiltersAction {
  type: typeof FETCH_LEGACY_FILTERS;
  payload: {
    strategy: PagesType;
    loading?: boolean;
  };
}

export interface FetchFiltersSuccessAction {
  type: typeof FETCH_LEGACY_FILTERS_SUCCESS;
  payload: FiltersState;
}

export interface FetchFiltersFailureAction {
  type: typeof FETCH_LEGACY_FILTERS_FAILURE;
}

export interface ChangeFilterAction {
  type: typeof CHANGE_LEGACY_FILTERS;
  payload: {
    options: NormalizedOptions;
    changedKey: string;
    strategy: string;
  };
}

export interface ChangeDynamicFilterAction {
  type: typeof CHANGE_DYNAMIC_FILTER;
  payload: DynamicFilterPayload;
}

export interface ResetFilterAction {
  type: typeof RESET_LEGACY_FILTERS;
  payload?: Anything;
}

export type FiltersActionTypes =
  | ResetFilterAction
  | FetchFiltersAction
  | FetchFiltersSuccessAction
  | ChangeFilterAction
  | ChangeDynamicFilterAction;

export type DynamicFilterPayload = {
  selectedKey: string;
  itemValue: string;
  strategy: string;
  keys: NormalizedLastOptions;
};

export type FiltersStateKey = {
  [key: string]: Anything[];
};
export type FiltersState = {
  strategy?: string;
  actions: NormalizedActionsOptions[];
  queues: NormalizeQueues[];
  filters: DynamicStrategies[];
  _expiresAt?: dayjs.Dayjs;
};

export type DynamicStrategies = {
  [key: string]: Anything;
  strategy: string;
  sort: NormalizedSort[];
  filters: NormalizedSort[];
};

export type NormalizedOptions = {
  label: string;
  selected: boolean;
  value: string;
};

export type NormalizedLastOptions = {
  label: string;
  selected: boolean;
  value: number | string;
};

export type LastResponseOptions = {
  value: number | string;
  text: string;
  default: boolean;
};

export type SingleStrategy = {
  value: string;
};

export type NormalizedActionsOptions = {
  type: string;
  label: string;
  value: string;
};
export type NormalizeQueues = {
  value: string;
  selected: boolean;
  label: string;
};
export type NormalizedSort = {
  label: string;
  value: string;
  options: NormalizedLastOptions[];
};

import { Location } from 'history';

import { simpleAction } from '../utils';
import { ROUTE_CHANGE, APP_INIT, CHANGE_PAGE } from './types';

export const appInit = ({ location }: { location: Location }) =>
  simpleAction(APP_INIT, { location });

export const routeChange = ({ location, action }: { location: Location; action: string }) =>
  simpleAction(ROUTE_CHANGE, { location, action });

export const changePage = (page: string) => simpleAction(CHANGE_PAGE, page);

import { AppState } from 'src/ducks';
import { withLoadingSelectors } from 'src/ducks/factories/loading';
import { withPaginationSelectors } from 'src/ducks/factories/pagination';
import { withSearchSelectors } from 'src/ducks/factories/search';

export const __REDUX_STATE_KEY__ = 'claim';

export const getReduxStateSlice = (state: AppState) => state[__REDUX_STATE_KEY__];

export const getClaims = (state: AppState) => getReduxStateSlice(state).list;
export const getClaimsCount = (state: AppState) => getReduxStateSlice(state).countItems;
export const getClaimsCountEstimate = (state: AppState) => getReduxStateSlice(state).estimate;
export const getClaimsSummary = (state: AppState) => getReduxStateSlice(state).summary;
export const getExportedDate = (state: AppState) => getReduxStateSlice(state).exportedDate;

export const claimLoadingSelectors = withLoadingSelectors(__REDUX_STATE_KEY__);

export const claimPaginationSelectors = withPaginationSelectors(__REDUX_STATE_KEY__);

export const claimSearchSelectors = withSearchSelectors(__REDUX_STATE_KEY__);

import { SimpleAction } from 'src/ducks/utils';

export const ADD_MODAL = 'ADD_MODAL';
export const DELETE_MODAL = 'DELETE_MODAL';

export type ActionTypeAdd = SimpleAction<
  typeof ADD_MODAL,
  { type: string; props: Record<string, unknown> }
>;

export type ActionTypeDelete = SimpleAction<typeof DELETE_MODAL, { modalId: string }>;

export type ModalAction = ActionTypeAdd | ActionTypeDelete;

export type Modal = {
  id?: string;
  type: string;
  props?: Record<string, unknown>;
};

export interface Modals {
  byKeyId: {
    [key: string]: Modal;
  };
  keys: string[];
}

import { Icon, IconButton, Loader, useTheme } from '@orfium/ictinus';
import React from 'react';

import { PROCESSING, StatusTypes } from '../../../../types';
import LinearLoader from '../LinearLoader';
import {
  ActionContainer,
  Container,
  Content,
  LoaderContainer,
  Message,
  Size,
  Title,
  TitleGroup,
} from './Progressitem.style';
import { actionPicker } from './utils';

export type Props = {
  id: string | number;
  file: {
    name: string;
    size: string;
    status: StatusTypes;
    progress?: number;
    message?: string;
    isIntermediate?: boolean;
    onCancel: () => void;
    onRetry?: () => void;
    onClear?: () => void;
  };
};

const ProgressItem: React.FC<Props> = ({ file }) => {
  const theme = useTheme();
  const action = actionPicker(
    file.status,
    file?.progress,
    file?.onCancel,
    file?.onClear,
    file?.onRetry
  );

  const isValidating = file?.progress === 100 && file?.status === PROCESSING;

  return (
    <Container>
      <Title>
        <TitleGroup>
          <Icon
            name={'file'}
            size={24}
            color={theme.utils.getColor('lightGrey', 650)}
            variant={600}
          />
          {file.name}
          {file.size && <Size>({file.size})</Size>}
        </TitleGroup>
        {typeof file.progress === 'number' && <Size>{file.progress}%</Size>}
      </Title>
      <Content>
        <LoaderContainer>
          {file.status === PROCESSING ? (
            <Loader type={'indeterminate'} />
          ) : (
            <LinearLoader type={file.status} progress={file.progress} />
          )}
        </LoaderContainer>
        <ActionContainer isValidating={isValidating}>
          {action.onClick && (
            <>
              {action.text}
              <IconButton
                transparent
                name={action.icon}
                size={20}
                color={action.color}
                dataTestId="progress-item"
                onClick={action.onClick}
              />
            </>
          )}
        </ActionContainer>
      </Content>
      <Message type={file.status}>{file?.message}</Message>
    </Container>
  );
};

export default ProgressItem;

import { simpleAction } from 'src/ducks/utils';

import { ADD_ALERT, DELETE_ALERT, AlertAction, Alert, ERROR_TYPE } from './types';

export const pushAlert = ({
  type,
  props = { type: ERROR_TYPE, messages: [] },
}: Alert): AlertAction => simpleAction(ADD_ALERT, { type, props });

export const removeAlert = (alertId: string | null): AlertAction =>
  simpleAction(DELETE_ALERT, { alertId });

import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState } from 'src/ducks';
import { SET_PAGINATION_PAGE, SetPaginationPageAction } from 'src/ducks/factories/pagination/types';
import {
  CHANGE_FILTERS,
  CHANGE_SORT,
  ChangeFilterAction,
  ChangeSortAction,
  FETCH_FILTERS,
  FETCH_FILTERS_SUCCESS,
  FetchFiltersSuccessAction,
  getFilterOptions,
  getSelectedStrategy,
  getSort,
} from 'src/ducks/filters';
import { handleParams } from 'src/ducks/filters/utils';
import { getGlobalSearch, SET_GLOBAL_SEARCH, SetGlobalSearchAction } from 'src/ducks/globalSearch';
import {
  fetchLeadsGenerationsCountRequest,
  fetchLeadsGenerationsFail,
  fetchLeadsGenerationsRequest,
  leadsGenerationLoadingActions,
  leadsGenerationPaginationActions,
  setLeadsGenerations,
  setLeadsGenerationsCount,
} from 'src/ducks/leadsGeneration/actions';
import { __REDUX_STATE_KEY__ } from 'src/ducks/leadsGeneration/selectors';
import {
  FETCH_LEADS_GENERATION_REQUEST,
  FETCH_LEADS_GENERATION_COUNT_REQUEST,
  FetchLeadsGenerationsRequestAction,
  FetchLeadsGenerationsCountRequestAction,
  LeadsGeneration,
  LeadsKeyword,
  LeadsChannel,
} from 'src/ducks/leadsGeneration/types';
import { leadsGenerationAPI } from 'src/providers';
import { LEADS_PAGES, LeadsGenerationPaginationLimit } from 'src/utils/constants';

const mapStrategyToURL = (strategy: string | undefined) => {
  switch (strategy) {
    case LEADS_PAGES.TOP_VIDEOS:
      return 'videos';
    case LEADS_PAGES.TOP_KEYWORDS:
      return 'keywords';
    case LEADS_PAGES.TOP_ASSETS:
      return 'assets';
    case LEADS_PAGES.TOP_CHANNELS:
      return 'channels';
    default:
      return 'videos';
  }
};

const triggerFetchActionsBasedOnStateFilters = (state: AppState) => {
  const selected = getSelectedStrategy(state);
  if (selected?.value && !Object.values<string>(LEADS_PAGES).includes(selected?.value as string)) {
    return of();
  }
  const filters = getFilterOptions(state);
  const sort = getSort(state);
  const q = getGlobalSearch(state);
  const params = handleParams(filters, sort, selected, q, 1);
  return concat(
    of(leadsGenerationLoadingActions.setLoading(true)),
    of(leadsGenerationPaginationActions.setPaginationPage(1)),
    of(fetchLeadsGenerationsCountRequest(params))
  );
};

//TODO: On FetchFiltersSuccessAction maybe check to keep the page from the url.
const filtersSucceededEpic = (
  action$: ActionsObservable<
    FetchFiltersSuccessAction | ChangeFilterAction | ChangeSortAction | SetGlobalSearchAction
  >,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_FILTERS_SUCCESS, CHANGE_FILTERS, CHANGE_SORT, SET_GLOBAL_SEARCH),
    switchMap(() => {
      return triggerFetchActionsBasedOnStateFilters(state$.value);
    })
  );

const fetchLeadsGenerationEpic = (action$: ActionsObservable<FetchLeadsGenerationsRequestAction>) =>
  action$.pipe(
    ofType(FETCH_LEADS_GENERATION_REQUEST),
    switchMap(({ payload }) => {
      const { request } = leadsGenerationAPI.single.get<
        LeadsGeneration[] | LeadsKeyword[] | LeadsChannel[]
      >({
        url: mapStrategyToURL(payload.strategy),
        ...payload,
      });

      return concat(
        of(leadsGenerationLoadingActions.setLoading(true)),
        from(request()).pipe(
          mergeMap((data) => of(setLeadsGenerations(data, payload.strategy as LEADS_PAGES))),
          catchError(() => of(fetchLeadsGenerationsFail()))
        ),
        of(leadsGenerationLoadingActions.setLoading(false))
      );
    })
  );

const fetchLeadsGenerationCountEpic = (
  action$: ActionsObservable<FetchLeadsGenerationsCountRequestAction>
) =>
  action$.pipe(
    ofType(FETCH_LEADS_GENERATION_COUNT_REQUEST),
    switchMap(({ payload }) => {
      const { request } = leadsGenerationAPI.single.getCount({
        url: mapStrategyToURL(payload.strategy),
        ...payload,
      });

      return from(request()).pipe(
        mergeMap((data) =>
          of(
            setLeadsGenerationsCount(data),
            leadsGenerationPaginationActions.setPaginationTotalPages(
              Math.ceil(data.count / LeadsGenerationPaginationLimit)
            )
          )
        )
      );
    })
  );

const changePageEpic = (
  action$: ActionsObservable<SetPaginationPageAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(SET_PAGINATION_PAGE(__REDUX_STATE_KEY__)),
    switchMap(({ payload }) => {
      const selected = getSelectedStrategy(state$.value);

      if (
        (selected?.value &&
          !Object.values<string>(LEADS_PAGES).includes(selected?.value as string)) ||
        !selected
      ) {
        return of();
      }

      const filters = getFilterOptions(state$.value);
      const sort = getSort(state$.value);
      const q = getGlobalSearch(state$.value);
      const params = handleParams(filters, sort, selected, q, payload);

      return concat(
        of(leadsGenerationLoadingActions.setLoading(true)),
        of(fetchLeadsGenerationsRequest(params)),
        of(leadsGenerationLoadingActions.setLoading(false))
      );
    })
  );

const resetPageData = (
  action$: ActionsObservable<SetPaginationPageAction>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(FETCH_FILTERS),
    switchMap(() => {
      const selected = getSelectedStrategy(state$.value);
      if (
        (selected?.value &&
          !Object.values<string>(LEADS_PAGES).includes(selected?.value as string)) ||
        !selected
      ) {
        return of();
      }

      return concat(of(setLeadsGenerations([], selected?.value as LEADS_PAGES)));
    })
  );

export default {
  filtersSucceededEpic,
  fetchLeadsGenerationEpic,
  fetchLeadsGenerationCountEpic,
  changePageEpic,
  resetPageData,
};

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { can, pickLandingPage } from 'src/ducks/user/policy';
import { __TOKEN__ } from 'src/utils/constants';
import { OPERATIONS } from 'src/utils/constants';
import cookies from 'src/utils/cookies';

import { CustomRouteProps } from '../types';

const PrivateRoute: React.FC<CustomRouteProps> = ({
  component: Component,
  disabled = false,
  ability,
  ...rest
}) => {
  const token = cookies.get(__TOKEN__);

  if (!token) {
    return <Route {...rest} render={() => <Redirect to="/login" />} />;
  }
  // If on home route or
  // there isn't a component passed or
  // the user doesn't have permission for this page
  // Redirect to landing page
  if (
    rest.path === '/' ||
    !Component ||
    !can({ I: OPERATIONS.READ, This: rest.path as string, ability })
  ) {
    return <Redirect to={pickLandingPage(ability)} />;
  }

  if (disabled) {
    return <Route {...rest} render={() => <span>This page is not available yet</span>} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;

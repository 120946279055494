import { METHODS, request } from '../axiosInstances';

export default {
  get: (params = {}) => request(METHODS.GET, `/review/`, { params }),
  create: (params: Record<string, unknown>) => request(METHODS.POST, `/review/`, { params }),
  update: (params: Record<string, unknown>) => request(METHODS.PUT, `/review/`, { params }),
  delete: (payload: Record<string, unknown>) => request(METHODS.DELETE, `/review/`, { payload }),
  action: (source: string, action: string, params: Record<string, unknown>) =>
    request(METHODS.PUT, `${source}/actions/${action}/`, { params }),
};

import { connect } from 'react-redux';
import { AppState } from 'src/ducks';
import { getAbility } from 'src/ducks/user';

import PrivateRoute from './PrivateRoute';

const mapStateToProps = (state: AppState) => ({
  ability: getAbility(state),
});

export default connect(mapStateToProps)(PrivateRoute);
